import React, { useState } from "react";

export default function AddressKyc(props) {
  const [formKycData, setformKycData] = useState(props.data);
  const [specialtype, setspecialType] = useState(props.typeCode);
  const handleFile = (e, id) => {
    setformKycData({
      ...formKycData,
      [e.target.name]: e.target.files[0],
      [e.target.name + id]: e.target.files[0].name,
    });
  };
  const handletypeKycChange = (e) => {
    setformKycData({
      ...formKycData,
      [e.target.name]: e.target.value,
    });

    let speccode = props.kyctypes.find(
      (el) => el.code === "others" && el.id === e.target.value
    );

    setspecialType(speccode ? speccode.code : "");
  };
  const handleKycChange = (e) => {
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }
    setformKycData({
      ...formKycData,
      [e.target.name]: value,
    });
  };
  let totalKyc = props.kyctypes.filter((el) => !el.checked);
  const handleKycUpload = (e, id, data) => {
    setformKycData({ ...formKycData, submited: true });
    props.handleKycUpload(e, id, data);
  };
  return (
    <div>
      <form
        className="edit-form"
        autoComplete="off"
        onSubmit={(e) => handleKycUpload(e, props.item.id, formKycData)}
      >
        <div className="row ">
          <div className="col-md-2 col-xs-12">
            <label className="control-label">
              KYC Type <span className="red">*</span>
            </label>
            {formKycData.id ? (
              <input
                type="text"
                required
                name="number"
                value={formKycData.document_type.type_name}
                class="form-control"
                disabled={true}
                placeholder=""
              />
            ) : (
              <select
                onChange={handletypeKycChange}
                className="form-control"
                name="document_type"
                disabled={formKycData.id ? true : false}
                value={formKycData.document_type}
                required
              >
                <option value="">Select</option>
                {props.kyctypes &&
                  props.kyctypes.map((item) => (
                    <React.Fragment>
                      {!item.checked && (
                        <option value={item.id}> {item.type_name}</option>
                      )}
                    </React.Fragment>
                  ))}
              </select>
            )}
          </div>
          {specialtype && specialtype === "others" && (
            <div className="col-md-2 col-xs-12">
              <label className="control-label">
                Name <span className="red">*</span>
              </label>
              <input
                type="text"
                required
                name="document_name"
                value={formKycData.document_name}
                class="form-control"
                onChange={handleKycChange}
                disabled={formKycData.id ? true : false}
                placeholder=""
              />
            </div>
          )}
          <div className="col-md-2 col-xs-12">
            <label className="control-label">
              Number <span className="red">*</span>
            </label>
            <input
              type="text"
              required
              name="number"
              value={formKycData.number}
              class="form-control"
              onChange={handleKycChange}
              disabled={formKycData.id ? true : false}
              placeholder=""
            />
          </div>

          <div className="col-md-3 col-xs-12">
            <label className="control-label">
              {" "}
              Front Page <span className="red">*</span>
            </label>
            <div className="upload-field">
              <label for={`upload-img${props.item.id + props.chilInd + 1}`}>
                <input
                  id={`upload-img${props.item.id + props.chilInd + 1}`}
                  required
                  type="file"
                  onChange={(e) => handleFile(e, "_name")}
                  // value={formData.upload}4
                  disabled={formKycData.id ? true : false}
                  accept="image/png, image/jpeg"
                  className="form-control"
                  name="frontpage"
                />
                {formKycData.frontpage_name}
              </label>
            </div>
          </div>
          <div className="col-md-3 col-xs-12">
            <label className="control-label">
              Back Page <span className="red">*</span>
            </label>
            <div className="upload-field">
              <label for={`backpage${props.item.id + props.chilInd + 1}`}>
                <input
                  id={`backpage${props.item.id + props.chilInd + 1}`}
                  required
                  type="file"
                  disabled={formKycData.id ? true : false}
                  onChange={(e) => handleFile(e, "_name")}
                  // value={formData.upload}
                  accept="image/png, image/jpeg"
                  className="form-control"
                  name="backpage"
                />
                {formKycData.backpage_name}
              </label>
            </div>
          </div>

          <div className="col-md-12 col-xs-12  text-right">
            {formKycData.id &&
              props.kycLen === props.chilInd + 1 &&
              totalKyc &&
              totalKyc.length !== 0 && (
                <button
                  className="btn btn-secondary"
                  type="button"
                  //   onSubmit={this.handleSubmit}
                  onClick={(e) =>
                    props.handleAddnewKyc(e, props.index, props.item.id)
                  }
                >
                  + &nbsp;&nbsp; ADD NEW KYC
                </button>
              )}
            {!formKycData.id && formKycData.addnew && (
              <button
                className="btn btn-secondary"
                type="button"
                onClick={(e) =>
                  props.handleChildCancel(props.index, props.chilInd)
                }
              >
                CANCEL
              </button>
            )}
            {formKycData.id ? (
              <button
                className="btn btn-primary"
                type="button"
                onClick={(e) =>
                  props.handleKycDelete(props.item.id, formKycData.id)
                }
              >
                Delete
              </button>
            ) : (
              <button
                className="btn btn-primary"
                type="submit"
                disabled={formKycData.submited}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
