import React, { Component } from "react";
import { Grid, MuiThemeProvider } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import CloseIcon from '@material-ui/icons/Close';
import CheckListStyle from "./checklist-style";
import { addCheckList } from "./apifunctions";
import SnackbarMessage from "../../plugins/snackbar-component";


class CheckListDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      snackbarOpen: false,
      snackbarMessage: "",
      snackbarVariant: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps = (props) =>{
        this.setState({
          formData: props.formData
       })
}

  handleClose = () => {
    this.props.handleClose();
  };

  getCheckListView = ()=>{
    this.props.getCheckListView();
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if(this.props.editMode){
          this.props.handleUpdate(this.state.formData)
    }else{
          addCheckList(this.state.formData)
          .then((res) => {
                 this.setState(
                  {
                    snackbarMessage: "Created Successfully",
                    snackbarOpen: true,
                    snackbarVariant: "success",
                    loader: false,
                  },
                () => {
                        this.handleClose()
                        this.getCheckListView()
                    }
            );
          })
          .catch((err) => {
                this.setState({
                   snackbarMessage: err.response.data.error.message,
                   snackbarOpen: true,
                   snackbarVariant: "error",
                   loader: false,
                });
          });
    }
}

onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
};
  
handleChange = (e) =>{
  let value = e.target.value;
		if (e.target.value.trim() === "") {
		  value = "";
	}
	this.setState({
		  formData: {
			...this.state.formData,
			[e.target.name]: value,
		  },
	});
}

render() {
    
    const { openDialog, editMode } = this.props;
    const { formData } = this.state;

  return (
    <div>
      <MuiThemeProvider theme={CheckListStyle}>
        <Dialog open={openDialog} 
              onClose={this.handleClose} 
              fullWidth
              aria-labelledby="form-dialog-title">
                <form onSubmit={this.handleSubmit}>
                    <DialogTitle disableTypography>
                      <h3>{editMode ? 'EDIT' : 'ADD'}</h3>
                      <IconButton 
                            aria-label="close" 
                            onClick={this.handleClose}>
                                 <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <InputLabel>Name</InputLabel>
                        <TextField
                            autoFocus
                            variant="outlined"
                            margin="dense"
                            required
                            name="name"
                            value={formData.name ? formData.name : ''}
                            onChange={this.handleChange}
                            type="text"
                            fullWidth
                        />
                    <InputLabel>Display Name</InputLabel>
                        <TextField
                            variant="outlined"
                            margin="dense"
                            name="display_name"
                            required
                            value={formData.display_name ? 
                                    formData.display_name:''}
                            onChange={this.handleChange}
                            type="text"
                            fullWidth
                        />
                  </DialogContent>
                  <DialogActions>
                    <Button variant="contained" 
                            type="submit"
                            className="btn btn-primary">
                              {editMode ? 'UPDATE' : 'SAVE'}
                    </Button>
                  </DialogActions>
              </form>
          </Dialog>
      </MuiThemeProvider>
        
      <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
      />
    </div>
    );
  }
}
export default CheckListDialog