import React, { Component } from "react";
import apis from "../../apiactions/api.actions";
import SnackbarMessage from "../../plugins/snackbar-component";
import "./login-style.css";
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      restObj: {},
      snackbarOpen: false,

      snackbarMessage: "",
      snackbarVariant: "",
    };
  }
  componentDidMount = () => {
    if (!localStorage.getItem("code") || localStorage.getItem("code") === null)
      this.props.history.push("/");
  };

  onLogin = (event) => {
    event.preventDefault();
    this.setState({
      restObj: {
        ...this.state.restObj,
        code: localStorage.getItem("code"),
      },
    });

    if (this.state.restObj.password === this.state.restObj.confirm_password) {
      apis
        .auth("employee/reset-password", this.state.restObj)
        .then((res) => {
          if (res.success) {
            this.setState(
              {
                snackbarMessage: res.message,
                snackbarOpen: true,
                snackbarVariant: "success",
              },
              () => {
                localStorage.removeItem("code");
                localStorage.removeItem("user_email");
                setTimeout(() => {
                  this.props.history.push("/login");
                }, 2500);
              }
            );
          } else {
            this.setState({
              snackbarMessage: "failed ! Try again",
              snackbarOpen: true,
              snackbarVariant: "error",
            });
          }
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.response.data.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } else {
      this.setState({
        snackbarMessage: "Password does'nt match",
        snackbarOpen: true,
        snackbarVariant: "error",
      });
    }
  };
  handleChange = (e) => {
    this.setState({
      restObj: {
        ...this.state.restObj,
        [e.target.name]: e.target.value,
      },
    });
  };
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  render() {
    return (
      <div className="login-wrapper">
        <div className="left-block">
          <div className="img-block">
            <img src="/app-assets/backend-images/login/login-img.png" />
          </div>
        </div>
        <div className="right-block">
          <div className="card">
            <h4 className="form-title">Enter New Password</h4>
            <form className="login-form" onSubmit={this.onLogin}>
              <div class="form-group">
                <label className="control-label">
                  New Password <span className="red">*</span>
                </label>
                <input
                  type="password"
                  required
                  name="password"
                  onChange={this.handleChange}
                  className="form-control"
                  placeholder="Enter New password"
                />
              </div>
              <div class="form-group">
                <label className="control-label">
                  Re Enter New Password <span className="red">*</span>
                </label>
                <input
                  type="password"
                  required
                  onChange={this.handleChange}
                  name="confirm_password"
                  className="form-control"
                  placeholder="Re Enter New Password"
                />
              </div>
              <div class="form-group">
                <button type="submit" className="btn btn-primary">
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default ResetPassword;
