import { Grid } from "@material-ui/core";
import moment from "moment";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import CalendarComponent from "../../../plugins/calendar.comp";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../plugins/dataTable/dataTable";
import DeleteComponent from "../../../plugins/delete/delete.component";
import SearchField from "../../../plugins/searchfield";
import {
  deleteCoupon,
  getEmployeeList,
  searchCouponData,
} from "../apifunctions";

class CouponsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [],
      options: {},
      mainId: "",
      opendelete: false,
      formSearch: {
        search: "",
        coupon_category: "",
        status: "",
        start_date: "",
        end_date: "",
        page: 1,
      },
      employee_list: [],
      pagesize: 0,
      totalCount: 0,
    };
  }

  async componentDidMount() {
    try {
      await this.getData();
      this.loadEmployeeList();
    } catch (err) {}
  }

  async getData() {
    this.setState({ loader: true });
    try {
      searchCouponData(this.state.formSearch)
        .then((res) => res.data)
        .then((res) => {
          // console.log(res.results, "--cc--");
          this.setState({
            datas: res.results,
            totalCount: res.count ? res.count : 1,
            pagesize: res.page_size ? res.page_size : 1,
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.message,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
        });
    } catch (err) {}
  }
  handleView = (e, row) => {
    this.props.history.push(`${this.props.match.path}-view/` + row.id);
  };

  handleDeleteClose = () => {
    this.setState({
      opendelete: false,
    });
  };
  handleDeleteOpen = (e, reqData) => {
    this.setState({
      opendelete: true,
      mainId: reqData.id,
    });
  };
  handleDelete = () =>
    deleteCoupon(this.state.mainId).then(() => {
      this.getData();
    });

  handleEdit = (e, row) => {
    this.props.history.push(`${this.props.match.path}-edit/` + row.id);
  };
  handleChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        if (e.target.name !== "search") {
          this.handleSearch();
        }
      }
    );
  };
  handlepagination(id) {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: id,
        },
      },
      () => {
        this.getData();
      }
    );
  }
  handleDatePicker = (date) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          startDate: date[0],
          endDate: date[1],
          start_date: date[0] ? moment(date[0]).format("DD-MM-YYYY") : "",
          end_date: date[1] ? moment(date[1]).format("DD-MM-YYYY") : "",
        },
      },
      () => {
        if (
          this.state.formSearch.start_date &&
          this.state.formSearch.end_date
        ) {
          this.handleSearch();
        }
      }
    );
  };

  async loadEmployeeList() {
    try {
      getEmployeeList()
        .then((res) => {
          this.setState({
            employee_list: res,
          });
        })
        .catch((err) => {});
    } catch (err) {}
  }

  handleSearch = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: 1,
        },
      },
      () => {
        this.getData();
      }
    );
  };
  handleClear = (name) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [name]: "",
        },
      },
      () => {
        this.handleSearch();
      }
    );
  };

  render() {
    const { masterData } = this.props;
    const { loader, formSearch, totalCount, pagesize } = this.state;

    return (
      <div className="card-content collapse show">
        <CommonLoader open={loader} />
        <div className="card-body card-dashboard">
          <Grid container spacing={1}>
            <Grid item xs={6} sm={3} className="table-search">
              <SearchField
                onChange={this.handleChange}
                value={formSearch.search}
                handleSearch={this.handleSearch}
                handleClear={this.handleClear}
              />
            </Grid>
            {/*<div className="position-relative">
                <input
                  type="text"
                  name="search"
                  className="form-control"
                  placeholder="Search"
                  onChange={this.handleChange}
                />
                <button
                  className="btn-transparent"
                  onClick={(e) => this.getData()}
                >
                  <img src="/app-assets/backend-images/icons/search-icon.svg" />
                </button>
              </div>*/}

            <Grid align="right" item xs={6} sm={9}>
              <Link
                to={`${this.props.match.path}/new`}
                className="btn btn-primary"
              >
                + &nbsp; Add New
              </Link>
            </Grid>
            <Grid item xs={6} sm={2} className="select-box">
              <select
                className="form-control"
                name="coupon_category"
                onChange={this.handleChange}
                value={
                  formSearch.coupon_category ? formSearch.coupon_category : ""
                }
              >
                <option value="" selected>
                  Category
                </option>
                {masterData &&
                  masterData.coupon_categories &&
                  masterData.coupon_categories.map(
                    ({ id, coupon_category_display_name }) => (
                      <option key={id} value={id}>
                        {coupon_category_display_name}
                      </option>
                    )
                  )}
              </select>
            </Grid>
            <Grid item xs={6} sm={2} className="select-box">
              <CalendarComponent
                selectsRange={true}
                startDate={formSearch.startDate}
                endDate={formSearch.endDate}
                name="date"
                onChange={this.handleDatePicker}
                placeholder="Usage Period"
              />
            </Grid>
            <Grid item xs={6} sm={2} className="select-box">
              <select
                className="form-control"
                name="status"
                onChange={this.handleChange}
              >
                <option value="" selected>
                  Status
                </option>
                {masterData &&
                  masterData.statuses &&
                  masterData.statuses.map(({ id, code, name }) => (
                    <option key={id} value={code}>
                      {name}
                    </option>
                  ))}
              </select>
            </Grid>

            <Grid item xs={12} sm={12} className="table-block">
              <DataTable
                data={this.state.datas}
                columns={[
                  {
                    title: "Category",
                    type: "text",
                    field: "category",
                    render: (rowData) => (
                      <span>
                        {rowData.coupon_category
                          ? rowData.coupon_category.coupon_category_display_name
                          : ""}
                      </span>
                    ),
                  },
                  {
                    title: "Title",
                    type: "text",
                    field: "coupon_name",
                  },
                  {
                    title: "Code",
                    type: "text",
                    field: "coupon_code",
                  },
                  {
                    title: "Start Date",
                    type: "text",
                    field: "start_date",
                  },
                  {
                    title: "End Date",
                    type: "text",
                    field: "end_date",
                  },
                  {
                    title: "Usage",
                    type: "number",
                    field: "current_usage",
                  },
                  {
                    title: "Status",
                    type: "status",
                    field: "status",
                  },
                ]}
                options={{
                  sort: true,
                  sortvalue: "coupon_name",
                  pageNo: this.state.formSearch.page,
                  pagination: true,
                  totalCount: totalCount,
                  pagesize: pagesize,
                  handlepagination: this.handlepagination.bind(this),
                }}
                actions={[
                  {
                    className: "view",
                    rowActionCLick: this.handleView.bind(this),
                    iconName: "/app-assets/backend-images/icons/view.png",
                    tooltip: "View In Detailed",
                  },
                  {
                    className: "edit",
                    rowActionCLick: this.handleEdit.bind(this),
                    iconName: "/app-assets/backend-images/icons/edit-icon.svg",
                    tooltip: "Edit Coupon",
                  },
                  {
                    className: "delete",
                    rowActionCLick: this.handleDeleteOpen.bind(this),
                    iconName:
                      "/app-assets/backend-images/icons/delete-icon.svg",
                    tooltip: "Delete Coupon",
                  },
                ]}
              />
            </Grid>
          </Grid>
        </div>

        <DeleteComponent
          opendelete={this.state.opendelete}
          handleDeleteClose={this.handleDeleteClose}
          delete={this.handleDelete}
        />
      </div>
    );
  }
}

export default CouponsList;
