import React, { Component } from "react";
import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../../../plugins/dataTable/dataTable";
import DeleteComponent from "../../../../../plugins/delete/delete.component";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import {
  createService_deliverables,
  delService_deliverables,
  getService_deliverables,
} from "../../../apifunctions";

class Deliverable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [],
      users: [],
      filedatas: [],
      entdatas: [],
      snackbarOpen: false,
      submited: false,
      snackbarVariant: "",
      loader: false,
      mainId: "",
      editflag: false,
      opendelete: false,
      hideAdd: false,
      snackbarMessage: "",
      formData: {},
      serId: this.props.ser_Id,
    };
  }
  componentWillReceiveProps = () => {
    this.setState({ serId: this.props.ser_Id });
  };
  handleChange = (e) => {
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: value,
      },
    });
  };
  componentDidMount = () => {
    if (this.props.ser_Id) {
      this.getproData(this.props.ser_Id);
    }
  };
  async getproData(serId) {
    this.setState({ loader: true });
    try {
      getService_deliverables(serId)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            datas: res.Deliverables,
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            datas: [],
            snackbarMessage: err.response.data.message,
            snackbarOpen: true,
            loader: false,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submited: true });
    createService_deliverables(this.props.ser_Id, this.state.formData)
      .then((res) => res.data)
      .then((res) => {
        this.setState(
          {
            formData: {},
            snackbarMessage: "Created Successfully",
            snackbarOpen: true,
            snackbarVariant: "success",
            loader: false,
            submited: false,
          },
          () => {
            this.getproData(this.props.ser_Id);
          }
        );
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err.response.data.error.message,
          snackbarOpen: true,
          snackbarVariant: "error",
          submited: false,
        });
      });
  };
  handleDeleteClose = () => {
    this.setState({
      opendelete: false,
    });
  };
  handleDeleteOpen = (e, reqData) => {
    this.setState({
      opendelete: true,
      mainId: reqData.id,
    });
  };
  handleDelete = () =>
    delService_deliverables(this.props.ser_Id, this.state.mainId).then(() => {
      this.getproData(this.props.ser_Id);
    });
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  render() {
    const { formData, loader, submited } = this.state;
    return (
      <div className="create-content">
        <CommonLoader open={loader} />
        <div className="content-body">
          <form className="edit-form" onSubmit={this.handleSubmit}>
            <div className="card-content margin-box">
              <div className="row">
                <div className="col-md-4 col-xs-12">
                  <div className="row">
                    <div className="col-md-12 col-xs-12 card-title">
                      <h4>Add Deliverable</h4>
                    </div>
                    <div className="col-md-12 col-xs-12">
                      <label className="control-label">Step</label>
                      <input
                        type="number"
                        name="step"
                        className="form-control"
                        required
                        value={formData.step ? formData.step : ""}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="col-md-12 col-xs-12">
                      <label className="control-label">Deliverable Name</label>
                      <input
                        type="text"
                        name="description"
                        value={formData.description ? formData.description : ""}
                        onChange={this.handleChange}
                        required
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-12 col-xs-12 text-left">
                      <button
                        className="btn btn-primary"
                        disabled={submited}
                        type="submit"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 col-xs-12">
                  <div className="row">
                    <div className="col-md-12 col-xs-12 card-title">
                      <h4>Uploaded Deliverable List</h4>
                    </div>
                    <div className="col-md-12 col-xs-12">
                      <DataTable
                        data={this.state.datas}
                        columns={[
                          {
                            title: "Step",
                            type: "text",
                            field: "step",
                          },
                          {
                            title: "Deliverable Name",
                            type: "text",
                            field: "description",
                          },
                        ]}
                        options={{
                          sort: true,
                          sortvalue: "step",
                          // pageNo: this.state.formSearch.page,
                          // pagination: true,
                          // totalCount: totalCount,
                          pagesize: this.state.pagesize,
                          // handlepagination: this.handlepagination.bind(this),
                        }}
                        actions={[
                          {
                            className: "delete",
                            rowActionCLick: this.handleDeleteOpen.bind(this),
                            iconName:
                              "/app-assets/backend-images/icons/delete-icon.svg",
                            tooltip: "Delete",
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-xs-12 text-right">
              <a
                className="btn btn-secondary"
                onClick={(e) => this.props.handleTabView(7)}
              >
                Back
              </a>
              {/* <button className="btn btn-primary" type="submit">
                save
              </button> */}
              <a
                className="btn btn-secondary"
                onClick={(e) => this.props.handleTabView(9)}
              >
                Next
              </a>
            </div>
          </form>
        </div>
        <DeleteComponent
          opendelete={this.state.opendelete}
          handleDeleteClose={this.handleDeleteClose}
          delete={this.handleDelete}
        />
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default Deliverable;
