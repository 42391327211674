const styles = (theme) => ({
  TableHeader: {
    backgroundColor: "#EBEBEB!important",

    fontSize: 12,
    lineHeight: 3,

    /*'&:first-child':{      
              width:'20%'
    }*/
  },
  TableStickyHeader: {
    backgroundColor: "#5d5a5a!important",
    color: "#fff!important",
    top: 0,
  },
  TableStickysearchHeader: {
    backgroundColor: "#fff!important",

    top: 29,
  },
  tabletextSelect: {
    backgroundColor: "white !important",
    textAlign: "right",
    height: "11px !important",
  },
  tabletextLeftSelect: {
    backgroundColor: "white !important",
    textAlign: "left",
    height: "11px !important",
  },
  tableTextBoxLeftError: {
    // textAlign: "right",
    height: "11px !important",
    backgroundColor: "white !important",
    border: "1px solid red !important",
    "&:hover": {
      border: "1px solid red !important",
    },
  },
  TableHeadersearch: {
    backgroundColor: "#fff!important",
  },
  searchInput: {
    backgroundColor: "#fff !important",
  },
  tableSearchRow: {
    backgroundColor: "#fff !important",
    "&:hover": {
      backgroundColor: "#fff !important",
    },
  },
  imputstyle: {
    backgroundColor: "#fff !important",
    height: "12px !important",
    border: "0px !important",
    "&:hover": {
      border: "0px !important",
    },
  },
  listIconButton: {
    padding: 0,
    "&:hover": {
      background: "transparent",
    },
  },
  resizeIcon: {
    transform: "rotate(90deg)",
    fontSize: 15,
    color: "#b8b5b5",
    cursor: "col-resize",
  },
  searchbutton: {
    padding: 0,
    "&:hover": {
      padding: 0,
      backgroundColor: "transparent",
    },
  },
  pagination: {
    float: "right",
    // marginTop: 7,
    margin: "15px 0px 0px",
  },
  iconColor: {
    fontSize: 15,
    padding: 1,
  },

  tableCellTurn: {
    whiteSpace: "nowrap",
    margin: 0,
    // width: "61%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  toolbar: {
    color: "#fff!important",
    position: "sticky",
    height: 20,
    minHeight: "24px !important",
    zIndex: 2,
    paddingLeft: "15px!important",
    top: 0,
    paddingTop: 7,
  },
  listIconButton: {
    padding: 0,
    "&SvgIcon": {
      "&root": {
        color: " white !important",
      },
    },
    "&:hover": {
      background: "transparent",
    },
  },
  ////////////////////////
  chkColor: {
    color: "#2E6ADF",
  },
  iconStyle: {
    transform: "rotate(90deg)",
    /* padding: 0px; */
    fontSize: 14,
  },

  /////////////////////
});
export default styles;
