import React , { Component } from 'react';
import ChangePassword from '../components/change-password/change-password';
import './header.css';
import SnackbarMessage from "../plugins/snackbar-component";

class Header extends Component{
  constructor (props) {
    super(props)
    this.state={
      showCPModal: false,
      snackbarOpen: false,
      snackbarMessage: "",
      snackbarVariant: "",
    }
  }

  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  }
  showSnackbar = (res) => {
    if (res.success) {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: res.message,
        snackbarVariant: "success",
      })
    } else {
      this.setState({
        snackbarOpen: true,
        snackbarMessage: res.message,
        snackbarVariant: "error",
      })
    }
  }
  render (){
    return (
      <nav className="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-semi-light bg-info navbar-shadow">
          <div className="navbar-wrapper">
            <div className="navbar-header">
              <ul className="nav navbar-nav flex-row">
                <li className="nav-item mobile-menu d-md-none mr-auto"><a className="nav-link nav-menu-main menu-toggle hidden-xs" href="#"><i className="ft-menu font-large-1" /></a></li>
                <li className="nav-item w-100"><a className="navbar-brand" href="/"><img className="brand-logo" alt="Bizpole logo" src="/app-assets/backend-images/logo/logo.svg" />
                    <h3 className="brand-text"><span></span><span>Bizpole</span></h3></a></li>
                <li className="nav-item d-md-none"><a className="nav-link open-navbar-container" data-toggle="collapse" data-target="#navbar-mobile"><i className="la la-ellipsis-v" /></a></li>
              </ul>
            </div>
            <div className="navbar-container content">
              <div className="collapse navbar-collapse" id="navbar-mobile">
                <ul className="nav navbar-nav mr-auto float-left">
                  {/* <li className="nav-item d-none d-md-block">
                    <a className="nav-link nav-menu-main menu-toggle hidden-xs" href="#">
                      <img src="/app-assets/backend-images/icons/menu.svg" alt="menu-icon" />
                    </a>
                  </li>
                  <li className="nav-item search-form">
                    <form>
                      <div className="form-group">
                        <input type="text" className="form-control" placeholder="Search" />
                        <button className="btn-transparent"><img src="/app-assets/backend-images/icons/search-icon.svg" /></button>
                      </div>
                    </form>
                  </li> */}
                </ul>
                <ul className="nav navbar-nav float-right" style={{'align-items': 'baseline'}}>
                  {/* <li className="dropdown dropdown-user nav-item"><a className="dropdown-toggle nav-link dropdown-user-link dropdown-notification-link" href="#" data-toggle="dropdown"><span className="notification notification-online"><img src="/app-assets/backend-images/icons/notification.svg" alt="notification" /><i /></span></a>
                    <div className="dropdown-menu dropdown-menu-right">
                      <a className="dropdown-item" href="#"><i className="ft-user" /> Edit Profile</a>
                      <a className="dropdown-item" href="#"><i className="ft-mail" /> My Inbox</a>
                    </div>
                  </li> */}
                  <li><label>{JSON.parse(localStorage.getItem('myProfile'))?.name}</label></li>
                  <li className="dropdown dropdown-user nav-item"><a className="dropdown-toggle nav-link dropdown-user-link" href="#" data-toggle="dropdown"><span className="avatar "><img src="/app-assets/backend-images/avatar-placeholder.jpg" alt="avatar" /></span></a>
                    <div className="dropdown-menu dropdown-menu-right">
                      <a className="dropdown-item" href="javascript:void(0)" onClick={() => this.setState({showCPModal: true})}><i className="ft-lock" /> Change Password</a>
                      <ChangePassword 
                        showModal={this.state.showCPModal} 
                        closeModal={() => this.setState({showCPModal: false})}
                        showSnackbar={(res) => this.showSnackbar(res)}/>
                      {/* <a className="dropdown-item" href="#"><i className="ft-user" /> Edit Profile</a><a className="dropdown-item" href="#"><i className="ft-mail" /> My Inbox</a><a className="dropdown-item" href="#"><i className="ft-check-square" /> Task</a><a className="dropdown-item" href="#"><i className="ft-message-square" /> Chats</a> */}
                      <div className="dropdown-divider" /><a className="dropdown-item" onClick={this.props.logout}><i className="ft-power" /> Logout</a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <SnackbarMessage
            snackbarOpen={this.state.snackbarOpen}
            snackbarMessage={this.state.snackbarMessage}
            onSnackbarClose={this.onSnackbarClose}
            variant={this.state.snackbarVariant}
          />
        </nav>
    );
  }
}

export default Header;
