import apis from "../../apiactions/api.actions";

const checklist = "core/check-list";

export const getCheckList = (val) => 
    apis.getDatas(
        checklist +
      `?search=${val.search}${val.page ? `&page=${val.page}` : ""}`
    );

export const addCheckList = (data) =>
    apis.createData(checklist +'?', data);     

export const getCheckListRow = (id) =>
    apis.getDatas(checklist +'/' + id);    
    
export const updateCheckList = (id,data) =>
    apis.updateData(checklist + '/'+ id, data);    

export const deleteCheckListRow = (id) =>
    apis.deleteData(checklist + '/' + id);     


    
