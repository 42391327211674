import React, { Component } from "react";
import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../../../plugins/dataTable/dataTable";
import DeleteComponent from "../../../../../plugins/delete/delete.component";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import {
  createPackage_faq,
  delPackage_faq,
  getPackage_ffaq,
} from "../../../apifunctions";

class FAQ extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [],
      users: [],
      filedatas: [],
      entdatas: [],
      snackbarOpen: false,
      submited: false,
      snackbarVariant: "",
      mainId: "",
      loader: false,
      editflag: false,
      opendelete: false,
      hideAdd: false,
      snackbarMessage: "",
      formData: {},
      pack_Id: this.props.pack_Id,
    };
  }
  componentWillReceiveProps = () => {
    this.setState({ pack_Id: this.props.pack_Id });
  };
  handleChange = (e) => {
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: value,
      },
    });
  };
  componentDidMount = () => {
    if (this.props.pack_Id) {
      this.getproData(this.props.pack_Id);
    }
  };
  async getproData(pack_Id) {
    this.setState({ loader: true });
    try {
      getPackage_ffaq(pack_Id)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            datas: res.faqs,
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            datas: [],
            loader: false,
            snackbarMessage: err.response.data.error.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submited: true });
    createPackage_faq(this.props.pack_Id, this.state.formData)
      .then((res) => res.data)
      .then((res) => {
        this.setState(
          {
            formData: {},
            snackbarMessage: "Created Successfully",
            snackbarOpen: true,
            snackbarVariant: "success",
            submited: false,
          },
          () => {
            this.getproData(this.props.pack_Id);
          }
        );
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err.response.data.error.message,
          snackbarOpen: true,
          snackbarVariant: "error",
          submited: false,
        });
      });
  };
  handleDeleteClose = () => {
    this.setState({
      opendelete: false,
    });
  };
  handleDeleteOpen = (e, reqData) => {
    this.setState({
      opendelete: true,
      mainId: reqData.id,
    });
  };
  handleDelete = () =>
    delPackage_faq(this.props.pack_Id, this.state.mainId).then(() => {
      this.getproData(this.props.pack_Id);
    });
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  render() {
    const { formData, loader, submited } = this.state;
    return (
      <div className="create-content">
        <CommonLoader open={loader} />
        <div className="content-body">
          <form className="edit-form" onSubmit={this.handleSubmit}>
            <div className="card-title">
              <h4>Add FAQ</h4>
            </div>
            <div className="card-content margin-box">
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <label className="control-label">Step</label>
                  <input
                    type="number"
                    name="step"
                    className="form-control"
                    required
                    value={formData.step ? formData.step : ""}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-md-6 col-xs-12">
                  <label className="control-label">FAQ Question</label>
                  <textarea
                    type="text"
                    name="faq"
                    required
                    value={formData.faq ? formData.faq : ""}
                    class="form-control"
                    onChange={this.handleChange}
                    placeholder="faq"
                    rows="8"
                  ></textarea>
                </div>
                <div className="col-md-6 col-xs-12">
                  <label className="control-label">FAQ Answer</label>
                  <textarea
                    type="text"
                    name="answer"
                    required
                    value={formData.answer ? formData.answer : ""}
                    class="form-control"
                    onChange={this.handleChange}
                    placeholder="answer"
                    rows="8"
                  ></textarea>
                </div>
                <div className="col-md-12 col-xs-12 text-right">
                  <button
                    className="btn btn-primary"
                    disabled={submited}
                    type="submit"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
            <div className="card-title">
              <h4>Updated FAQ List</h4>
            </div>
            <div className="card-content margin-box">
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <DataTable
                    data={this.state.datas}
                    columns={[
                      {
                        title: "Step",
                        type: "text",
                        field: "step",
                      },
                      {
                        title: "Questions",
                        type: "text",
                        field: "faq",
                      },
                      {
                        title: "Answer",
                        type: "text",
                        field: "answer",
                      },
                    ]}
                    options={{
                      sort: true,
                      sortvalue: "step",
                      // pageNo: this.state.formSearch.page,
                      // pagination: true,
                      // totalCount: totalCount,
                      pagesize: this.state.pagesize,
                      // handlepagination: this.handlepagination.bind(this),
                    }}
                    actions={[
                      {
                        className: "delete",
                        rowActionCLick: this.handleDeleteOpen.bind(this),
                        iconName:
                          "/app-assets/backend-images/icons/delete-icon.svg",
                        tooltip: "Delete",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-xs-12 text-right">
              <a
                className="btn btn-secondary"
                onClick={(e) => this.props.handleTabView(7)}
              >
                Back
              </a>

              <a
                className="btn btn-secondary"
                onClick={(e) => this.props.handleTabView(9)}
              >
                Next
              </a>
            </div>
          </form>
        </div>
        <DeleteComponent
          opendelete={this.state.opendelete}
          handleDeleteClose={this.handleDeleteClose}
          delete={this.handleDelete}
        />
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default FAQ;
