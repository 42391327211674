import { Grid } from "@material-ui/core";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../plugins/dataTable/dataTable";
import DeleteComponent from "../../../plugins/delete/delete.component";
import SearchField from "../../../plugins/searchfield";
import { get_Complaince_mast } from "../apifunctions";
class ComplianceMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      options: {},
      mainId: "",
      opendelete: false,
      formSearch: {
        search: "",
        page: 1,
        filing_types: "",
        status: "",
        priority: "",
        filling_day: "",
      },
      loader: false,
      searchParam: "",
      tableConfig: {},

      pagesize: 0,
      masterData: this.props.masterData,
      totalCount: 0,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentWillReceiveProps = (props) => {
    this.setState({
      masterData: this.props.masterData,
    });
  };
  async componentDidMount() {
    try {
      await this.getData();
    } catch (err) {}
  }
  handleDeleteClose = () => {
    this.setState({
      opendelete: false,
    });
  };
  handleDeleteOpen = (e, reqData) => {
    this.setState({
      opendelete: true,
      mainId: reqData.id,
    });
  };

  handlepagination(page) {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: page,
        },
      },
      () => {
        this.getData();
      }
    );
  }
  getData(query) {
    this.setState({ loader: true });
    try {
      get_Complaince_mast(this.state.formSearch)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            datas: res.results,
            totalCount: res.count,
            pagesize: res.page_size,
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.response.data.error.message,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
        });
    } catch (err) {}
  }
  handleEdit = (e, row) => {
    this.props.history.push(`${this.props.match.path}-edit/` + row.id);
  };
  handleSearch = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: 1,
        },
      },
      () => {
        this.getData();
      }
    );
  };
  handleClose = () => {};
  handleChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        if (e.target.name !== "search") {
          this.handleSearch();
        }
      }
    );
  };
  handleClear = (name) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [name]: "",
        },
      },
      () => {
        this.handleSearch();
      }
    );
  };
  render() {
    const { totalCount, formSearch, loader } = this.state;
    const { masterData } = this.props;

    return (
      <div className="card-content collapse show">
        <CommonLoader open={loader} />
        <div className="card-body card-dashboard">
          <Grid container spacing={1}>
            <Grid item xs={6} sm={2} className="table-search">
              <SearchField
                onChange={this.handleChange}
                value={formSearch.search}
                handleSearch={this.handleSearch}
                handleClear={this.handleClear}
              />
            </Grid>
            <Grid item xs={6} sm={2} className="table-search">
              <select
                className="form-control"
                name="filing_types"
                onChange={this.handleChange}
                value={formSearch.filing_types ? formSearch.filing_types : ""}
              >
                <option value="">Type</option>
                {masterData.filing_types &&
                  masterData.filing_types.map((filt, ind) => (
                    <option key={filt.id} value={filt.id}>
                      {filt.filing_type}
                    </option>
                  ))}
              </select>
            </Grid>
            <Grid item xs={6} sm={2} className="table-search">
              <select
                className="form-control"
                onChange={this.handleChange}
                value={formSearch.filling_day ? formSearch.filling_day : ""}
                name="filling_day"
              >
                <option value=" ">Filing Day</option>
                {[...Array(31)].map((filt, index) => (
                  <option key={filt} value={filt}>
                    {index + 1}
                  </option>
                ))}
              </select>
            </Grid>
            <Grid item xs={6} sm={2} className="table-search">
              <select
                className="form-control"
                onChange={this.handleChange}
                value={formSearch.priority ? formSearch.priority : ""}
                name="priority"
              >
                <option value="">Priority</option>
                {masterData.filing_priority &&
                  masterData.filing_priority.map((filt, ind) => (
                    <option key={filt} value={filt}>
                      {filt}
                    </option>
                  ))}
              </select>
            </Grid>
            <Grid item xs={6} sm={2} className="table-search">
              <select
                className="form-control"
                onChange={this.handleChange}
                value={formSearch.status ? formSearch.status : ""}
                name="status"
              >
                <option value="">Status</option>
                {masterData.statuses &&
                  masterData.statuses.map((filt, ind) => (
                    <option key={filt.id} value={filt.id}>
                      {filt.name}
                    </option>
                  ))}
              </select>
            </Grid>
            <Grid align="right" item xs={6} sm={2}>
              <Link
                to={`${this.props.match.path}-create/new`}
                className="btn btn-primary"
              >
                + &nbsp; Add New
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} className="table-block">
              <DataTable
                data={this.state.datas}
                columns={[
                  // { title: "Unique ID", field: "id" },
                  {
                    title: "Type",

                    field: "type",
                  },
                  {
                    title: "Name",

                    field: "name",
                  },
                  {
                    title: "Applicable Variation Count",

                    field: "applicableVariationCount",
                  },
                  {
                    title: "Filing Day",
                    render: (rowData) => (
                      <span>{rowData.filing.filing_day}</span>
                    ),
                    field: "filingDay",
                  },
                  {
                    title: "Priority",
                    render: (rowData) => <span>{rowData.filing.priority}</span>,
                    field: "Priority",
                  },
                  // {
                  //   title: "Status",
                  //   type: "status",
                  //   field: "status",
                  // },
                ]}
                options={{
                  sort: true,
                  sortvalue: "name",
                  pageNo: this.state.formSearch.page,
                  pagination: true,
                  totalCount: totalCount,
                  pagesize: this.state.pagesize,
                  handlepagination: this.handlepagination.bind(this),
                }}
                actions={[
                  {
                    className: "edit",
                    rowActionCLick: this.handleEdit.bind(this),
                    iconName: "/app-assets/backend-images/icons/edit-icon.svg",
                    tooltip: "Edit ",
                  },
                ]}
              />
            </Grid>
          </Grid>
        </div>
        <DeleteComponent
          opendelete={this.state.opendelete}
          handleDeleteClose={this.handleDeleteClose}
          delete={this.handleDelete}
        />
      </div>
    );
  }
}

export default ComplianceMaster;
