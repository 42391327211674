export default function Validation(values, fieldName, required) {
  const errors = {};

  required.forEach((field) => {
    let valid = /\s/;
    if (fieldName === "") {
      if (!values[field]) {
        errors[field] = field;
      }
    } else {
      if (field === fieldName) {
        if (!values[field]) {
          errors[field] = field;
        }
      }
    }
  });

  return errors;
}
