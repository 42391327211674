import React, { Component } from "react";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
let serId = "";

class PricingRequest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      snackbarOpen: false,
      submited: false,
      snackbarVariant: "",
      mainId: "",
      editflag: false,
      opendelete: false,
      hideAdd: false,
      snackbarMessage: "",
      formData: {},
      options: props.pricing_attributes ? props.pricing_attributes : [],
    };
  }
  componentWillReceiveProps = (props) => {
    this.setState(
      { options: props.pricing_attributes ? props.pricing_attributes : [] },
      () => {}
    );
  };
  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  componentDidMount = () => {
    //

    this.props.onRef(this);
  };
  handleChange = (ind) => (e) => {
    const { options } = this.state;
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }

    options[ind] = value;
    this.setState({
      options,
    });
  };

  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  handleAddOption = () => {
    const { options } = this.state;
    let data = "";
    options.push(data);
    this.setState({
      options,
    });
  };
  handleOptionDel = (e, index) => {
    const { options } = this.state;
    let data = "";
    options.splice(index, 1);
    this.setState(
      {
        options,
      },
      () => {
        this.props.handleSubmit(e);
      }
    );
  };
  handleData = () => {
    return this.state.options;
  };
  render() {
    const { formData } = this.state;
    return (
      <div>
        <div className="col-md-12 col-xs-12">
          <div className="pricing-box">
            <div className="row align-start">
              <div className="col-md-3 col-xs-6">
                <div className="sub-head">
                  <p>Request For Pricing </p>
                </div>
                <p className="text">
                  For complex services, customers can request for pricing and
                  the requests would be updated in the admin panel and Bizpole
                  agents could contact the customer and get more details and
                  finalise on a price.{" "}
                </p>
                <p className="text">
                  Default set of fields would be presented to the customer to
                  collect their basic details and if any additional information
                  is required - it could be setup here for this service, and it
                  will be shown to the customer in the frontend.
                </p>
                <div className="sub-head">
                  <p>Default Fields For Basic Details </p>
                </div>
                <p className="text">Name, Email, Phone, State, City</p>
              </div>
              <div className="col-md-9 col-xs-12 border-l">
                <div className="row align-center">
                  <div className="col-md-12 col-xs-12">
                    <div className="sub-head">
                      <p>Request Form </p>
                    </div>
                  </div>
                  <div className="col-md-12 col-xs-12">
                    <div className="pricing-box m-0">
                      <div className="row">
                        {this.state.options &&
                          this.state.options.map((opt, index) => (
                            <>
                              <div
                                key={opt.index}
                                className="col-md-5 col-xs-12"
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  required
                                  disabled={
                                    opt !== "name" &&
                                    opt !== "email" &&
                                    opt !== "phone" &&
                                    opt !== "city" &&
                                    opt !== "state"
                                      ? false
                                      : true
                                  }
                                  onChange={this.handleChange(index)}
                                  value={opt}
                                  placeholder="Requests"
                                />
                              </div>
                              <div className="col-md-1 col-xs-2">
                                {opt !== "name" &&
                                  opt !== "email" &&
                                  opt !== "phone" &&
                                  opt !== "city" &&
                                  opt !== "state" && (
                                    <a
                                      href="#/"
                                      className="delete"
                                      onClick={(e) =>
                                        this.handleOptionDel(e, index)
                                      }
                                    >
                                      <img src="/app-assets/backend-images/icons/delete.png" />
                                    </a>
                                  )}
                              </div>
                            </>
                          ))}

                        <div className="col-md-12 col-xs-12 text-left">
                          <button
                            className="btn btn-secondary m-0"
                            type="button"
                            onClick={this.handleAddOption}
                          >
                            Add Options
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default PricingRequest;
