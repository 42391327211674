import React, { Component } from "react";
import { Route } from "react-router-dom";
import BreadCrumb from "../../layout/breadcrumb";
import NavigationSubMenu from "../../layout/sub-menu";
import "./services-packages.css";
class ServicesPackages extends Component {
  constructor(props) {
    super(props);
    const currentRoute = this.props.routes.find(
      (route) =>
        this.props.match.path + route.path === this.props.location.pathname
    );
    if (this.props.match.path === this.props.location.pathname) {
      this.props.history.push(`${this.props.match.path}/service-management`);
    }
    this.state = {
      type: currentRoute ? currentRoute["type"] : null,
    };
  }
  componentDidMount = () => {
    // this.getMasterData();
  };

  componentWillMount() {
    this.rerouteToAdminPage();
    // this.loadjsagain(null);
  }
  componentDidUpdate(prevProps) {
    this.rerouteToAdminPage();
    // this.loadjsagain(prevProps);
  }
  componentWillReceiveProps = () => {
    if (this.props.location.pathname === "/packages-services")
      this.props.history.push(`${this.props.match.path}/service-management`);
  };
  rerouteToAdminPage() {
    const currentRoute = this.props.routes.find(
      (route) =>
        this.props.match.path + route.path === this.props.location.pathname
    );
    //Setting BreadCrumb type also in Here from currentRoute
    if (currentRoute && currentRoute["type"] !== this.state.type) {
      this.setState({ type: currentRoute["type"] });
    }
  }

  render() {
    return (
      <>
        <div className="app-content content">
          <div className="content-wrapper">
            <BreadCrumb type={this.state.type} />
            <div className>
              <div className="content-body">
                <section id="configuration">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <NavigationSubMenu type={this.props.type} />
                        {this.props.routes.map((route, index) => {
                          return (
                            <Route
                              key={index}
                              path={`${this.props.match.path}${route.path}`}
                              render={(props) => (
                                // pass the sub-routes down to keep nesting
                                <route.component
                                  key={index}
                                  masterData={this.props.masterData}
                                  {...props}
                                  routes={route.routes}
                                />
                              )}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ServicesPackages;
