import React, { Component } from "react";
import { Link } from "react-router-dom";
import SnackbarMessage from "../../plugins/snackbar-component";
import { loginapi } from "./apifunctions";
import "./login-style.css";
/* import {InputAdornment,IconButton, FormControl,Input,InputLabel} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons'; 
*/

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      islogged: false,
      users: {},
      snackbarOpen: false,
      snackbarMessage: "",
      snackbarVariant: "",
      loginParams: {},
      showPassword: false,
    };
  }
  handleFormChange = (e) => {
    this.setState({
      loginParams: {
        ...this.state.loginParams,
        [e.target.name]: e.target.value,
      },
    });
  };

  ////////// Toggle Password Visibility  /////////////////////////////
  handleClickShowPassword = () => {
   
    this.setState({
      ...this.state.showPassword,
      showPassword: !this.state.showPassword,
    });
  };

  componentDidMount = () => {
    localStorage.removeItem("token");
    // window.addEventListener("popstate", () => {
    //   this.props.history.go(0);
    // });
  };

  login = (event) => {
    try {
      loginapi(this.state.loginParams)
        .then((res) => {
          this.props.login();
          this.setState(
            {
              islogged: true,
            },
            () => {
              setTimeout(() => {
                window.open("/dashboard", "_self");
                // this.props.history.push("/dashboard");
              }, 150);
            }
          );
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.response.data.error.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
    event.preventDefault();
  };
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  render() {
    return (
      <div className="login-wrapper">
        <div className="left-block">
          <div className="img-block">
            <img src="/app-assets/backend-images/login/login-img.png" />
          </div>
        </div>
        <div className="right-block">
          <div className="card">
            <h4 className="form-title">Log In</h4>
            <form className="login-form" onSubmit={this.login}>
              <div className="form-group">
                <label className="control-label">
                  Email <span class="red">*</span>
                </label>
                <input
                  type="email"
                  name="username"
                  className="form-control"
                  placeholder="Enter your email"
                  onChange={this.handleFormChange}
                  required
                />
              </div>
              <div class="form-group">
                <label className="control-label">
                  Password <span className="red">*</span>
                </label>
                <input /* password text toggle */
                  type={this.state.showPassword ? "text" : "password"}
                  name="password"
                  className="form-control"
                  placeholder="Enter your password"
                  onChange={this.handleFormChange}
                  required
                />
                {/* password toggle */}
                <span className="password-icon"
                      onClick={this.handleClickShowPassword}>

                    {this.state.showPassword ? 
                          (<img src="/app-assets/backend-images/icons/akar-icons_eye-open.png" />)
                          : 
                          (<img src="/app-assets/backend-images/icons/akar-icons_eye-slashed.png" />)}
                </span> 
              </div>
              {/*  for toggle password using material ui //////////
                  
               <FormControl>
                    <InputLabel htmlFor="standard-adornment-password">
                             Password</InputLabel>
                    <Input
                          id="standard-adornment-password"
                          name='password'
                          fullWidth required
                          type={this.state.showPassword ? 'text' : 'password'}
                          value={this.state.loginParams.password}
                          onChange={this.handleFormChange}
                          endAdornment={
                                         <InputAdornment position="end">
                                           <IconButton
                                               aria-label="toggle password visibility"
                                              onClick={this.handleClickShowPassword}
                                            >
                           {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                                         </InputAdornment>
                            }
                         />
                </FormControl>   */}
              <div class="form-group d-flex">
                <div className="checkbox-block">
                  <input type="checkbox" name="remember-me" id="remember_me" />
                  <label for="remember_me">Remember me</label>
                </div>
                {/* <Link to="/forgot-password">Forgot Password?</Link> */}
              </div>
              <div class="form-group">
                <button type="submit" className="btn btn-primary">
                  Log In
                </button>
              </div>
            </form>
          </div>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default Login;
