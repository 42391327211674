import React, { Component } from "react";
import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import SelectBox from "../../../../../plugins/selectbox/commonSelectBox";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import {
  getCompnayMapping,
  getEmployeeListSearch,
  save_CompnayMapping,
  update_CompnayMapping,
} from "../../../apifunctions";
// import {
//   createService_Benefits,
//   getService_Benefits,
//   updateService_Benefits,
// } from "../../../apifunctions";
let serId = "";
class CompanyOfficialMaping extends Component {
  constructor(props) {
    super(props);

    this.state = {
      snackbarOpen: false,
      submited: false,
      snackbarVariant: "",
      loader: false,
      employeeData: [],
      snackbarMessage: "",
      formData: {},
    };
  }
  componentDidMount = () => {
    if (this.props.com_Id) {
      this.getData();
    }
  };
  async getData(id) {
    this.setState({ loader: true });
    try {
      getCompnayMapping(this.props.com_Id)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            formData: {
              business_tier: res.company_mapping[0].business_tier,
              customer_relation_executive_name: res.company_mapping[0]
                .customer_relation_executive
                ? res.company_mapping[0].customer_relation_executive.name
                : "",
              customer_relation_executive: res.company_mapping[0]
                .customer_relation_executive
                ? res.company_mapping[0].id
                : "",

              id: res.company_mapping[0].id,
            },
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            datas: [],
            loader: false,
            snackbarMessage: err.response.data.error.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }
  handleChange = (e) => {
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: value,
      },
    });
  };
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submited: true });
    if (this.state.formData.id) {
      update_CompnayMapping(
        this.props.com_Id,
        this.state.formData.id,
        this.state.formData
      )
        .then((res) => {
          this.setState(
            {
              snackbarMessage: "Updated Successfully",
              snackbarOpen: true,
              submited: false,
              snackbarVariant: "success",
            },
            () => {
              this.getData();
            }
          );
        })
        .catch((err) => {
          this.setState({
            submited: false,
            snackbarMessage: err.response.data.error.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } else {
      save_CompnayMapping(this.props.com_Id, this.state.formData)
        .then((res) => res.data)
        .then((res) => {
          this.setState(
            {
              snackbarMessage: "Created Successfully",
              snackbarOpen: true,
              snackbarVariant: "success",
            },
            () => {
              this.getData();
            }
          );
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.response.data.error.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    }
  };
  handleDataChange = (value) => {
    if (value.length >= 3) {
      this.setState({
        formData: {
          ...this.state.formData,
          customer_relation_executive_name: value,
        },
      });
      this.getEmplData(value);
    }
  };
  getEmplData = (value) => {
    getEmployeeListSearch(value)
      .then((res) => res)
      .then((res) => {
        this.setState({
          employeeData: res,
        });
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err.response.data.error.message.error,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  handleSelectChange = (e, id, name) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [id]: e.id,
        customer_relation_executive_name: e[name],
      },
    });
  };
  render() {
    const { formData, loader, employeeData, submited } = this.state;
    const { masterData, companyDetail } = this.props;
    console.log(companyDetail);
    return (
      <div className="create-content">
        <CommonLoader open={loader} />
        <div className="content-body">
          <form className="edit-form" onSubmit={this.handleSubmit}>
            <div className="card-content">
              <div className="row">
                <div className="col-md-12 col-xs-12 customer-list">
                  <span className="details">
                    Customer Unique Referal ID: {companyDetail.company_code}
                  </span>
                </div>
                <div className="col-md-4 col-xs-12">
                  <label className="control-label">
                    Business Tier <span className="red">*</span>
                  </label>
                  <select
                    required
                    value={formData.business_tier}
                    onChange={this.handleChange}
                    name="business_tier"
                    className="form-control"
                  >
                    <option>Select</option>
                    {masterData &&
                      masterData.business_tier.map((item) => (
                        <option value={item.id}>{item.tier}</option>
                      ))}
                  </select>
                </div>
                <div className="col-md-4 col-xs-12">
                  <label className="control-label">
                    Customer Relation Executive <span className="red">*</span>
                  </label>
                  <SelectBox
                    Data={employeeData}
                    id="id"
                    async="true"
                    value={formData.customer_relation_executive_name}
                    desc="name"
                    getData={this.handleDataChange}
                    placeholder="Category"
                    className="form-control"
                    descId="customer_relation_executive"
                    onChange={this.handleSelectChange}
                  />
                </div>
                <div className="col-md-12 col-xs-12 text-right">
                  <a
                    href="#/"
                    className="btn btn-secondary"
                    onClick={(e) => this.props.handleTabView(1)}
                  >
                    Back
                  </a>
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={submited}
                  >
                    {this.state.formData.id ? "Update" : "Save"}
                  </button>
                  <a
                    href="#/"
                    className="btn btn-secondary"
                    onClick={(e) => this.props.handleTabView(3)}
                  >
                    Next
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default CompanyOfficialMaping;
