import React, { useState, useRef, useEffect } from 'react'
import {Dialog,Slide,TextField} from "@material-ui/core";
import {Autocomplete} from '@material-ui/lab';
import { withStyles } from "@material-ui/core/styles";
import styles from "./file-upload.css";
import SimpleReactValidator from 'simple-react-validator';
import {
    uploadFile,
    tagFile,
    getCustomers,
    getServices
  } from "../apifunctions";

function Transition(props) {
    return <Slide direction="down" {...props} />;
}

const fileTagFieldsInitialState = {
    name: '',
    customer: null,
    service: null,
    status: null
}

const FileUpload = (props) => {
    const [, forceUpdate] = useState()
    const [files, setFiles] = useState({})
    const [customers, setCustomers] = useState([])
    const [services, setServices] = useState([])
    const [statuses, setStatuses] = useState([])
    const [fileTagFields, setFileTagFields] = useState(fileTagFieldsInitialState)
    const [processStep, setProcessStep] = useState(0)
    const [submitted, setSubmitted] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const fileInputField = useRef(null);
    const simpleValidator = useRef(new SimpleReactValidator())

    useEffect(async () => {
        // await getCustomerList()
        // await getServiceList()
        // const masterData = JSON.parse(localStorage.getItem('masterData'))
        // setStatuses(masterData.statuses)
    }, [])

    const onBrowseFileClick = () => { fileInputField.current.click() }
    const dragOver = (e) => { e.preventDefault(); }    
    const dragEnter = (e) => { e.preventDefault(); }    
    const dragLeave = (e) => { e.preventDefault(); }    
    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        setUploadedFiles(files)
    }
    const onInputFileChange = (e) => {
        e.preventDefault();
        var files = e.target.files;
        setUploadedFiles(files)
    }
    const setUploadedFiles = (files) => {
        const fileName = files[0]?.name;
        // const nameIdx = fileName.lastIndexOf(".")
        handleFileTagInputChange('name', fileName);
        setFiles(files);
        setProcessStep(1);
    }
    const onClickOpen = () => {
        if (props.isAllowed)
            setShowModal(true)
        else
            props.showSnackbar({message: 'You do not have permission to perform this action', type: 'error'})
    }
    const onClickClose = () => {
        props.reload()
        resetForm()
        setShowModal(false)
    }
    const resetForm = () => {
        setFiles({})
        setFileTagFields(fileTagFieldsInitialState)
    }
    const handleFileTagInputChange = (name, value) => {
        const fields = {
            ...fileTagFields,
            [name]: value
        }
        setFileTagFields(fields)
    }
    const onFileTagSubmit = async () => {
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages()
            forceUpdate(1)
        } else {
            setSubmitted(true)
            setProcessStep(2)
            await fileUpload(files[0])  
            onClickClose()          
            setSubmitted(false)
        }
    }
    //API Call starts
    const fileUpload = async (file) => {
        try {
            let bodyFormData = new FormData()
            bodyFormData.append('file_data', file)
            bodyFormData.append('folder', props.folderId)
            const res = await uploadFile(bodyFormData)
            if (res.success) await fileTag(res.data?.id)
            setProcessStep(3)
        } catch (err) {
            setProcessStep(4)
            setSubmitted(false)
            console.error('uploadFile err >> ', err)
            props.showSnackbar({message: err.response.data.error?.message, type: 'error'})
        }
    }
    const fileTag = async (fileId) => {
        try {
            const req = {
                file_id: fileId,
                name: fileTagFields.name,
                customer: fileTagFields.customer?.id,
                service: fileTagFields.service?.id,
                status: fileTagFields.status?.id
            }
            const res = await tagFile(req)
            if (res.success) props.showSnackbar({message: 'File Uploaded and Tagged Successfully!', type: 'success'})
        } catch (err) {
            setSubmitted(false)
            console.error('fileTag err >> ', err)
            props.showSnackbar({message: err.response.data.error?.message, type: 'error'})
        }
    }
    const getCustomerList = async () => {
        getCustomers({})
            .then((res) => {
                if (res.success) {
                    setCustomers(res.data.customers)
                }
            })
            .catch(err => {
                console.error('getCustomerList err >>> ',err)
            })
    }
    const getServiceList = async () => {
        getServices({})
            .then((res) => {
                if (res.success) {
                    setServices(res.data.services)
                }
            })
            .catch(err => {
                console.error('getServiceList err >>> ',err)
            })
    }
    //API Call ends
    return (
        <>        
        <a href="javascript:void(0)" className="btn btn-secondary" onClick={() => onClickOpen()}>
        + &nbsp; New Document
        </a>
        <Dialog
            open={showModal}
            TransitionComponent={Transition}
            onClose={() => onClickClose()}
            fullWidth="lg"
            maxWidth="lg"
            scroll="body"
            className="modal-large"
            id="file_upload"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
        >
            <div className="modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">ADD DOCUMENT</h5>
                        <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => onClickClose()}
                        >
                        <img src="/app-assets/backend-images/icons/close-modal.svg" />
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="dropzone drop-container"
                            onDragOver={dragOver}
                            onDragEnter={dragEnter}
                            onDragLeave={dragLeave}
                            onDrop={fileDrop}
                            onClick={() => onBrowseFileClick()}>
                            <input type="file" ref={fileInputField} hidden onChange={(e) => onInputFileChange(e)}/>
                            <div className="flex-centered">
                            <span>Browse file</span>
                            <p>OR</p>
                            <p>Drop your files here </p>
                            </div>
                        </div>
                        {
                        files.length > 0 ?                     
                            <div className="table-responsive">
                                <table className="modal-table">
                                <thead>
                                    <tr>
                                    <th>File Name <span className="red">*</span></th>
                                    <th>Customer </th>
                                    <th>Service</th>
                                    <th>Status</th>
                                    <th>Uploading Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td>
                                        <div className="fields">
                                            <input type="text" 
                                                name="name" className="form-control" placeholder="File Name"
                                                value={fileTagFields.name}
                                                onChange={(e) => handleFileTagInputChange(e.target.name, e.target.value)} />
                                            {simpleValidator.current.message('name', fileTagFields.name, 'required', { className: 'text-danger'})}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="fields">
                                        <Autocomplete
                                            disabled
                                            id="combo-box-demo"
                                            options={customers}
                                            getOptionLabel={(option) => option.name}
                                            // style={{ width: 225 }}
                                            renderInput={(params) => <TextField {...params} label="Customer" variant="outlined" size="medium"/>}
                                            onChange={(e, val) => handleFileTagInputChange("customer", val)}
                                            value={fileTagFields.customer}
                                        />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="fields">
                                        <Autocomplete
                                            disabled
                                            id="combo-box-demo"
                                            options={services}
                                            getOptionLabel={(option) => option.service_name}
                                            // style={{ width: 225 }}
                                            renderInput={(params) => <TextField {...params} label="Service" variant="outlined" size="medium"/>}
                                            onChange={(e, val) => handleFileTagInputChange("service", val)}
                                            value={fileTagFields.service}
                                        />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="fields">
                                        <Autocomplete
                                            disabled
                                            id="combo-box-demo"
                                            options={statuses}
                                            getOptionLabel={(option) => option.name}
                                            // style={{ width: 225 }}
                                            renderInput={(params) => <TextField {...params} label="Status" variant="outlined" size="medium"/>}
                                            onChange={(e, val) => handleFileTagInputChange("status", val)}
                                            value={fileTagFields.status}
                                        />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="progress-block">
                                        <div className="doc-filename">
                                            {/* <p className="sub">Uploading.....</p>
                                            <div className="doc-progress">
                                            <span className="progress-scale progress-success">
                                                <span className="progress-percent" style={{width: '20%'}}></span>
                                            </span>
                                            </div>
                                            <p className="sub">20%</p> */}
                                            {processStep === 1 && <span className="strong">Ready to Upload</span>}
                                            {processStep === 2 && <span className="strong" style={{color: 'blue'}}>Uploading...</span>}
                                            {processStep === 3 && <span className="strong" style={{color: 'green'}}>Completed🗸</span>}
                                            {processStep === 4 && <span className="strong" style={{color: 'red'}}>Failed✗</span>}
                                        </div>
                                        <a className="delete-link" href="javascript:void(0)" onClick={() => resetForm()}><img src="/app-assets/backend-images/icons/delete-red.svg" /></a>
                                        </div>
                                    </td>
                                    </tr>
                                    {/* <tr>
                                    <td>
                                        <div className="fields">
                                        <input type="text" name="name" className="form-control" placeholder="File Name" />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="fields">
                                        <select className="form-control">
                                            <option>Select Customer</option>
                                            <option>Select Customer</option>
                                            <option>Select Customer</option>
                                        </select>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="fields">
                                        <select className="form-control">
                                            <option>Select Services</option>
                                            <option>Select Services</option>
                                            <option>Select Services</option>
                                        </select>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="fields">
                                        <select className="form-control">
                                            <option>Select Status</option>
                                            <option>Select Status</option>
                                            <option>Select Status</option>
                                        </select>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="progress-block">
                                        <div className="doc-filename">
                                            <p className="sub">Failed</p>
                                            <div className="doc-progress">
                                            <span className="progress-scale progress-failed">
                                                <span className="progress-percent" style={{width: '100%'}}></span>
                                            </span>
                                            <img src="/app-assets/backend-images/icons/refresh-icon.svg" />
                                            </div>
                                            <p className="sub">0%</p>
                                        </div>
                                        <a className="delete-link" href="#"><img src="/app-assets/backend-images/icons/delete-red.svg" /></a>
                                        </div>
                                    </td>
                                    </tr> */}
                                </tbody>
                                </table>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" onClick={() => onFileTagSubmit()} disabled={submitted}>SUBMIT</button>
                                </div>
                            </div>
                        :                
                            // empty content
                            <div className="content-empty">
                                <div className="fullwidth">
                                <img src="/app-assets/backend-images/icons/no-documents.png" />
                                <p>No files Selected</p>
                                </div>
                            </div> 
                        }
                    </div>
                </div>
            </div>
        </Dialog>  
        </>
    )
}

export default withStyles( styles )( FileUpload );