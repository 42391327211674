import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import apis from "./apiactions/api.actions";
import { refreshToken } from "./config";
import Layout from "./layout/layout_funk";
import NoLayout from "./layout/Nolayout_funk ";
function App() {
  const history = useHistory();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [masterData, setMasteData] = useState([]);
  const [count, setCount] = useState(1);
  let token = localStorage.getItem("token");
  // let masterData = JSON.parse(localStorage.getItem("masterData"));
  if (!token) {
    refreshToken(null);
    localStorage.clear();
    localStorage.removeItem("token");
    localStorage.removeItem("masterData");
    window.history.replaceState(null, null, "/");
    history.push("/");
  } else {
    if (history.location.pathname === "/") history.push("/user-file-management/folders-list");
  }

  if (!isLoggedIn && localStorage.getItem("token")) {
    setIsLoggedIn(true);
  }

  const login = () => {
    setIsLoggedIn(true);
    history.push("/user-file-management/folders-list");
  };
  const logout = () => {
    refreshToken(null);
    localStorage.clear();
    localStorage.removeItem("token");
    localStorage.removeItem("masterData");
    setIsLoggedIn(false);
    window.history.replaceState(null, null, "/");
    history.push("/");
  };

  const getmasterData = () => {
    apis
      .getDatas("core/master-data")
      .then((res) => res.data)   //checkkkk
      .then((res) => {
        setMasteData(res);
        localStorage.setItem("masterData", JSON.stringify(res));
      });
  };
  refreshToken(localStorage.getItem("token"));
  useEffect(() => {
    if (isLoggedIn) getmasterData();
  }, [isLoggedIn]);

  return (
    <div>
      {isLoggedIn ? (
        <Layout masterData={masterData} login={login} logout={logout} />
      ) : (
        <NoLayout login={login} logout={logout} />
      )}
    </div>
  );
}

export default App;
