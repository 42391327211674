import { Grid } from "@material-ui/core";
import moment from "moment";
import React, { Component } from "react";
import CalendarComponent from "../../../../../plugins/calendar.comp";
import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import {
  getCustomerBasic,
  saveCustomerBasic,
  updateCustomerBasic,
} from "../../../apifunctions";

// let serId = "";

class BasicInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      snackbarOpen: false,
      submited: false,
      snackbarVariant: "",
      loader: false,
      snackbarMessage: "",
      // customer_basic_info: {},
      formData: {
        // country: "India",
        // state: "Kerala",
        // city: "Alappuzha",
        preferred_language: "English",
      },
      country_list: "",
      state_list: "",
      custId: props.custId,
    };
  }
  componentWillReceiveProps = () => {
    this.setState({
      custId: this.props.custId,
    });
  };
  componentDidMount = () => {
    // this.loadLocation();
    // if (this.state.custId) {
    //   this.props.loadFlag(this.state.custId);
    // }
    if (this.state.custId) {
      this.getData(this.state.custId);
    }
  };
  // loadLocation() {
  //   try {
  //     getLocationList()
  //       .then((res) => res.data)
  //       .then((res) => {
  //         this.setState({
  //           country_list: res.countries,
  //           state_list: res.state,
  //         });
  //       })
  //       .catch((err) => {});
  //   } catch (err) {}
  // }
  async getData(custId) {
    this.setState({ loader: true });
    // try {
    getCustomerBasic(custId)
      .then((res) => {
        // console.log(res);
        this.setState({
          // customer_basic_info: res,
          formData: {
            ...this.state.formData,
            id: res.id,
            first_name: res.default_contact
              ? res.default_contact.user.first_name
              : "",
            last_name: res.default_contact
              ? res.default_contact.user.last_name
              : "",
            email: res.default_contact ? res.default_contact.user.email : "",
            email_2: res.default_contact
              ? res.default_contact.user.email_2
              : "",
            mobile: res.default_contact ? res.default_contact.user.mobile : "",
            mobile_2: res.default_contact
              ? res.default_contact.user.mobile_2
              : "",
            date_of_birth: res.date_of_birth,
            preferred_language: res.preferred_language
              ? res.preferred_language.language_name
              : "",
          },
          loader: false,
        });
      })
      .catch((err) => {
        var error_message = this.props.loadError(
          err.response.data.error.message
        );
        this.setState({
          snackbarMessage: error_message,
          snackbarOpen: true,
          snackbarVariant: "error",
          loader: false,
        });
      });
    // } catch (err) {}
  }
  handleChange = (e) => {
    // if (e.target.value) {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.value,
      },
    });
    // }
  };
  modifyJson(obj) {
    Object.keys(obj).forEach((key) => obj[key] == "" && delete obj[key]);
    return obj;
  }
  handleDatePicker = (e) => {
    if (e.value) {
      this.setState({
        formData: {
          ...this.state.formData,
          [e.name]: e.value ? moment(e.value).format("MM-DD-YYYY") : "",
        },
      });
      // setTimeout(() => {
      //   console.log(this.state.formData.dob, e.name);
      // }, 500);
    }
  };
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  refrainData = (formData) => {
    Object.entries(formData).map(([attr, value]) => {
      if (value === "" || value === null) delete formData[attr];
    });
    this.setState({
      formData: formData,
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loader: true });
    if (this.state.custId) {
      this.refrainData(this.state.formData);
      console.log(this.state.formData, "this.state.formData");
      updateCustomerBasic(this.state.custId, this.state.formData)
        .then((res) => {
          this.setState({
            snackbarMessage: "Updated Successfully",
            snackbarOpen: true,
            snackbarVariant: "success",
            loader: false,
          });
        })
        .catch((err) => {
          var error_message = this.props.loadError(
            err.response.data.error.message
          );
          this.setState({
            snackbarMessage: error_message,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
        });
    } else {
      console.log(this.state.formData, "this.state.formData");
      saveCustomerBasic(this.state.formData)
        .then((res) => res.data)
        .then((res) => {
          this.setState(
            {
              formData: { ...this.state.formData, id: res.id },
              snackbarMessage: "Created Successfully",
              snackbarOpen: true,
              snackbarVariant: "success",
              loader: false,
            },

            () => {
              setTimeout(() => {
                this.props.updateTab(this.state.formData.id);
              }, 500);
            }
          );
        })
        .catch((err) => {
          var error_message = this.props.loadError(
            err.response.data.error.message
          );
          this.setState({
            snackbarMessage: error_message,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
        });
    }
  };
  render() {
    const { masterData, editflag } = this.props;
    const {
      formData,
      // customer_basic_info,
      loader,
      custId,
      country_list,
      state_list,
    } = this.state;
    // const { user } = formData.default_contact
    //   ? formData.default_contact.user
    //   : "";
    // var user = formData.default_contact.user;
    return (
      <div className="create-content">
        <CommonLoader open={loader} />
        <div className="content-body">
          <form
            className="edit-form"
            autoComplete="off"
            onSubmit={this.handleSubmit}
          >
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6}>
                <h4 className="card-title">
                  {custId ? "" : "New "}Customer Details
                </h4>
              </Grid>
              {/* <Grid align="right" item xs={12} sm={12}>
                <button
                  style={{ marginRight: 6 }}
                  onClick={(e) => this.props.handleClose()}
                  className="btn btn-secondary"
                >
                  Close
                </button>
                <button className="btn btn-primary" type="submit">
                  {custId ? "Update" : "Save"}
                </button>

                {custId && (
                  <a
                    href={void 0}
                    className="btn btn-secondary"
                    onClick={(e) => this.props.handleTabView(2)}
                  >
                    Next
                  </a>
                )}
              </Grid> */}
            </Grid>
            <br />
            <div className="card-content">
              <div className="row">
                <div className="col-md-4 col-xs-12">
                  <div className="row">
                    <div className="col-md-12 col-xs-12">
                      <label className="control-label">
                        First Name <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        required
                        name="first_name"
                        value={formData.first_name ? formData.first_name : ""}
                        class="form-control"
                        onChange={this.handleChange}
                        placeholder=""
                      />
                    </div>

                    <div className="col-md-12 col-xs-12">
                      <label className="control-label">
                        Email <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        required
                        name="email"
                        value={formData.email ? formData.email : ""}
                        class="form-control"
                        onChange={this.handleChange}
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-xs-12">
                  <div className="row">
                    <div className="col-md-12 col-xs-12">
                      <label className="control-label">
                        Last Name <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        required
                        name="last_name"
                        value={formData.last_name ? formData.last_name : ""}
                        class="form-control"
                        onChange={this.handleChange}
                        placeholder=""
                      />
                    </div>

                    <div className="col-md-12 col-xs-12">
                      <label className="control-label">
                        Mobile <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        required
                        name="mobile"
                        value={formData.mobile ? formData.mobile : ""}
                        class="form-control"
                        onChange={this.handleChange}
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-xs-12 upload-block upload-block01 ">
                  <label className="control-label">Profile Image </label>
                  <div className="upload-img">
                    <input
                      type="file"
                      className="file-upload"
                      id="img-upload"
                    />
                    <label for="img-upload">
                      <img src="/app-assets/backend-images/default.png" />
                      <div className="upload-icon">
                        <img src="/app-assets/backend-images/icons/camera.svg" />
                      </div>
                    </label>
                  </div>
                </div>
                {/* <div className="col-md-4 col-xs-12">
                  <label className="control-label">
                    Country <span className="red">*</span>
                  </label>
                  <select
                    required
                    className="form-control"
                    name="country"
                    value={formData.country ? formData.country : "IN"}
                    onChange={this.handleChange}
                    disabled="disabled"
                  >
                    {country_list &&
                      country_list.map(({ code, name }) => (
                        <option key={code} value={code}>
                          {name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-4 col-xs-12">
                  <label className="control-label">
                    State <span className="red">*</span>
                  </label>
                  <select
                    required
                    className="form-control"
                    name="state"
                    value={formData.state ? formData.state : ""}
                    onChange={this.handleChange}
                  >
                    {state_list &&
                      state_list.map(({ code, name }) => (
                        <option key={code} value={code}>
                          {name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-4 col-xs-12">
                  <label className="control-label">
                    City <span className="red">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    name="city"
                    value={formData.city}
                    class="form-control"
                    onChange={this.handleChange}
                    placeholder=""
                  />
                </div> */}

                <div className="col-md-4 col-xs-12">
                  <label className="control-label">Secondary Email</label>
                  <input
                    type="text"
                    name="email_2"
                    value={formData.email_2 ? formData.email_2 : ""}
                    class="form-control"
                    onChange={this.handleChange}
                    placeholder=""
                  />
                </div>
                <div className="col-md-4 col-xs-12">
                  <label className="control-label">Secondary Mobile</label>
                  <input
                    type="text"
                    name="mobile_2"
                    value={formData.mobile_2 ? formData.mobile_2 : ""}
                    class="form-control"
                    onChange={this.handleChange}
                    placeholder=""
                  />
                </div>
                <div className="col-md-4 col-xs-12">
                  <label className="control-label">
                    Date of Birth <span className="red">*</span>
                  </label>
                  <CalendarComponent
                    selectsRange={false}
                    label="date"
                    required
                    onChange={this.handleDatePicker}
                    name="date_of_birth"
                    dateTime={false}
                    maxDate={new Date()}
                    selected={
                      formData.date_of_birth
                        ? moment(formData.date_of_birth).toDate()
                        : ""
                    }
                    class="form-control"
                  />
                  {/* <input
                        type="text"
                        required
                        name="dob"
                        value={formData.dob}
                        class="form-control"
                        // onChange={this.handleChange}
                        placeholder=""
                      /> */}
                </div>

                <div className="col-md-4 col-xs-12">
                  <label className="control-label">
                    Preferred Language <span className="red">*</span>
                  </label>
                  <select
                    required
                    className="form-control"
                    name="preferred_language"
                    value={
                      formData.preferred_language
                        ? formData.preferred_language
                        : ""
                    }
                    onChange={this.handleChange}
                  >
                    <option value="">Please Choose</option>
                    {masterData &&
                      masterData.languages &&
                      masterData.languages.map(({ id, language_name }) => (
                        <option key={id} value={language_name}>
                          {language_name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-4 col-xs-12">
                  <label className="control-label">Specialization</label>
                  <select
                    className="form-control"
                    name="specialization"
                    value={
                      formData.specialization ? formData.specialization : ""
                    }
                    // onChange={this.handleChange}
                  >
                    <option value="">Please Choose</option>
                    <option value={"specialization"}>specialization</option>
                  </select>
                </div>
                <div className="col-md-4 col-xs-12">
                  <label className="control-label">
                    Education Qualification
                  </label>
                  <select
                    className="form-control"
                    name="educational_qualification"
                    value={
                      formData.educational_qualification
                        ? formData.educational_qualification
                        : ""
                    }
                    // onChange={this.handleChange}
                  >
                    <option value="">Please Choose</option>
                    <option value={"educational_qualification"}>
                      Education
                    </option>
                  </select>
                </div>
                <div className="col-md-4 col-xs-12">
                  <label className="control-label">
                    Number of Businesses <span className="red">*</span>
                  </label>
                  <input
                    type="text"
                    // required
                    name="Number of Businesses"
                    class="form-control"
                    // onChange={this.handleChange}
                    placeholder=""
                  />
                </div>

                <div className="col-md-12 col-xs-12 text-right">
                  <button
                    style={{ marginRight: 6 }}
                    onClick={(e) => this.props.handleClose()}
                    className="btn btn-secondary"
                  >
                    Close
                  </button>
                  <button className="btn btn-primary" type="submit">
                    {custId ? "Update" : "Save"}
                  </button>

                  {custId && (
                    <a
                      href={void 0}
                      className="btn btn-secondary"
                      onClick={(e) => this.props.handleTabView(2)}
                    >
                      Next
                    </a>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default BasicInformation;
