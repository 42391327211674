import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../plugins/dataTable/dataTable";
import DeleteComponent from "../../../plugins/delete/delete.component";
import SearchField from "../../../plugins/searchfield";
import { getSpecializationList, deleteSpecializationRow } from "../apifunctions";

class Specialization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],         
      options:{},
      mainId: "",
      opendelete:false,
      formSearch:{
           status:'',
           page: 1,
           search:''
      },
      pagesize: 0,
      totalCount: 0,
      loader: false,
      snackbarMessage: "",
      snackbarOpen: false,
      snackbarVariant: ""
    };
  }

  async componentDidMount() {
    try {
      await this.getData();
    } catch (err) {}
  }
  
  async getData() {
    this.setState({ loader: true });
    try {
      getSpecializationList(this.state.formSearch)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
             datas: res.results,
             totalCount:res.count,
             pagesize: res.page_size,
             loader: false
          })
       })
       .catch((err) => {
        this.setState({
          snackbarMessage: err.response.data.error.message.error,
          snackbarOpen: true,
          snackbarVariant: "error",
          loader: false
        });
      });
    } catch (err) {}
  }
  
  handleChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        if (e.target.name !== "search") {
          this.handleSearch();
        }
      }
    );
  };
 
  handleSearch = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: 1,
        },
      },
      () => {
        this.getData();
      }
    );
  };
  
  handleClear = (name) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [name]: "",
        },
      },
      () => {
        this.handleSearch();
      }
    );
  };

  handlepagination(id) {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: id,
        },
      },
      () => {
        this.getData();
      }
    );
  }
   
  handleEdit = (e, row) => {
    this.props.history.push(`${this.props.match.path}-edit/` + row.id);
  };

  
  handleDeleteOpen = (e, reqData) => {
    this.setState({
      opendelete: true,
      mainId: reqData.id,
    });
  };

  handleDeleteClose = () => {
    this.setState({
      opendelete: false,
    });
  };

  handleDelete = () =>
    deleteSpecializationRow(this.state.mainId).then(() => {
      this.getData();
  });
  
  render() {

    const { loader, totalCount, formSearch, pagesize} = this.state;
    const { masterData } = this.props;

    return (
      <div className="card-content collapse show">
         <CommonLoader open={loader} />
        <div className="card-body card-dashboard">
        <Grid container spacing={1}>
                  <Grid item xs={6} sm={3} className="table-search">
                   <SearchField
                     onChange={this.handleChange}
                     value={formSearch.search}
                    handleSearch={this.handleSearch}
                    handleClear={this.handleClear}
                   />
              </Grid>
              <Grid item xs={6} sm={2}>
                  <select
                      className="form-control"
                      onChange={this.handleChange}
                      value={formSearch.status ? formSearch.status : ""}
                      name="status"
                   >
                <option value="" selected>Status</option>
                {masterData &&
                  masterData.statuses &&
                  masterData.statuses.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </select>
              </Grid>
              <Grid item align="right" xs={6} sm={7}>
                   <Link
                         to={`${this.props.match.path}-create`}
                         className="btn btn-primary"
                    >
                         + &nbsp; Add New
                   </Link>
              </Grid>
             <Grid item xs={12} sm={12} className="table-block">
                    <DataTable
                      data={this.state.datas}
                      columns={[
                          /*{ title: "SL",
                            type: "numeric", 
                            field: "educational_qualification_sl",
                          },*/
                          { 
                            title: "Specialization Code", 
                            type: "text", 
                            field: "specialization_code"
                          },
                          {
                            title: "Specialization Name",
                            type: "text",
                            field: "specialization_name",
                          },
                          {
                            title: "Display Name",
                            type: "text",
                            field: "specialization_displayname",
                          },
                          {
                            title: "Status",
                            type: "status",
                            field: "status",
                            render: (rowData) =>
                               rowData.status.code && (
                                  <span
                                    style={{
                                      backgroundColor:
                                        rowData.status.code === "active"
                                        ? "#78DEC0"
                                        : "#DEDEDE",
                                        padding: "0px 8px",
                                      }}
                                  >
                                    {rowData.status.name}
                                   </span>
                                 ),
                              }
                        ]}
                          options={{
                            sort: true,
                            sortvalue: "specialization_name",
                            pageNo: formSearch.page,
                            pagination: true,
                            totalCount: totalCount,
                            pagesize: pagesize,
                            handlepagination: this.handlepagination.bind(this),
                          }}
                          actions={[
                            {
                              className: "edit",
                              rowActionCLick: this.handleEdit.bind(this),
                              iconName: "/app-assets/backend-images/icons/edit-icon.svg",
                              tooltip: "Edit ",
                            },
                            {
                              className: "delete",
                              rowActionCLick: this.handleDeleteOpen.bind(this),
                              iconName:
                                "/app-assets/backend-images/icons/delete-icon.svg",
                              tooltip: "Delete",
                            }
                          ]}
                      />
                  </Grid>
               </Grid>
         </div>
         <DeleteComponent
            opendelete={this.state.opendelete}
            handleDeleteClose={this.handleDeleteClose}
            delete={this.handleDelete}
          />
      </div>
    );
  }
}
export default Specialization;
