import React, { Component } from "react";
import BreadCrumb from "../../../../layout/breadcrumb";
import CommonLoader from "../../../../plugins/commonLoader/commonLoader.component";
import NavigationTab from "../../../../plugins/customtab/customtab";
import { customerviewcomponent } from "../../../../plugins/customtab/customtabmenu";
import { getCustomer } from "../../apifunctions";
import BusinessProfile from "./business-profile/business-profile";
import CustomerCompliances from "./customer-compliances/compliances";
import "./customer-detailedview.css";
import CustomerLeads from "./customer-leads/leads";
import CustomerOrders from "./customer-orders/customer-orders";
import CustomerInformation from "./customer-profile/customer-profile";
import CustomerReferrals from "./customer-referrals/referrals";
import CustomerRelatedBusiness from "./customer-related-business/related-business";
import CustomerServices from "./customer-services/services";
import CustomerTickets from "./customer-tickets/tickets";
import CustomerTransactions from "./customer-transactions/transactions";

let custId = "";
let tabData = customerviewcomponent.menus;

// [
//   {
//     name: "Customer Profile",
//     id: 1,
//   },
//   {
//     name: "Business Profile",
//     id: 2,
//   },
//   {
//     name: "Compliances",
//     id: 3,
//   },
// ];
class CustomerListComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "",
      custData: {},
      userDetails: {},
      currentTab: 1,
      params: "",
      loader: true,
    };
    custId = this.props.match.params.id;
  }
  componentDidMount = () => {
    if (custId) {
      this.getData();
    }
  };
  getData = () => {
    this.setState({
      isActive: true,
    });
    getCustomer(custId)
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          custData: res,
          userDetails: res.default_contact ? res.default_contact.user : {},
          loader: false,
        });
      })
      .catch((err) => {
        this.setState({
          isActive: false,
          loader: false,
        });
      });
  };

  // componentWillMount() {}
  // componentDidUpdate(prevProps) {}

  handleTabView = (value) => {
    this.setState({
      currentTab: value,
    });
  };

  handleEdit = (id) => {
    console.log(id);
    this.props.history.push(`/customer-detailed/` + id);
  };

  render() {
    const { loader, userDetails, custData, currentTab } = this.state;

    //
    //

    return (
      <>
        <div className="app-content content">
          <div className="content-wrapper">
            <BreadCrumb type={"customerviewcomponent"} />
            <CommonLoader open={loader} />
            <div className>
              <div className="content-body">
                <section id="configuration">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="customer-list-head customer-view">
                          <div className="details-box">
                            <div className="detail-box border-r">
                              <div className="img-box">
                                <img
                                  alt=""
                                  src={
                                    custData.profile_image
                                      ? custData.profile_image.profile_thumbnail
                                      : ""
                                  }
                                />
                              </div>
                              <div className="content">
                                <p className="name">
                                  {/* display name */}
                                  {userDetails.first_name +
                                    " " +
                                    userDetails.last_name}
                                  <span
                                    className="tag yellow"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {custData.tier}
                                  </span>
                                </p>
                                <div className="list">
                                  <span className="icon">
                                    <img src="/app-assets/backend-images/icons/phone.png" />
                                  </span>
                                  <span className="para">
                                    {userDetails.mobile}
                                  </span>
                                </div>
                                <div className="list">
                                  <span className="icon">
                                    <img src="/app-assets/backend-images/icons/envelope.png" />
                                  </span>
                                  <span className="para">
                                    {" "}
                                    {userDetails.email}{" "}
                                  </span>
                                </div>
                                <div className="list">
                                  <span className="icon">
                                    <img src="/app-assets/backend-images/icons/date.png" />
                                  </span>
                                  <span className="para">
                                    {custData.date_of_birth}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="detail-box border-r">
                              <div className="content">
                                <p className="main">Bizpole Details</p>
                                <div className="list">
                                  <span className="sub">Total Referrals :</span>
                                  <span className="text">
                                    {custData.referrals}
                                  </span>
                                </div>
                                <div className="list">
                                  <span className="sub">CRE :</span>
                                  <span className="text">
                                    {custData.cre_agent &&
                                      custData.cre_agent.name}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="detail-box">
                              <div className="content">
                                <p className="main">Data Summary</p>
                                <div className="flex-list">
                                  <div className="view">
                                    <div className="list">
                                      <span className="sub">
                                        Number of Business
                                      </span>
                                      <span className="text">
                                        {" "}
                                        {custData.no_of_businesses}
                                      </span>
                                    </div>
                                    <div className="list">
                                      <span className="sub">
                                        Active Orders :
                                      </span>
                                      <span className="text">NA</span>
                                    </div>
                                  </div>
                                  <div className="view">
                                    <div className="list">
                                      <span className="sub">
                                        Pending Payments :
                                      </span>
                                      <span className="text">NA</span>
                                    </div>
                                    <div className="list">
                                      <span className="sub">
                                        Lifetime Value :
                                      </span>
                                      <span className="text">NA</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="actions">
                            <a
                              className="btn btn-primary"
                              title="Edit Details"
                              onClick={(e) => this.handleEdit(custData.id)}
                            >
                              <img src="/app-assets/backend-images/icons/edit-lg.png" />
                            </a>
                            <a className="btn btn-secondary" href="#">
                              <img src="/app-assets/backend-images/icons/file-lg.png" />
                            </a>
                          </div>
                        </div>
                        <NavigationTab
                          tabData={tabData}
                          key={custId}
                          currentTab={currentTab}
                          handleTabView={this.handleTabView}
                        />
                        {currentTab === 1 && (
                          <CustomerInformation
                            key={custId}
                            custData={custData}
                            custId={custId}
                          />
                        )}
                        {currentTab === 2 && (
                          <BusinessProfile
                            key={custId}
                            custData={custData}
                            custId={custId}
                          />
                        )}
                        {currentTab === 3 && (
                          <CustomerOrders
                            key={custId}
                            custData={custData}
                            custId={custId}
                          />
                        )}
                        {currentTab === 4 && (
                          <CustomerTransactions
                            key={custId}
                            custData={custData}
                            custId={custId}
                          />
                        )}
                        {currentTab === 5 && (
                          <CustomerServices
                            key={custId}
                            custData={custData}
                            custId={custId}
                          />
                        )}
                        {currentTab === 6 && (
                          <CustomerReferrals
                            key={custId}
                            custData={custData}
                            custId={custId}
                          />
                        )}
                        {currentTab === 7 && (
                          <CustomerCompliances
                            key={custId}
                            custData={custData}
                            custId={custId}
                          />
                        )}
                        {currentTab === 8 && (
                          <CustomerTickets
                            key={custId}
                            custData={custData}
                            custId={custId}
                          />
                        )}
                        {currentTab === 9 && (
                          <CustomerLeads
                            key={custId}
                            custData={custData}
                            custId={custId}
                          />
                        )}
                        {currentTab === 10 && (
                          <CustomerRelatedBusiness
                            key={custId}
                            custData={custData}
                            custId={custId}
                          />
                        )}

                        {/* <NavigationSubMenu
                          // params={custId}
                          type={this.props.type}
                        />
                        {this.props.routes.map((route, index) => {
                          return (
                            <Route
                              key={index}
                              path={`${this.props.match.path}${route.path}`}
                              render={(props) => (
                                // pass the sub-routes down to keep nesting
                                <route.component
                                  key={index}
                                  custData={custData}
                                  custId={custId}
                                  masterData={this.props.masterData}
                                  {...props}
                                  routes={route.routes}
                                />
                              )}
                            />
                          );
                        })} */}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CustomerListComponent;
