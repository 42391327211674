import apis from "../../apiactions/api.actions";

export const servicecat = "core/service-categories";
export const constitutioncat = "core/constitution-categories";
export const constitution_params = "core/constitution-category-parameter";
export const parameter_options = "core/parameter-option";
export const parameters = "core/parameters";
export const businsector = "core/business-sectors";
export const businesCat = "core/business-industries";

export const eduQualif = "customer/educational-qualifications/";

export const specialization = "customer/specialization/";

export const currentpractice = "customer/currentpractice/";

export const getParameterList = () => apis.getDatas(parameters);

export const searchConstitutionCategoriesData = (val) =>
  apis.getDatas(
    constitutioncat +
      `?search=${val.search}${val.status && `&status=${val.status}`}${
        val.page ? `&page=${val.page}` : ""
      }`
  );
export const getConstCatData = (val) =>
  apis.getDatas(constitutioncat + `?search=${val}&view=all`);
export const getServicelist = (val) =>
  apis.getDatas(
    servicecat +
      `?search=${val.search}${val.status && `&status__id=${val.status}`}${
        val.page ? `&page=${val.page}` : ""
      }`
  );
 
export const deleteConstitutionCategoriesRow = (id) =>
  apis.deleteData(constitutioncat + "/" + id);

export const searchParameterOptionsData = (val) => {
  return apis.getDatas(
    parameter_options +
      `?search=${val.search}&parameter_name__id=${val.parameter_name__id}${
        val.page ? `&page=${val.page}` : ""
      }`
  );
};

export const deleteParameterOptionsRow = (id) =>
  apis.deleteData(parameter_options + "/" + id);

export const addParameterOption = (data) =>
  apis.createData(parameter_options, data);

export const updateParameterOption = (id, data) =>
  apis.updateData(parameter_options + "/" + id, data);

export const getParameterOptionData = (id) =>
  apis.getDatas(parameter_options + "/" + id);


export const getEducationalList = (val) =>
  apis.getDatas(
    eduQualif +
      `?search=${val.search}${val.status && `&status=${val.status}`}${
        val.page ? `&page=${val.page}` : ""
      }`
  );

export const addEducation = (data) =>
  apis.createData(eduQualif +'?', data);  

export const getEducation = (id) =>
  apis.getDatas(eduQualif + id +'/');   

export const updateEducation = (id, data) =>
  apis.updateData(eduQualif + id +'/', data);  

export const deleteEducationRow = (id) =>
  apis.deleteData(eduQualif + id +'/'); 

export const getSpecializationList = (val) =>
  apis.getDatas(
    specialization +
      `?search=${val.search}${val.status && `&status=${val.status}`}${
        val.page ? `&page=${val.page}` : ""
      }`
  ); 

export const addSpecialization = (data) =>
  apis.createData(specialization +'?', data); 

export const getSpecialization = (id) =>
  apis.getDatas(specialization + id +'/');   

export const updateSpecialization = (id, data) =>
  apis.updateData(specialization + id +'/', data);   

export const deleteSpecializationRow = (id) =>
  apis.deleteData(specialization + id +'/');  
  
export const getCurrentPracticesList = (val) =>
  apis.getDatas(
    currentpractice +
      `?search=${val.search}${val.status && `&status=${val.status}`}${
        val.page ? `&page=${val.page}` : ""
      }`
 );   

export const addCurrentPractice = (data) =>
 apis.createData(currentpractice +'?', data);  

export const getCurrentPractice = (id) =>
 apis.getDatas(currentpractice + id +'/');   

export const updateCurrentPractice = (id, data) =>
 apis.updateData(currentpractice + id +'/', data);  

export const deleteCurrentPracticeRow = (id) =>
 apis.deleteData(currentpractice + id +'/');  


 