import axios from "axios";

export const BaseUrl = () => {
  //demo
  return "";
};

export const refreshToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Token ${token}`;
  } else {
    axios.defaults.headers.common["Authorization"] = null;
  }
};

export const ServInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // headers: {
  //   Authorization: refreshToken(),
  // },
});

function isTokenExpiredError(errorResponse) {
  // Your own logic to determine if the error is due to JWT token expired returns a boolean value
}
