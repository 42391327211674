import React, { Component } from "react";
import apis from "../../../apiactions/api.actions";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../plugins/dataTable/dataTable";
import SelectBox from "../../../plugins/selectbox/commonSelectBox";
import SnackbarMessage from "../../../plugins/snackbar-component";
import {
  compli_master,
  constitutioncat,
  getEntitiesAll,
  govt_fillings,
} from "../apifunctions";
class ComplianceMasterCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [],
      users: [],
      filedatas: [],
      entdatas: [],
      snackbarOpen: false,
      submited: false,
      snackbarVariant: "",
      editflag: false,
      entityDetail: [],
      hideAdd: false,
      loader: false,
      snackbarMessage: "",
      formData: {},
    };
  }
  componentDidMount = () => {
    if (this.props.match.params.id !== "new") {
      this.setState({ editflag: true });
      this.getComplianeData();
    } else {
      this.setState({ editflag: false });
    }
  };
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  async getComplianeData() {
    this.setState({ loader: true });
    let data = [];
    try {
      apis
        .getDatas(compli_master + "/" + this.props.match.params.id)
        .then((res) => res)
        .then((res) => {
          let stringmonth = "";
          if (res[0].filing.get_month) {
            res[0].filing.get_month.forEach((element) => {
              stringmonth = stringmonth + element.month + ", ";
            });
            stringmonth = stringmonth.slice(0, -2);
          }
          this.setState(
            {
              formData: {
                filing_day: res[0].filing.filing_day,
                filing_name: res[0].filing.filing_name,
                filing_type: res[0].filing.filing_type.id,
                filing_priority: res[0].filing.priority,
                filing_month: stringmonth,
                filing_status: res[0].filing.status.name,
                filing: res[0].filing.id,
                entity_variation_name:
                  res[0].variations[0].entity_variation_name,
                entity_type: res[0].variations[0].id,
                constitution_category_name:
                  res[0].variations[0].constitution_category
                    .constitution_category_name,
                constitution_category:
                  res[0].variations[0].constitution_category.id,
              },
              loader: false,
            },
            () => {
              this.getEntityVariation();
            }
          );
        })
        .catch((err) => {
          this.setState({
            loader: false,
            snackbarMessage: err.response.data.error.message.error,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }
  async componentDidMount() {}
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submited: true });
    let forms = {
      filing: this.state.formData.filing,
      entity_type: this.state.formData.entity_type,
    };
    let variation = [];
    if (this.state.editflag) {
      apis
        .updateData(compli_master + "/" + this.props.match.params.id, forms)
        .then((res) => res.data)
        .then((res) => {
          this.setState(
            {
              snackbarMessage: "Updated Successfully",
              snackbarOpen: true,
              snackbarVariant: "success",
              submited: false,
            },
            () => {
              this.getEntityVariation();
            }
          );
        })
        .catch((err) => {
          this.setState({
            submited: false,
            snackbarMessage: err.response.data.error.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } else {
      apis
        .createData(compli_master, forms)
        .then((res) => res.data)
        .then((res) => {
          this.setState(
            {
              hideAdd: true,
              snackbarMessage: "Created succesfully",
              snackbarOpen: true,
              snackbarVariant: "success",
              submited: false,
            },
            () => {
              this.getEntityVariation();
            }
          );
        })
        .catch((err) => {
          this.setState({
            submited: false,
            snackbarMessage: err.response.data.error.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    }
  };
  async getData(query) {
    try {
      apis
        .getDatas(query)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            datas: res.constitution_categories,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.response.data.error.message.error,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }
  handleDataChange = (value) => {
    if (value.length >= 3) {
      this.setState({
        formData: { ...this.state.formData, constitution_category_name: value },
      });
      let tag = constitutioncat + "?view=all&&search=" + value;
      this.getData(tag);
    }
  };
  async getfileData(query) {
    try {
      apis
        .getDatas(query)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            filedatas: res.filings,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.response.data.error.message.error,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }
  async getEndData(query) {
    try {
      getEntitiesAll(query)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            entdatas: res.entity_types,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.response.data.error.message.error,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }
  handlefileDataChange = (value) => {
    if (value.length >= 3) {
      this.setState({
        formData: { ...this.state.formData, filing_name: value },
      });
      let tag =
        govt_fillings +
        "?view=all&search=" +
        value +
        "&filing_type=" +
        this.state.formData.filing_type;
      this.getfileData(tag);
    }
  };
  handleEntDataChange = (value) => {
    if (value.length >= 3) {
      this.setState({
        formData: { ...this.state.formData, entity_variation_name: value },
      });
      let tag = {
        constitution_category__id: this.state.formData.constitution_category,
        search: value,
      };

      this.getEndData(tag);
    }
  };
  handleChange = (e) => {
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: value,
      },
    });
  };
  handleSelectChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        constitution_category: e.id,
        constitution_category_name: e.constitution_category_name,
      },
    });
  };
  handleEntSelectChange = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        entity_type: e.id,
        entity_variation_name: e.entity_variation_name,
      },
    });
  };
  handlefileChange = (e) => {
    let stringmonth = "";
    if (e.get_month) {
      e.get_month.forEach((element) => {
        stringmonth = stringmonth + element.month + ",";
      });
      stringmonth = stringmonth.slice(0, -2);
    }
    this.setState({
      formData: {
        ...this.state.formData,
        filing: e.id,
        filing_day: e.filing_day ? e.filing_day : "",
        filing_status: e.status ? e.status.name : "",
        filing_month: stringmonth,
        filing_priority: e.priority ? e.priority : "",
        filing_name: e.filing_name ? e.filing_name : "",
      },
    });
  };
  handleClose = () => {
    this.props.history.push("/filings-compliance/compliance-master");
  };
  getEntityVariation(query) {
    try {
      let tag = {
        search: "",
        constitution_category__id: this.state.formData.constitution_category,
      };
      getEntitiesAll(tag)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            entityDetail: res.entity_types,
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.response.data.error.message.error,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
        });
    } catch (err) {}
  }
  handleDelete = () => {};
  render() {
    const {
      formData,
      datas,
      filedatas,
      entdatas,
      hideAdd,
      editflag,
      entityDetail,
      loader,
    } = this.state;
    const { masterData } = this.props;

    return (
      <div className="create-content">
        <CommonLoader open={loader} />
        <div className="content-body">
          <form className="edit-form" onSubmit={this.handleSubmit}>
            <div className="card-content">
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <div className="row">
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Filing Type <span className="red">*</span>
                      </label>
                      <select
                        value={formData.filing_type}
                        onChange={this.handleChange}
                        className="form-control"
                        onFocus
                        name="filing_type"
                        required
                      >
                        <option value="">Select</option>
                        {masterData.filing_types &&
                          masterData.filing_types.map((item) => (
                            <option value={item.id}>{item.filing_type}</option>
                          ))}
                      </select>
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        {" "}
                        Filing Month <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        disabled
                        value={formData.filing_month}
                        name="admin-name"
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        {" "}
                        Filing Status <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        disabled
                        value={formData.filing_status}
                        name="admin-name"
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-xs-12">
                  <div className="row">
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        Filing <span className="red">*</span>
                      </label>
                      <SelectBox
                        Data={filedatas}
                        id="id"
                        disabled={
                          formData.filing_type && formData.filing_type !== ""
                            ? false
                            : true
                        }
                        required
                        async="true"
                        placeholder="Filing"
                        value={formData.filing_name}
                        desc="filing_name"
                        getData={this.handlefileDataChange}
                        className="form-control"
                        onChange={this.handlefileChange}
                      />
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        {" "}
                        Filing Day of Month <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        disabled
                        value={formData.filing_day}
                        name="admin-name"
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                    <div className="col-md-3 col-xs-12">
                      <label className="control-label">
                        {" "}
                        Filing Priority <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        disabled
                        value={formData.filing_priority}
                        name="admin-name"
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div className="linedivider"></div>
                <div className="col-md-3 col-xs-12 row">
                  <div className="card-title col-md-12 col-xs-12">
                    <h4>Entity Type Variation</h4>
                  </div>
                  <div className="col-md-12 col-xs-12">
                    <label className="control-label">
                      Constitution Category
                    </label>
                    <SelectBox
                      Data={datas}
                      id="id"
                      required
                      async="true"
                      placeholder="Constitution Category"
                      value={formData.constitution_category_name}
                      desc="constitution_category_name"
                      getData={this.handleDataChange}
                      className="form-control"
                      onChange={this.handleSelectChange}
                    />
                  </div>
                  <div className="col-md-12 col-xs-12">
                    <label className="control-label">Entity Variation</label>
                    <SelectBox
                      Data={entdatas}
                      id="id"
                      required
                      disabled={
                        formData.constitution_category &&
                        formData.constitution_category !== ""
                          ? false
                          : true
                      }
                      async="true"
                      placeholder="Entity Variation"
                      value={formData.entity_variation_name}
                      desc="entity_variation_name"
                      getData={this.handleEntDataChange}
                      className="form-control"
                      onChange={this.handleEntSelectChange}
                    />
                  </div>
                  <div className="col-sm-6 col-xs-12 text-left">
                    <button
                      style={{ marginRight: 6 }}
                      onClick={(e) => this.handleClose()}
                      className="btn btn-secondary"
                      type="button"
                    >
                      Close
                    </button>
                  </div>
                  <div className="col-sm-6 col-xs-12 text-left">
                    {!hideAdd && (
                      <button
                        //by sreelekshmi
                        type="submit"
                        disabled={this.state.submited}
                        className="btn btn-primary"
                      >
                        {editflag ? "Update" : "Save"}
                      </button>
                    )}
                  </div>
                </div>
                <div className="col-md-9 col-xs-12">
                  <div className="card-title col-md-12 col-xs-12">
                    <h4>List of Entity Variation</h4>
                  </div>
                  <div className="col-md-12 col-xs-12">
                    <DataTable
                      data={entityDetail}
                      columns={[
                        {
                          title: "Unique Id",
                          type: "text",
                          field: "entity_id",
                        },
                        {
                          title: "Constitution Category",
                          type: "text",
                          field: "constitution_category_name",
                          render: (rowData) =>
                            rowData.constitution_category && (
                              <span>
                                {
                                  rowData.constitution_category
                                    .constitution_category_name
                                }
                              </span>
                            ),
                        },
                        {
                          title: "Entity Type Variation",
                          type: "text",
                          field: "entity_variation_name",
                        },
                      ]}
                      options={{
                        // sort: true,
                        sortvalue: "name",
                        pagination: false,
                      }}
                      actions={[
                        {
                          className: "edit",
                          rowActionCLick: this.handleDelete.bind(this),
                          iconName:
                            "/app-assets/backend-images/icons/delete-icon.svg",
                          tooltip: "Delete",
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default ComplianceMasterCreate;
