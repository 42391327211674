import React , { Component , useState  } from "react";
import { Grid ,Modal } from "@material-ui/core";
import { Link } from "react-router-dom";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../plugins/dataTable/dataTable";
import SearchField from "../../../plugins/searchfield";
import SnackbarMessage from "../../../plugins/snackbar-component";
import FileUpload from "./file-upload";
import CreateFolder from "./create-folder";
import Rename from "./rename";
import DeleteComponent from "../../../plugins/delete/delete.component";
import {
  getFolderList,
  deleteFolder
} from "../apifunctions";

class OrdersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],         
      formSearch:{
          page: 1,
          search:'',
          folder_id: ''
      },
      snackbarOpen: false,
      snackbarMessage: "",
      snackbarVariant: "",
      deleteObj: {
        showModal: false,
        deleteRow: null
      },
      folderPermissions: {},
      currentRow: {},
      showRename: false
    };
}

async componentDidMount() {
  const params = this.props.match.params
  const parentData = this.props.history.location.state?.data || null
  try {
    if (parentData?.folder_permissions.can_view === false) this.props.history.go(-1)
    this.setState({folderPermissions: parentData?.folder_permissions})
    if (params.id) {
      this.state.formSearch.folder_id = params.id
    }
    await this.getData();
  } catch (err) {}
}

async getData() {
  this.setState({ loader: true });
  try {
    getFolderList(this.state.formSearch)
      .then((res) => res.data)
      .then((res) => {
        res.results.map(folder => {
          folder.icon = folder.is_directory ? '/app-assets/backend-images/icons/folder-icon.svg' : '/app-assets/backend-images/icons/file-icon.svg'
          folder.createdBy = folder.created_by.first_name.trim() + ' ' + folder.created_by.last_name.trim()
          folder.modifiedBy = folder.modified_by.first_name.trim() + ' ' + folder.modified_by.last_name.trim()
          folder.customerName = folder.customer?.name
          folder.serviceName = folder.service?.service_name
        })
        this.setState({
          datas: res.results,
          totalCount: res.count,
          pagesize: res.page_size,
          loader: false,
        });
      })
      .catch((err) => {
        this.showSnackbar({message: err.response.data.error.message, type: "error"})
        this.setState({ loader: false });
      });
  } catch (err) {}
}
deleteData = async () => {
  const row = this.state.deleteObj.deleteRow
  return await deleteFolder({id: row.id}).then(() => { this.getData(); })
}
  
handleChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        if (e.target.name !== "search") {
          this.handleSearch();
        }
      }
    );
};
 
handleSearch = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: 1,
        },
      },
      () => {
        this.getData();
      }
    );
};
  
handleClear = (name) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [name]: "",
        },
      },
      () => {
        this.handleSearch();
      }
    );
};

handleView = (e, row) => {
  e.preventDefault()
  if (this.state.folderPermissions?.can_view === false) { this.showSnackbar({message: 'You do not have permission to perform this action', type: 'error'});  return; }
  if (row.is_directory) {
    this.props.history.push(`/user-file-management/folder/${row.id}`, { data: row })
  } else {
    window.URL = window.URL || window.webkitURL;

    var xhr = new XMLHttpRequest(),
          a = document.createElement('a'), file;

    xhr.open('GET', row.file_data, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
        file = new Blob([xhr.response], { type : 'application/octet-stream' });
        a.href = window.URL.createObjectURL(file);
        a.download = row.name;  // Set to whatever file name you want
        // Now just click the link you created
        // Note that you may have to append the a element to the body somewhere
        // for this to work in Firefox
        a.click();
    };
    xhr.send();
  }
};
handleDelete = (e, row) => {
  e.preventDefault()
  if (this.state.folderPermissions?.can_delete === false)
    this.showSnackbar({message: 'You do not have permission to perform this action', type: 'error'})
  else
    this.setState({deleteObj: {showModal: true, deleteRow: row}})
}
handleEdit = (e, row) => {
  e.preventDefault()
  if (this.state.folderPermissions?.can_edit === false)
    this.showSnackbar({message: 'You do not have permission to perform this action', type: 'error'})  
  else
    this.setState({currentRow: row, showRename: true})
}


onSnackbarClose = () => {
  this.setState({ snackbarOpen: false });
};
showSnackbar = (data) => {
  this.setState({ 
    snackbarOpen: true,
    snackbarMessage: data.message,
    snackbarVariant: data.type,
   })
}

// setSmShow = (value) => {
//   this.smShow = value;
// };

// setLgShow = (value) => {
//   this.lgShow = value;
// };

 
render() {


  // this.setSmShow(false);
  // this.setLgShow(false);
  
  console.log("text");
    const { 
      loader, 
      totalCount, 
      formSearch, 
      pagesize, 
      editMode, 
      formData } = this.state;
    
  return (
      <div className="card-content collapse show">
        <CommonLoader open={loader} />
        <div className="card-body card-dashboard">
        {this.state.formSearch.folder_id && <span className="cursor-pointer" style={{'color': 'darkgray'}} onClick={() => this.props.history.goBack()}><i class="fa fa-angle-left" aria-hidden="true"></i> Back</span>}
        <Grid container spacing={1}>
                <Grid item xs={3} sm={4} className="table-search">
                  <SearchField
                    onChange={this.handleChange}
                    value={formSearch.search}
                    handleSearch={this.handleSearch}
                    handleClear={this.handleClear}
                  />
                </Grid>
                <Grid item xs={1} sm={1} className="filter-box">
                  {/* <Link className="filter-btn">
                    <img src="/app-assets/backend-images/icons/filter-icon.svg"  />
                  </Link> */}
                </Grid>
                
                <Grid item align="right" xs={8} sm={7}>
                  {/* <Link
                         className="btn btn-secondary" onClick={() => this.setSmShow(true)}
                         
                    >
                         + &nbsp; New Document
                   </Link>
                   <Link
                         className="btn btn-primary" onClick={() => this.setLgShow(true)}
                         
                    >
                         + &nbsp; New Folder
                   </Link> */}
                  {
                    this.state.formSearch.folder_id ?
                      // <a href="#upload_document" data-toggle="modal" className="btn btn-secondary">
                      // + &nbsp; New Document
                      // </a>                      
                      <FileUpload 
                        folderId={this.state.formSearch.folder_id} 
                        reload={() => this.getData()} 
                        isAllowed={this.state.folderPermissions?.can_add}
                        showSnackbar={(data) => this.showSnackbar(data)}/>
                    :
                      <></>
                  }
                  {
                    this.state.formSearch.folder_id ?
                      // <a href="#create_folder" data-toggle="modal" className="btn btn-primary">
                      // + &nbsp; New Folder
                      // </a>
                      <CreateFolder 
                        folderId={this.state.formSearch.folder_id} 
                        reload={() => this.getData()} 
                        isAllowed={this.state.folderPermissions?.can_create_subfolder}
                        showSnackbar={(data) => this.showSnackbar(data)}/>
                    :
                      <></>
                  }
                  
                  <Rename 
                    row={this.state.currentRow} 
                    showModal={this.state.showRename}
                    onCloseModal={() => this.setState({currentRow: {}, showRename: false})}
                    reload={() => this.getData()}
                    showSnackbar={(data) => this.showSnackbar(data)}/>

                </Grid>
                {!loader && <Grid item xs={12} sm={12} className="table-block">
                    <DataTable
                      data={this.state.datas}
                      columns={[
                          /*{
                            title: "SL",
                            type: "String",
                            field: "slNo",
                          },*/
                          {
                            title: "",
                            type: "img",
                            field: "icon",
                            rowActionCLick: this.handleView.bind(this)
                          },
                          {
                            title: "Name",
                            type: "text",
                            field: "name",
                            rowActionCLick: this.handleView.bind(this)
                          },
                          {
                            title: "Customer Name",
                            type: "text",
                            field: "customerName",
                            width: '15%'
                          },
                          { 
                            title: "Services", 
                            type: "text",
                            field: "serviceName",
                            width: '15%'
                          },
                          { 
                            title: "Last modified on", 
                            type: "text", 
                            field: "datetime_updated",
                            width: "100"
                          },
                          {
                            title: "Last modified By",
                            type: "text",
                            field: "modifiedBy",
                            width: "100"                         
                          },
                          {
                            title: "File Type",
                            type: "text",
                            field: "file_type"                            
                          },
                          {
                            title: "Created on",
                            type: "text",
                            field: "datetime_created",
                            width: "100"
                          },
                          {
                            title: "Created By",
                            type: "text",
                            field: "createdBy",
                            width: "100"
                          },
                          
                        ]}
                          options={{
                            sort: false,
                            sortvalue: "name",
                            pageNo: formSearch.page,
                            pagination: true,
                            totalCount: totalCount,
                            pagesize: pagesize,
                            //handlepagination: this.handlepagination.bind(this),
                          }}
                          actions={[
                            {
                              className: "view",
                              rowActionCLick: this.handleView.bind(this),
                              iconName: "/app-assets/backend-images/icons/view.png",
                              tooltip: "View ",
                            },
                            {
                              className: "delete",
                              rowActionCLick: this.handleDelete.bind(this),
                              iconName:
                                "/app-assets/backend-images/icons/delete-icon.svg",
                              tooltip: "Delete",
                              showWhen: {"is_root_folder" : false}
                            },
                            {
                              className: "edit",
                              rowActionCLick: this.handleEdit.bind(this),
                              iconName:
                                "/app-assets/backend-images/icons/edit-icon.svg",
                              tooltip: "Edit"
                            }
                          ]}
                      />
                  </Grid>}
               </Grid>
              

              {/* modal small */}
               {/* <div
                className="modal modal-mini  fade"
                id="create_folder"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">CREATE FOLDER</h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <img src="/app-assets/backend-images/icons/close-modal.svg" />
                      </button>
                    </div>
                    <div className="modal-body">
                      <form>
                        <fieldset>
                          <label className="control-label">Folder Name</label>
                          <input type="text" name="folder-name" className="form-control" />
                        </fieldset>
                      </form>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-primary">CREATE</button>
                    </div>
                  </div>
                </div>
              </div>   */}

            {/* modal large */}

             {/* <div
                className="modal modal-large  fade"
                id="upload_document"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">ADD DOCUMENT</h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <img src="/app-assets/backend-images/icons/close-modal.svg" />
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="dropzone">
                          <div className="flex-centered">
                            <span>Browse file</span>
                            <p>OR</p>
                            <p>Drop your files here </p>
                          </div>
                      </div>
                      <div className="table-responsive">
                          <table className="modal-table">
                            <thead>
                              <tr>
                                <th>File Name <span className="red">*</span></th>
                                <th>Customer </th>
                                <th>Services</th>
                                <th>Status</th>
                                <th>Uploading Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="fields">
                                    <input type="text" name="name" className="form-control" placeholder="File Name" />
                                  </div>
                                </td>
                                <td>
                                  <div className="fields">
                                    <select className="form-control">
                                      <option>Select Customer</option>
                                      <option>Select Customer</option>
                                      <option>Select Customer</option>
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="fields">
                                    <select className="form-control">
                                      <option>Select Services</option>
                                      <option>Select Services</option>
                                      <option>Select Services</option>
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="fields">
                                    <select className="form-control">
                                      <option>Select Status</option>
                                      <option>Select Status</option>
                                      <option>Select Status</option>
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="progress-block">
                                    <div className="doc-filename">
                                      <p className="sub">Uploading.....</p>
                                      <div className="doc-progress">
                                        <span className="progress-scale progress-success">
                                          <span className="progress-percent" style={{width: '20%'}}></span>
                                        </span>
                                      </div>
                                      <p className="sub">20%</p>
                                    </div>
                                    <a className="delete-link" href="#"><img src="/app-assets/backend-images/icons/delete-red.svg" /></a>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="fields">
                                    <input type="text" name="name" className="form-control" placeholder="File Name" />
                                  </div>
                                </td>
                                <td>
                                  <div className="fields">
                                    <select className="form-control">
                                      <option>Select Customer</option>
                                      <option>Select Customer</option>
                                      <option>Select Customer</option>
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="fields">
                                    <select className="form-control">
                                      <option>Select Services</option>
                                      <option>Select Services</option>
                                      <option>Select Services</option>
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="fields">
                                    <select className="form-control">
                                      <option>Select Status</option>
                                      <option>Select Status</option>
                                      <option>Select Status</option>
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <div className="progress-block">
                                    <div className="doc-filename">
                                      <p className="sub">Failed</p>
                                      <div className="doc-progress">
                                        <span className="progress-scale progress-failed">
                                          <span className="progress-percent" style={{width: '100%'}}></span>
                                        </span>
                                        <img src="/app-assets/backend-images/icons/refresh-icon.svg" />
                                      </div>
                                      <p className="sub">0%</p>
                                    </div>
                                    <a className="delete-link" href="#"><img src="/app-assets/backend-images/icons/delete-red.svg" /></a>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                      </div>

                      
                      // empty content

                      // <div className="content-empty">
                      //     <div className="fullwidth">
                      //       <img src="/app-assets/backend-images/icons/no-documents.png" />
                      //       <p>No files Selected</p>
                      //     </div>
                      // </div>  


                    </div>
                  </div>
                </div>
              </div>   */}



               {/* <Modal
                size="sm"
                show={this.smShow}
                onHide={() => this.setSmShow(false)}
                aria-labelledby="example-modal-sizes-title-sm"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-sm">
                    Small Modal
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>...</Modal.Body>
              </Modal>
              <Modal
                size="lg"
                show={this.lgShow}
                onHide={() => this.setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-lg">
                    Large Modal
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>...</Modal.Body>
              </Modal>  */}


              </div>
        <DeleteComponent
          opendelete={this.state.deleteObj.showModal}
          handleDeleteClose={() => this.setState({deleteObj: {showModal: false}})}
          delete={this.deleteData}
        />
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />  
      </div>
      );
    }
}
export default OrdersList;