import React , { Component } from 'react';
import { Grid } from "@material-ui/core";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import SnackbarMessage from "../../../plugins/snackbar-component";
import { addSpecialization } from "../apifunctions";

class SpecializationCreate extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
		formData: {
		ranking_order: 1,
		specialization_code: "",
		specialization_name: "",
		specialization_displayname: "",
		status: "",
		description: "",
	},
		snackbarOpen: false,
		snackbarMessage: "",
		snackbarVariant: "",
	};
		this.handleChange = this.handleChange.bind(this);
	}
    
	handleFileChange = (e) => {
		var image = document.getElementById("output");
		image.src = URL.createObjectURL(e.target.files[0]);
	};
	
	handleChange = (e) => {
		let value = e.target.value;
		if (e.target.value.trim() === "") {
		  value = "";
		}
		this.setState({
		  formData: {
			...this.state.formData,
			[e.target.name]: value,
		  },
		});
	};
	
	onSnackbarClose = () => {
		this.setState({ snackbarOpen: false });
	};
	
	handleSubmit = (event) => {
		event.preventDefault();
    	this.setState({ loader: true });
		addSpecialization(this.state.formData)
		  .then((res) => {
			this.setState(
			  {
				snackbarMessage: "Created Successfully",
				snackbarOpen: true,
				snackbarVariant: "success",
				loader: false,
			  },
			  () => {
				setTimeout(() => {
				  this.props.history.push(
					"/data-management/specializations"
				  );
				}, 2500);
			  }
			);
		  })
		  .catch((err) => {
			this.setState({
			  snackbarMessage: err.response.data.error.message,
			  snackbarOpen: true,
			  snackbarVariant: "error",
			  loader: false,
			});
		  }); 
	};
	
	handleClose = () => {
		this.props.history.push("/data-management/specializations");
	};

   render() {

	const { loader, formData } = this.state;
	const { masterData } = this.props;
    return (
       <div className="create-content">
		 <CommonLoader open={loader} />
	        <div className="content-body">
	          <form className="edit-form" onSubmit={this.handleSubmit}>
			    <Grid container spacing={1}>
                    <Grid item xs={6} sm={6}>
                      <h4 className="card-title">Add New Specialization</h4>
                    </Grid>
                    <Grid align="right" item xs={6} sm={6}>
                        <button
                            style={{ marginRight: 6 }}
                            onClick={(e) => this.handleClose()}
                            className="btn btn-secondary"
                            type="button"
                        >
                            Close
                        </button>
                        <button type="submit" className="btn btn-primary">
                            Save
                        </button>
                    </Grid>
                </Grid>
				<div className="card-content">
		            <div className="row">
		            	<div className="col-md-7 col-xs-12">
		            	   <div className="row">
		            		  <div className="col-md-6 col-xs-12">
		            			<label className="control-label">
									Specialization Name  <span className="red">*</span></label>
		            			<input type="text"
								       required 
								       name="specialization_name"
									   value={formData.specialization_name} 
									   class="form-control"
									   onChange={this.handleChange} 
									   placeholder="Specialization Name"  />
		            		  </div>
		            		  <div className="col-md-6 col-xs-12">
		            			<label className="control-label">
									Display Name <span className="red">*</span></label>
		            			<input type="text" 
									   required
									   name="specialization_displayname" 
									   value={formData.specialization_displayname}
									   class="form-control"
									   onChange={this.handleChange} 
									   placeholder="Display Name"  />
		            		  </div>
					          <div className="col-md-6 col-xs-12">
                                <label className="control-label">
                                   Specialization Code
                                       <span className="red">*</span>
                                </label>
                                <input type="text"
                                       required
									   name="specialization_code"
									   value={formData.specialization_code}
									   class="form-control"
									   onChange={this.handleChange}
									   placeholder="Specialization Code"
                                 />
                               </div>
                               <div className="col-md-6 col-xs-12">
                                  <label className="control-label">
                                     Status <span className="red">*</span>
                                  </label>
                                  <select
                                      className="form-control"
                                      name="status"
                                      required
                                      onChange={this.handleChange}
                                      value={formData.status}
                                     >
                                    <option value="" selected>
                                       Status
                                    </option>
                                   {masterData &&
                                    masterData.statuses &&
                                     masterData.statuses.map(({ id, name }) => (
                                        <option key={id} value={id}>
                                          {name}
                                        </option>
                                    ))}
                                 </select>
                                </div>
                    			<div className="col-md-12 col-xs-12">
		            				<label className="control-label">
										Description</label>
									<textarea
                                        name="description"
                                        required
                                        class="form-control"
                                        placeholder="Description"
                                        rows="5"
                                        value={formData.description ? 
										       formData.description : ""}
                                        onChange={this.handleChange}
                                    ></textarea>
								</div>
		            		</div>
		            		</div>
		            		<div className="col-md-5 col-xs-12">
		            			<div className="col-md-12 col-xs-12">
	            					<label className="control-label">
										Thumbnail Image </label>
	            					<div className="upload-img">
	            						<input  type="file" 
										        onChange={this.handleFileChange}
										        className="file-upload" 
												id="img-upload" />
	            						<label for="img-upload">
	            							<img alt=""
											     id="output"
												 src="/app-assets/backend-images/thumbnail-business-sector.png" />
	            							<div className="upload-icon">
	            								<img src="/app-assets/backend-images/icons/camera.svg" />
	            							</div>
	            						</label>
	            					</div>
	            				</div>
		            		</div>
		            	</div>
		            </div>
	            </form>
	        </div>
			    <SnackbarMessage
                 snackbarOpen={this.state.snackbarOpen}
                 snackbarMessage={this.state.snackbarMessage}
                 onSnackbarClose={this.onSnackbarClose}
                 variant={this.state.snackbarVariant}
		        />
	    </div>
      );
   }
}
export default SpecializationCreate;