import React, { Component } from "react";
// export default c function Sectionelements({
//   key,
//   serId,
//   elementLis,
//   sections,
//   handleRefresh,
// }) {
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DeleteComponent from "../../../../../plugins/delete/delete.component";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import {
  createServiceSection,
  createServiceSection_element,
  delete_service_elements,
  delete_service_sectoion,
  updateService_sections,
} from "../../../apifunctions";
export default class Sectionelements extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {},
      datas: [],

      submited: false,

      sectionData: {
        name: "",
        show_in_app: false,
        show_in_web: false,
      },
      sectionConut: 0,
      serId: props.serId,
      section: props.sections,
      deleteDat: { open: false, id: "", section: false },
      set_selected: false,
      elementList: this.props.elementList,
      snackbar: {
        open: false,
        message: "",
        variant: "",
      },
      addnewflag: props.serId ? true : false,
    };
  }
  componentWillReceiveProps = (props) => {
    this.setState({
      serId: props.serId,
      section: props.sections,
      elementList: this.props.elementList,
    });
  };

  handleAddSection = (ind, filt) => {
    const { section } = this.state;
    if (filt.name) {
      let data = {
        id: filt.id,
        name: filt.name,
        show_in_app: filt.show_in_app,
        show_in_web: filt.show_in_web,
      };
      if (filt.id) {
        updateService_sections(this.state.serId, filt.id, data)
          .then((res) => res.data)
          .then((res) => {
            section[ind] = {
              ...res.service_section_data,
              readOnly: true,
              elements: [],
            };
            this.setState({
              section,
              addnewflag: false,
              snackbar: {
                open: true,
                message: " section updated successfully",
                variant: "success",
              },
            });
            this.props.handleRefresh(this.state.serId);
          })
          .catch((err) => {
            this.setState({
              snackbar: {
                open: true,
                message: err.respose.data.error.message,
                variant: "error",
              },
            });
          });
      } else {
        createServiceSection(this.state.serId, data)
          .then((res) => res.data)
          .then((res) => {
            section[ind] = {
              ...res.service_section_data,
              readOnly: true,
              elements: [],
            };
            this.setState({
              section,
              addnewflag: false,
              snackbar: {
                open: true,
                message: "New section created successfully",
                variant: "success",
              },
            });
            this.props.handleRefresh(this.state.serId);
          })
          .catch((err) => {
            this.setState({
              snackbar: {
                open: true,
                message: err.respose.data.error.message,
                variant: "error",
              },
            });
          });
      }
    } else {
      this.setState({
        snackbar: {
          open: true,
          message: "please fill section name",
          variant: "error",
        },
      });
    }
  };

  handleSectionAdd = () => {
    const { section } = this.state;
    let data = {
      name: "",
      show_in_app: false,
      show_in_web: false,
      elements: [],
      readOnly: false,
    };
    section.push(data);
    this.setState({ section, addnewflag: false });
  };
  handleDeleteFuntion = () =>
    this.state.deleteDat.section
      ? delete_service_sectoion(this.state.serId, this.state.deleteDat.id).then(
          (res) => {
            this.props.handleRefresh(this.state.serId);
          }
        )
      : delete_service_elements(this.state.serId, this.state.deleteDat.id).then(
          (res) => {
            this.props.handleRefresh(this.state.serId);
          }
        );

  handleDelete = (index, chil, id) => {
    const { section, elementList } = this.state;
    if (id) {
      this.setState({
        deleteDat: {
          open: true,
          id: id,
          section: false,
        },
      });
    } else {
      let elid = section[index].elements[chil].id;
      elementList.forEach((item) => {
        if (item.id === elid) item["checkadd"] = false;
        item["is_selected"] = false;
      });
      section[index].elements.splice(chil, 1);
      if (section[index].elements.length === 0) {
        section[index]["addnew"] = false;
      }
      this.setState({ elementList, section });

      // setCount((count) => count + 1);
    }
  };
  handleDeleteSection = (index, id) => {
    const { section, elementList } = this.state;
    if (id) {
      this.setState({
        deleteDat: {
          open: true,
          id: id,
          section: true,
        },
      });
    } else {
      let itemsid = section[index].id;
      elementList.forEach((item) => {
        if (item.id === itemsid) item["checkadd"] = false;
        item["is_selected"] = false;
      });
      section.splice(index, 1);
      this.setState({ section });
    }
  };
  handleClick = (e, index, value) => {
    const { elementList } = this.state;
    elementList[index]["is_selected"] = !value;
    this.setState({ elementList });
  };
  // handleAddElement = (sec) => {
  //   const { elementList, section } = this.state;
  //   elementList.forEach((item) => {
  //     if (item.is_selected && !item.checkadd) {
  //       item["checkadd"] = true;
  //       item["show_in_web"] = false;
  //       item["show_in_app"] = false;
  //       let index = section.indexOf(sec);
  //       section[index]["addnew"] = true;
  //       section[index].elements.push(item);
  //     }
  //   });
  //   this.setState({ elementList, section });
  // };
  onSnackbarClose = () => {
    this.setState({ snackbar: { open: false } });
  };
  saveSectionElements = (ind) => {
    const { elementList, section } = this.state;
    let form = [];
    section[ind].elements.forEach((item) => {
      form.push({
        name: section[ind].name,
        section: section[ind].id,
        show_in_web: item.show_in_web,
        show_in_app: item.show_in_app,
        key: item.id,
      });
    });
    createServiceSection_element(this.state.serId, form)
      .then((res) => res.data)
      .then((res) => {
        section[ind]["added"] = true;
        this.setState({
          addnewflag: true,
          snackbar: {
            open: true,
            message: "Elements added successfully",
            variant: "success",
          },
        });
        this.props.handleRefresh(this.state.serId);
      })
      .catch((err) => {
        this.setState({
          snackbar: {
            open: true,
            message: err.respose.data.error.message,
            variant: "error",
          },
        });
      });
  };
  setValue = (ind, e, val) => {
    const { section } = this.state;
    section[ind][e.target.name] = val;
    this.setState({ section });
  };
  setchildValue = (ind, chil, e, val) => {
    const { section } = this.state;
    section[ind].elements[chil][e.target.name] = val;
    this.setState({ section });
  };
  deleteClose = () => {
    this.setState({
      deleteDat: { open: false },
    });
  };
  handleEdit = (ind) => {
    const { section } = this.state;
    section[ind]["readOnly"] = false;
    this.setState({ section });
  };
  handleEditClose = (ind) => {
    const { section } = this.state;
    section[ind]["readOnly"] = true;
    this.setState({ section });
  };
  handleOnDragEnd = (result) => {
    const { elementList, section } = this.state;
    if (!result.destination) return;

    if (
      result.source.droppableId === "elements" &&
      result.destination.droppableId !== "elements"
    ) {
      let [reorderedItem] = elementList.splice(result.source.index, 1);
      reorderedItem = {
        ...reorderedItem,
        show_in_app: false,
        show_in_web: false,
        checkadd: true,
      };

      let index = section.length - 1;
      section[index]["addnew"] = true;
      section[index].elements.splice(
        result.destination.index,
        0,
        reorderedItem
      );

      this.setState({ elementList, section });
    } else if (
      result.source.droppableId !== "elements" &&
      result.destination.droppableId !== "elements"
    ) {
      let index = section.length - 1;
      let [reorderedItem] = section[index].elements.splice(
        result.source.index,
        1
      );
      section[index].elements.splice(
        result.destination.index,
        0,
        reorderedItem
      );
    } else if (
      result.source.droppableId === "elements" &&
      result.destination.droppableId === "elements"
    ) {
      let [reorderedItem] = elementList.splice(result.source.index, 1);
      elementList.splice(result.destination.index, 0, reorderedItem);
    }
  };
  render() {
    const { elementList, section, snackbar, deleteDat, addnewflag } =
      this.state;

    return (
      <div className="row">
        <DragDropContext onDragEnd={this.handleOnDragEnd}>
          <Droppable droppableId="elements">
            {(provided) => (
              <div
                className="elements-list col-md-12 col-xs-12"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {elementList &&
                  elementList.map((item, index) => (
                    <Draggable
                      isDragDisabled={item.readOnly}
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided) => (
                        <React.Fragment>
                          {!item.checkadd && (
                            <a
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              key={item.id}
                              href="#/"
                              onClick={(e) =>
                                this.handleClick(e, index, item.is_selected)
                              }
                              className={item.is_selected ? "highlight" : ""}
                            >
                              {item.tab}
                            </a>
                          )}
                        </React.Fragment>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          {section &&
            section.map((filt, index) => (
              <div className="addnew-section col-md-2 col-xs-12">
                <div className="section-card">
                  <form>
                    <div className="section-title">
                      <input
                        type="text"
                        name="name"
                        disabled={filt.readOnly}
                        placeholder={"Section name *"}
                        onChange={(e) =>
                          this.setValue(index, e, e.target.value)
                        }
                        className="form-control is-filled"
                        value={filt.name}
                      />
                      {!filt.readOnly && filt.name && (
                        <a
                          className="save"
                          onClick={(e) => this.handleAddSection(index, filt)}
                          href="#/"
                        >
                          <img
                            alt=""
                            src="/app-assets/backend-images/icons/tick.png"
                          />
                        </a>
                      )}
                      {filt.id && filt.readOnly && (
                        <a
                          className="edit"
                          href="#/"
                          onClick={(e) => this.handleEdit(index)}
                        >
                          <img
                            alt=""
                            src="/app-assets/backend-images/icons/edit.png"
                          />
                        </a>
                      )}
                      {filt.id && !filt.readOnly && (
                        <a
                          href="#/"
                          className="cancel"
                          onClick={(e) => this.handleEditClose(index)}
                        >
                          <img
                            alt=""
                            src="/app-assets/backend-images/icons/close.png"
                          />
                        </a>
                      )}
                      <a
                        href="#/"
                        className="cancel"
                        onClick={(e) =>
                          this.handleDeleteSection(index, filt.id)
                        }
                      >
                        <img
                          alt=""
                          src="/app-assets/backend-images/icons/delete-icon.svg"
                        />
                      </a>
                    </div>
                  </form>
                  <div className="section-content">
                    <div className="flex-box checkbox-block">
                      <div className="position-relative">
                        <input
                          type="checkbox"
                          id="show_in_web"
                          name="show_in_web"
                          disabled={filt.readOnly}
                          value={filt.show_in_web}
                          checked={filt.show_in_web}
                          onChange={(e) =>
                            this.setValue(index, e, e.target.checked)
                          }
                        />
                        <label className="" for="Web">
                          Web
                        </label>
                      </div>
                      <div className="position-relative">
                        <input
                          type="checkbox"
                          id="show_in_app"
                          name="show_in_app"
                          checked={filt.show_in_app}
                          disabled={filt.readOnly}
                          value={filt.show_in_app}
                          onChange={(e) =>
                            this.setValue(index, e, e.target.checked)
                          }
                        />
                        <label className="" for="App">
                          App
                        </label>
                      </div>
                    </div>

                    <Droppable
                      isDropDisabled={filt.id ? false : true}
                      droppableId={`section-body${index}`}
                    >
                      {(provided) => (
                        <div
                          className="section-body"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {filt.elements &&
                            filt.elements.map((item, chil) => (
                              <Draggable
                                isDragDisabled={item.readOnly}
                                key={item.id}
                                draggableId={item.id}
                                index={chil}
                              >
                                {(provided) => (
                                  <div
                                    className="elements-card"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div className="element-title">
                                      <p>{item.tab}</p>
                                      <a
                                        href="#/"
                                        className="delete-element red"
                                        onClick={(e) =>
                                          this.handleDelete(
                                            index,
                                            chil,
                                            item.ids
                                          )
                                        }
                                      >
                                        <img
                                          alt=""
                                          src="/app-assets/backend-images/icons/delete-icon.svg"
                                        />
                                      </a>
                                    </div>
                                    <div className="checkbox-block">
                                      <div className="position-relative">
                                        <input
                                          type="checkbox"
                                          id="show_in_web"
                                          name="show_in_web"
                                          value={item.show_in_web}
                                          disabled={item.readOnly}
                                          checked={item.show_in_web}
                                          onChange={(e) =>
                                            this.setchildValue(
                                              index,
                                              chil,
                                              e,
                                              e.target.checked
                                            )
                                          }
                                        />
                                        <label className="" for="element01-web">
                                          Web
                                        </label>
                                      </div>
                                      <div className="position-relative">
                                        <input
                                          type="checkbox"
                                          id="show_in_app"
                                          name="show_in_app"
                                          checked={item.show_in_app}
                                          disabled={item.readOnly}
                                          value={item.show_in_app}
                                          onChange={(e) =>
                                            this.setchildValue(
                                              index,
                                              chil,
                                              e,
                                              e.target.checked
                                            )
                                          }
                                        />
                                        <label className="" for="element01-app">
                                          App
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                          {filt.id && !filt.added && filt.addnew && (
                            <button
                              type="button"
                              onClick={(e) => {
                                this.saveSectionElements(index);
                              }}
                            >
                              save
                            </button>
                          )}
                        </div>
                      )}
                    </Droppable>
                  </div>
                </div>
              </div>
            ))}
        </DragDropContext>
        {section.length < 4 && addnewflag && (
          <div className="addnew-btn col-md-2 col-xs-12">
            <button
              className="btn btn-addnew"
              type="button"
              onClick={this.handleSectionAdd}
            >
              <img src="/app-assets/backend-images/icons/add.png" />
              Add Section
            </button>
          </div>
        )}
        <SnackbarMessage
          snackbarOpen={snackbar.open}
          snackbarMessage={snackbar.message}
          onSnackbarClose={this.onSnackbarClose}
          variant={snackbar.variant}
        />
        <DeleteComponent
          opendelete={deleteDat.open}
          handleDeleteClose={this.deleteClose}
          delete={this.handleDeleteFuntion}
        />
      </div>
    );
  }
}
