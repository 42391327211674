import React, { Component } from "react";
import { Link } from "react-router-dom";
import breadcrumbConfig from "../utils/breadcrumbConfig";
import "./breadcrumb.css";
import "./breadcrumb.css";

class BreadCrumb extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {}
    };
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps.data !== this.state.data) {
      this.setState({data: nextProps.data})
    }
  }
  render() {
    return (
      <div className="content-header row">
        <div className="content-header-left col-md-12 col-12 mb-2">
          <div className="row breadcrumbs-top">
            <div className="breadcrumb-wrapper col-12">
              <ol className="breadcrumb">
                {this.props.type ? (
                  breadcrumbConfig[this.props.type].map((url, index) => (
                    <li
                      key={index}
                      className={
                        "breadcrumb-item" + (url.isActive ? " active" : "")
                      }
                    >
                      {url.path ? (
                        <Link to={url.path}>{url.isPlaceholder ? this.state.data?.[url.name] || null : url.name}</Link>
                      ) : (
                        url.isPlaceholder ? this.state.data?.[url.name] || null : url.name
                      )}
                    </li>
                  ))
                ) : (
                  <></>
                )}
              </ol>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BreadCrumb;
