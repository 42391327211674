import { createMuiTheme } from "@material-ui/core/styles";

const CheckListStyle = createMuiTheme({
    overrides: {
        MuiDialogTitle:{
            root:{
               display:'flex',
               justifyContent:'space-between',
               alignItems:'center',
               '& h3':{
                color:'blue'
            }
        },
    },
    MuiDialogActions: {
        root: {
            padding:"25px",
           '& .btn.btn-primary':{
                fontSize: '14px',
                width: '25%'
            }
        }
    },
    MuiIconButton: {
        root: {
            color: "red"
        }

    },
    MuiFormLabel: {
        root: {
            color:"black",
            marginTop: 8
        }
    }

  }
});
export default CheckListStyle;
