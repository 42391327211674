import { TreeItem } from "@material-ui/lab";
import { withStyles } from "@material-ui/styles";
export const StyledTreeItem = withStyles({
  root: {
    padding: 3,
    "&:focus > $content": {
      backgroundColor: "none !important",
    },
    "&$selected > $content": {
      backgroundColor: "lightblue !important",
      color: "#000",
    },

    "&:focus > $content $label,  &$selected > $content $label": {
      backgroundColor: "lightblue !important",
    },
  },
  content: {
    "&$selected": {
      backgroundColor: "lightblue  !important",
      color: "#000",
    },
  },
  /*iconContainer: {
    "&$selected": {
      backgroundColor: "lightblue  !important",
      color: "#000",
    },
  },*/
  label: {
    "&$selected": {
      backgroundColor: "lightblue  !important",
      color: "#000",
    },
  },
})(TreeItem);
