import { Grid } from "@material-ui/core";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import apis from "../../../apiactions/api.actions";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../plugins/dataTable/dataTable";
import DeleteComponent from "../../../plugins/delete/delete.component";
import SearchField from "../../../plugins/searchfield";
import SelectBox from "../../../plugins/selectbox/commonSelectBox";
import { constitutioncat, deleteEntity, getEntities } from "../apifunctions";

class EntityType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      options: {},
      mainId: "",
      opendelete: false,
      formSearch: {
        constitution_category__id: "",
        search: "",
        page: 1,
      },
      searchParam: "",
      constData: [],

      tableConfig: {},
      pagesize: 0,
      masterData: this.props.masterData,
      totalCount: 0,
    };
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }
  componentWillReceiveProps = (props) => {
    this.setState({
      masterData: this.props.masterData,
    });
  };
  async componentDidMount() {
    try {
      await this.getData();
    } catch (err) {}
  }
  handleDeleteClose = () => {
    this.setState({
      opendelete: false,
    });
  };
  handleDeleteOpen = (e, reqData) => {
    this.setState({
      opendelete: true,
      mainId: reqData.id,
    });
  };
  handleDelete = () =>
    deleteEntity(this.state.mainId).then(() => {
      this.getData();
    });

  handlepagination(val) {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: val,
        },
      },
      () => {
        this.getData();
      }
    );
  }
  async getData(query) {
    this.setState({ loader: true });
    try {
      getEntities(this.state.formSearch)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            datas: res.results,
            totalCount: res.count,
            pagesize: res.page_size,
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.response.data.error.message,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
        });
    } catch (err) {}
  }
  handleEdit = (e, row) => {
    this.props.history.push(`${this.props.match.path}-edit/` + row.id);
  };

  handleChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        // let query = e.id ? "?constitution_category__id=" + e.id : "";
        // this.handlepagination();
      }
    );
  };
  handleClose = () => {};

  handleDataChange = (value) => {
    if (value.length >= 3) {
      this.setState({
        formSearch: {
          ...this.state.formSearch,
          constitution_category_name: value,
        },
      });
      let tag = constitutioncat + "?view=all&&search=" + value;
      this.getconstData(tag);
    }
  };

  handleSelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          constitution_category__id: e.id,
          constitution_category_name: e.constitution_category_name,
        },
      },
      () => {
        this.handleSearch();
      }
    );
  };
  async getconstData(query) {
    try {
      apis
        .getDatas(query)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            constData: res.constitution_categories,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.response.data.error.message.error,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }
  handleClear = (name) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [name]: "",
        },
      },
      () => {
        this.handleSearch();
      }
    );
  };
  handleSearch = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: 1,
        },
      },
      () => {
        this.getData();
      }
    );
  };
  render() {
    const { totalCount, formSearch, constData, loader } = this.state;
    const { masterData } = this.props;

    return (
      <div className="card-content collapse show">
        <CommonLoader open={loader} />
        <div className="card-body card-dashboard">
          <Grid container spacing={1}>
            <Grid item xs={6} sm={3} className="table-search">
              {/* <div className="position-relative">
                <input
                  type="text"
                  name="search"
                  value={formSearch.search}
                  className="form-control"
                  placeholder="Search"
                  onChange={this.handleChange}
                />
                <button
                  className="btn-transparent"
                  onClick={(e) => this.handleSearch(e)}
                >
                  <img src="/app-assets/backend-images/icons/search-icon.svg" />
                </button>
              </div> */}
              <SearchField
                onChange={this.handleChange}
                value={formSearch.search}
                handleSearch={this.handleSearch}
                handleClear={this.handleClear}
              />
            </Grid>
            <Grid item xs={6} sm={2} className="table-search">
              <SelectBox
                Data={constData}
                id="id"
                async="true"
                value={formSearch.constitution_category_name}
                desc="constitution_category_name"
                getData={this.handleDataChange}
                placeholder="Category"
                className="form-control"
                onChange={this.handleSelectChange}
              />
            </Grid>
            <Grid align="right" item xs={6} sm={7}>
              <Link
                to={`${this.props.match.path}-create/new`}
                className="btn btn-primary"
              >
                + &nbsp; Add New
              </Link>
            </Grid>

            <Grid item xs={12} sm={12} className="table-block">
              <DataTable
                data={this.state.datas}
                columns={[
                  {
                    title: "Unique Id",
                    type: "text",
                    field: "entity_id",
                  },
                  {
                    title: "Constitution Category",
                    type: "text",
                    field: "constitution_category_name",
                    render: (rowData) =>
                      rowData.constitution_category && (
                        <span>
                          {
                            rowData.constitution_category
                              .constitution_category_name
                          }
                        </span>
                      ),
                  },
                  {
                    title: "Entity Type Variation",
                    type: "text",
                    field: "entity_variation_name",
                  },
                  {
                    title: "Status",
                    field: "status",
                    render: (rowData) => (
                      <span
                        span
                        style={{
                          backgroundColor:
                            rowData.status.name === "Active"
                              ? "#78DEC0"
                              : "#DEDEDE",
                          padding: "0px 8px",
                        }}
                      >
                        {rowData.status.name}
                      </span>
                    ),
                    type: "status",
                  },
                ]}
                options={{
                  sort: true,
                  sortvalue: "entity_id",
                  pageNo: this.state.formSearch.page,
                  pagination: true,
                  totalCount: totalCount,
                  pagesize: this.state.pagesize,
                  handlepagination: this.handlepagination.bind(this),
                }}
                actions={[
                  {
                    className: "edit",
                    rowActionCLick: this.handleEdit.bind(this),
                    iconName: "/app-assets/backend-images/icons/edit-icon.svg",
                    tooltip: "Edit ",
                  },
                ]}
              />
            </Grid>
          </Grid>
        </div>
        <DeleteComponent
          opendelete={this.state.opendelete}
          handleDeleteClose={this.handleDeleteClose}
          delete={this.handleDelete}
        />
      </div>
    );
  }
}

export default EntityType;
