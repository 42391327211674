import React , { Component } from 'react';
import { Grid } from "@material-ui/core";
import SnackbarMessage from "../../../plugins/snackbar-component";
import { getSpecialization, updateSpecialization } from "../apifunctions";

let specializationId = "";
class SpecializationEdit extends Component {
  constructor (props) {
    super(props);
    this.state = {
      snackbarMessage: "",
      snackbarOpen: false,
      snackbarVariant: "",
      formData: {},
      mainId: "",
      opendelete: false,
    };
      specializationId = this.props.match.params.id;
    }
    
  componentDidMount = () => {
      if (specializationId) {
        this.getData();
      }
  };
  
  async getData() {
      try {
        getSpecialization(specializationId)
          .then((res) => res.data)
          .then((res) => {
            this.setState({
              formData: res,
            });
          })
          .catch((err) => {
            this.setState({
              snackbarMessage: err.response.data.error.message.error,
              snackbarOpen: true,
              snackbarVariant: "error",
            });
          });
      } catch (err) {}
  }
  
  handleChange = (e) => {
      let value = e.target.value;
      if (e.target.value.trim() === "") {
        value = "";
      }
      this.setState({
        formData: {
          ...this.state.formData,
          [e.target.name]: value,
        },
      });
  };
  
  handleSubmit = (event) => {
      event.preventDefault();
      updateSpecialization(specializationId, this.state.formData)
        .then((res) => {
          this.setState(
            {
              snackbarMessage: "Updated Successfully",
              snackbarOpen: true,
              snackbarVariant: "success",
            },
            () => {
              setTimeout(() => {
                this.props.history.push(
                  "/data-management/specializations"
                );
              }, 2500);
            }
          );
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.response.data.error.message.error,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
  };
  
  handleClose = () => {
      this.props.history.push("/data-management/specializations");
  };
  
  onSnackbarClose = () => {
      this.setState({ snackbarOpen: false });
  };
  
  render() {

    const { masterData } = this.props;
    const { formData } = this.state;

    return (
      <div className="create-content">
        <div className="content-body">
          <form className="edit-form" onSubmit={this.handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6}>
                <h4 className="card-title">Edit Specializations</h4>
              </Grid>
              <Grid align="right" item xs={6} sm={6}>
                <button
                  style={{ marginRight: 6 }}
                  onClick={(e) => this.handleClose()}
                  className="btn btn-secondary"
                  type="button"
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </Grid>
            </Grid>    
            <div className="card-content">
              <div className="row">
                <div className="col-md-7 col-xs-12">
                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <label className="control-label">
                            Specialization Name  
                          <span className="red">*</span>
                      </label>
                      <input type="text" 
                             name="specialization_name" 
                             class="form-control" 
                             required
                             onChange={this.handleChange}
                             value={formData.specialization_name}
                             placeholder="Specialization Name" />
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <label className="control-label">
                          Display Name 
                          <span className="red">*</span>
                      </label>
                      <input type="text" 
                             name="specialization_displayname" 
                             class="form-control"
                             value={formData.specialization_displayname}
                             required
                             onChange={this.handleChange}
                             placeholder="Display Name"  />
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <label className="control-label">
                        Specialization Code
                        <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        required
                        name="specialization_code"
                        value={formData.specialization_code}
                        class="form-control"
                        onChange={this.handleChange}
                        placeholder="Specialization Code"
                      />
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <label className="control-label">
                        Status <span className="red">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="status"
                        required
                        onChange={this.handleChange}
                        value={formData.status}
                      >
                        <option value="" selected>
                          Status
                        </option>
                        {masterData &&
                          masterData.statuses &&
                          masterData.statuses.map(({ id, name }) => (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-md-12 col-xs-12">
                      <label className="control-label">Description</label>
                      <textarea
                        name="description"
                        class="form-control"
                        placeholder="Description"
                        rows="5"
                        value={formData.description}
                        onChange={this.handleChange}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 col-xs-12">
                  <div className="col-md-12 col-xs-12">
                    <label className="control-label">Thumbnail Image </label>
                      <div className="upload-img">
                        <input
                              type="file"
                              onChange={this.handleFileChange}
                              className="file-upload"
                              id="img-upload"
                        />   
                        <label for="img-upload">
                          <img alt=""
                               id="output"
                               src="/app-assets/backend-images/thumbnail-business-sector.png" />
                          <div className="upload-icon">
                            <img src="/app-assets/backend-images/icons/camera.svg" />
                          </div>
                          </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <SnackbarMessage
                snackbarOpen={this.state.snackbarOpen}
                snackbarMessage={this.state.snackbarMessage}
                onSnackbarClose={this.onSnackbarClose}
                variant={this.state.snackbarVariant}
              />
      </div>
    );
  }
}
export default SpecializationEdit;