const styles = (theme) =>({
  title:{
      display:'flex',
      flexDirection: 'column',
      marginTop:20,
      alignItems:'center',
      '& h4':{
          padding:'10px'
      }
  },
  content: {
      minWidth: 400,
      fontSize: '16px',
      padding:'15px 24px',
      textAlign:'center'
  },
  closeButton:{
     color: 'red',
     borderRadius: '100%',
     border:'3px solid red',
     '& svg':{
        fontSize:'2em'
      }
  },
  actionsButton:{
      justifyContent:'center',
      padding:'30px',
      gap:'20px',
      '& button':{
          minWidth:145
      }
  }
})
export default styles;
