import React, { useState, useEffect } from 'react'
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core/styles";
import styles from "./file-upload.css";
import {
    updateFolder,
    updateFile
  } from "../apifunctions";


function Transition(props) {
    return <Slide direction="down" {...props} />;
}

const Rename = (props) => {
    const [name, setName] = useState('')
    const [row, setRow] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [apiObj, setApiObj] = useState({})

    useEffect(() => {
        const row = props.row || {}
        setRow(row)
        setName(row.name)
        setShowModal(props.showModal)
    }, [props.showModal])

    const onClickUpdate = (e) => {
        e.preventDefault()
        if (!name) { setApiObj({error: "Name required."}); return; }
        updateData()
    }
    //API Call starts
    const updateData = async () => {
        try {
            const employee = JSON.parse(localStorage.getItem('myProfile'))
            const req = { name: name, id: row.id, created_by: employee.id, path: row.path }
            // if (!row.is_directory) req = {...req, created_by: employee.id, path: row.path}
            const res = row.is_directory ? await updateFolder(req) : await updateFile(req)
            if (res.success) {
                onClickClose()
                props.showSnackbar({message: 'Updated Successfully!', type: 'success'})
            } else {
                setApiObj({error: 'Problem on updating!'})
            }
        } catch (err) {
            setApiObj({error: 'Problem on updating!'})
            console.error('updateData err >> ', err)
            props.showSnackbar({message: err.response.data.error?.message || err.message, type: 'error'})
        }
    }
    //API Call ends
    const onClickClose = () => {
        props.reload()
        props.onCloseModal()
        setName("")
        setShowModal(false)
        setApiObj({})
    }
    return (
        <>
        <Dialog
            open={showModal}
            TransitionComponent={Transition}
            onClose={() => onClickClose()}
            fullWidth="xs"
            maxWidth="xs"
            scroll="body"
            className="modal-mini"
            id="update_folder"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
        >
            <div className="modal-dialog-centered">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">UPDATE {!row.is_directory ? 'FILE' : 'FOLDER'}</h5>
                    <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => onClickClose()}
                    >
                    <img src="/app-assets/backend-images/icons/close-modal.svg" />
                    </button>
                </div>
                <div className="modal-body">
                    <form>
                    <fieldset>
                        <label className="control-label">{!row.is_directory ? 'File' : 'Folder'} Name</label>
                        <input type="text" name="folder-name" className="form-control" value={name} onChange={(e) => setName(e.target.value)}/>
                        <span style={{color: 'red'}}>{apiObj.error}</span>
                    </fieldset>
                    </form>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" onClick={(e) => onClickUpdate(e)}>UPDATE</button>
                </div>
                </div>
            </div>
        </Dialog>  
        </>
    )
}

export default withStyles( styles )( Rename );