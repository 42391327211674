import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../plugins/dataTable/dataTable";
import SearchField from "../../../plugins/searchfield";
import SnackbarMessage from "../../../plugins/snackbar-component";



class OrdersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],         
      formSearch:{
          page: 1,
          search:''
      },
      snackbarOpen: false,
      snackbarMessage: "",
      snackbarVariant: "",
    };
}

async componentDidMount() {
  try {
      
  } catch (err) {}
}
  
handleChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        if (e.target.name !== "search") {
          this.handleSearch();
        }
      }
    );
};
 
handleSearch = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: 1,
        },
      },
      () => {
        this.getData();
      }
    );
};
  
handleClear = (name) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [name]: "",
        },
      },
      () => {
        this.handleSearch();
      }
    );
};


onSnackbarClose = () => {
  this.setState({ snackbarOpen: false });
};

 
render() {

    const { 
      loader, 
      totalCount, 
      formSearch, 
      pagesize, 
      editMode, 
      formData } = this.state;
    
  return (
      <div className="card-content collapse show">
        <CommonLoader open={loader} />
        <div className="card-body card-dashboard">
        <Grid container spacing={1}>
                <Grid item xs={6} sm={4} className="table-search">
                  <SearchField
                    onChange={this.handleChange}
                    value={formSearch.search}
                    handleSearch={this.handleSearch}
                    handleClear={this.handleClear}
                  />
                </Grid>
                
            <Grid item xs={6} sm={2} className="table-search">
              <select
                className="form-control"
                name=""
                onChange={this.handleChange}
                //value={}
              >
                <option value="" selected>
                  Payment Status
                </option>
                {/*{employee_list &&
                  employee_list.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}*/}
              </select>
            </Grid>
            
            <Grid item xs={6} sm={2} className="table-search">
              <select
                className="form-control"
                name=""
                onChange={this.handleChange}
                //value={}
              >
                <option value="" selected>
                  Order Status
                </option>
                {/*{employee_list &&
                  employee_list.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}*/}
              </select>
            </Grid>
                <Grid item align="right" xs={6} sm={4}>
                   <Link
                         className="btn btn-primary"
                         /*onClick={(e) =>
                          this.handleAdd(e) }*/
                    >
                         + &nbsp; Add New
                   </Link>
                         </Grid>
                <Grid item xs={12} sm={12} className="table-block">
                    <DataTable
                      data={this.state.datas}
                      columns={[
                          /*{
                            title: "SL",
                            type: "String",
                            field: "slNo",
                          },*/
                          {
                            title: "Order ID",
                            type: "text",
                            field: "order_id",
                          },
                          {
                            title: "Order Date",
                            type: "String",
                            field: "order_date",
                          },
                          { title: "Customer ID", 
                            type: "text"
                             
                          },
                          { title: "Company Name", 
                            type: "text", 
                            
                          },
                          {
                            title: "Order Summary",
                            type: "text",
                            
                          },
                          {
                            title: "Order Value",
                            type: "text"
                            
                          },
                          {
                            title: "Payment Status",
                            type: "text"
                            
                          },
                          {
                            title: "Order Status",
                            type: "text"
                            
                          },
                          {
                            title: "Referal Amount",
                            type: "text",
                          },
                          
                        ]}
                          options={{
                            sort: true,
                            sortvalue: "name",
                            pageNo: formSearch.page,
                            pagination: true,
                            totalCount: totalCount,
                            pagesize: pagesize,
                            //handlepagination: this.handlepagination.bind(this),
                          }}
                          actions={[
                            {
                              className: "edit",
                              //rowActionCLick: this.handleEdit.bind(this),
                              iconName: "/app-assets/backend-images/icons/edit-icon.svg",
                              tooltip: "Edit ",
                            },
                            {
                              className: "delete",
                              //rowActionCLick: this.handleDeleteOpen.bind(this),
                              iconName:
                                "/app-assets/backend-images/icons/delete-icon.svg",
                              tooltip: "Delete",
                            }
                          ]}
                      />
                  </Grid>
               </Grid>
              </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />  
      </div>
      );
    }
}
export default OrdersList;