import { Grid } from "@material-ui/core";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../plugins/dataTable/dataTable";
import DeleteComponent from "../../../plugins/delete/delete.component";
import SearchField from "../../../plugins/searchfield";
import { deleteFiles, govFileSearch, govt_fillings } from "../apifunctions";
class GovernmentFilings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      options: {},
      mainId: "",
      opendelete: false,
      formSearch: {
        filing_types: "",
        priority: "",
        status: "",
        page: 1,
        search: "",
      },
      searchParam: "",
      tableConfig: {},
      pagesize: 0,
      masterData: this.props.masterData,
      totalCount: 0,
    };
  }
  componentWillReceiveProps = (props) => {
    this.setState({
      masterData: this.props.masterData,
    });
  };
  async componentDidMount() {
    try {
      await this.getData();
    } catch (err) {}
  }
  handleDeleteClose = () => {
    this.setState({
      opendelete: false,
    });
  };
  handleDeleteOpen = (e, reqData) => {
    this.setState({
      opendelete: true,
      mainId: reqData.id,
    });
  };
  handleDelete = () =>
    deleteFiles(this.state.mainId).then(() => {
      this.getData(govt_fillings);
    });

  handlepagination(val) {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: val,
        },
      },
      () => {
        this.getData();
      }
    );
  }
  getData() {
    this.setState({ loader: true });
    try {
      govFileSearch(this.state.formSearch)
        .then((res) => res.data)                 
        .then((res) => {
          this.setState({
            datas: res.results,
            totalCount: res.count,
            pagesize: res.page_size,
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.response.data.error.message.error,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
        });
    } catch (err) {}
  }
  handleEdit = (e, row) => {
    this.props.history.push(`${this.props.match.path}-edit/` + row.id);
  };

  handleClose = () => {};
  handleChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [e.target.name]: e.target.value,
          page: 1,
        },
      },
      () => {
        if (e.target.name !== "search") {
          this.getData();
        }
      }
    );
  };
  handleClear = (name) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [name]: "",
        },
      },
      () => {
        this.handleSearch();
      }
    );
  };
  handleSearch = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,

          page: 1,
        },
      },
      () => {
        this.getData();
      }
    );
  };
  render() {
    const { totalCount, formSearch, loader } = this.state;
    const { masterData } = this.props;

    return (
      <div className="card-content collapse show">
        <CommonLoader open={loader} />
        <div className="card-body card-dashboard">
          <Grid container spacing={1}>
            {/* <Grid align="right" item xs={6} sm={12}>
              <Link
                to={`${this.props.match.path}-create/new`}
                className="btn btn-primary"
              >
                + &nbsp; Add New
              </Link>
            </Grid> */}
            <Grid item xs={6} sm={2} className="table-search">
              {/* <div className="position-relative">
                <input
                  type="text"
                  name="search"
                  className="form-control"
                  placeholder="Search"
                  onChange={this.handleChange}
                />
                <button
                  className="btn-transparent"
                  onClick={(e) => this.getData()}
                >
                  <img src="/app-assets/backend-images/icons/search-icon.svg" />
                </button>
              </div> */}
              <SearchField
                onChange={this.handleChange}
                value={formSearch.search}
                handleSearch={this.handleSearch}
                handleClear={this.handleClear}
              />
            </Grid>
            <Grid item xs={6} sm={2} className="table-search">
              <select
                className="form-control"
                name="filing_types"
                onChange={this.handleChange}
                value={formSearch.filing_types ? formSearch.filing_types : ""}
              >
                <option value="">Type</option>
                {masterData.filing_types &&
                  masterData.filing_types.map((filt, ind) => (
                    <option key={filt.id} value={filt.id}>
                      {filt.filing_type}
                    </option>
                  ))}
              </select>
            </Grid>
            {/* <Grid item xs={6} sm={2} className="select-box">
              <select className="form-control">
                <option>Frequency</option>
                <option>Category</option>
                <option>Category</option>
                <option>Category</option>
              </select>
            </Grid> */}
            <Grid item xs={6} sm={2} className="table-search">
              <select
                className="form-control"
                onChange={this.handleChange}
                value={formSearch.priority ? formSearch.priority : ""}
                name="priority"
              >
                <option value="">Priority</option>
                {masterData.filing_priority &&
                  masterData.filing_priority.map((filt, ind) => (
                    <option key={filt} value={filt}>
                      {filt}
                    </option>
                  ))}
              </select>
            </Grid>
            {/* <Grid item xs={6} sm={2} className="select-box">
              <select className="form-control">
                <option>Entity Type</option>
                <option>Category</option>
                <option>Category</option>
                <option>Category</option>
              </select>
            </Grid> */}
            <Grid item xs={6} sm={2} className="table-search">
              <select
                className="form-control"
                onChange={this.handleChange}
                value={formSearch.status ? formSearch.status : ""}
                name="status"
              >
                <option value="">Status</option>
                {masterData.statuses &&
                  masterData.statuses.map((filt, ind) => (
                    <option key={filt.id} value={filt.id}>
                      {filt.name}
                    </option>
                  ))}
              </select>
            </Grid>
            {/*add */}
            <Grid align="right" item xs={6} sm={4}>
              <Link
                to={`${this.props.match.path}-create/new`}
                className="btn btn-primary"
              >
                + &nbsp; Add New
              </Link>
            </Grid>
            {/*add */}
            <Grid item xs={6} sm={12} className="table-block">
              <DataTable
                data={this.state.datas}         
                columns={[
                  {
                    title: "Filings",
                    type: "text",
                    field: "filing_name",
                  },
                  {
                    title: "Filing Type",
                    type: "text",
                    field: "filing_type_name",
                  },
                  {
                    title: "Filing Frequency",
                    type: "text",
                    field: "compliance_filing_frequency",
                  },
                  {
                    title: "Filing Day",
                    field: "filing_day",
                    align: "center",
                  },
                  {
                    title: "Priority",
                    field: "priority",
                  },
                  {
                    title: "Status",
                    type: "status",
                    field: "status_name",
                  },
                ]}
                options={{
                  sort: true,
                  sortvalue: "filing_name",
                  pageNo: this.state.formSearch.page,
                  pagination: true,
                  totalCount: totalCount,
                  pagesize: this.state.pagesize,
                  handlepagination: this.handlepagination.bind(this),
                }}
                actions={[
                  {
                    className: "edit",
                    rowActionCLick: this.handleEdit.bind(this),
                    iconName: "/app-assets/backend-images/icons/edit-icon.svg",
                    tooltip: "Edit ",
                  },
                ]}
              />
            </Grid>
          </Grid>
        </div>
        <DeleteComponent
          opendelete={this.state.opendelete}
          handleDeleteClose={this.handleDeleteClose}
          delete={this.handleDelete}
        />
      </div>
    );
  }
}

export default GovernmentFilings;
