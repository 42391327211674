import { TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datatable.css";
import SelectStyle from "./selectbox/selectoveride.syle";
let dateFormat = "DD-MM-YYYY";
let dateTimeFormat = "DD-MM-YYYY HH:mm";
class CalendarComponent extends React.Component {
  constructor(props) {
    super();
    this.state = {
      toggleHeader: "",
      values: "",
    };
  }
  componentWillReceiveProps = (props) => {
    this.setState({});
  };
  handleDatePickerChange = (value, name) => {
    const { values } = this.state;
    if (value !== undefined) {
      let date = value;
      if (date) {
        this.props.onChange({ value: date, name: this.props.name });
      } else {
        this.props.onChange({ value: null, name: this.props.name });
      }
    } else {
      this.props.onChange({ values: null, name: this.props.name });
    }
  };
  render() {
    const {
      classes,
      selected,
      label,
      margin,
      onChange,
      name,
      placeholder,
      readOnly,
      error,
      dateTime,
      required,
      showTimeSelect,
      timeIntervals,
      timeFormat,
      popperPlacement,
      popperClassName,
      showPopperArrow,
      id,
      showYearPicker,
      maxDate,

      showMonthYearPicker,
      disabled,
      inputStyle,
      minDate,
      InputLabelProps,
      selectsRange,
      startDate,
      endDate,
      rootStyle,
    } = this.props;
    const { value } = this.state;
    let data = Date.parse(selected);

    return (
      <div>
        <MuiThemeProvider theme={SelectStyle}>
          <DatePicker
            id={id}
            selectsRange={selectsRange}
            startDate={startDate}
            endDate={endDate}
            name={name}
            // selected={selected ? moment(data).toString() : ""}
            selected={data ? data : ""}
            onChange={(date) =>
              selectsRange ? onChange(date) : this.handleDatePickerChange(date)
            }
            fullWidth
            required={required}
            dateFormat={
              dateTime
                ? "dd-MMM-yyyy HH:mm"
                : showYearPicker
                ? "yyyy"
                : showMonthYearPicker
                ? "MMM-yyyy"
                : "dd-MMM-yyyy"
            }
            showYearPicker={showYearPicker}
            showMonthYearPicker={showMonthYearPicker}
            maxDate={maxDate}
            minDate={minDate}
            wrapperClassName={{ width: "100%" }}
            popperClassName={popperClassName}
            timeFormat={timeFormat}
            timeIntervals={timeIntervals}
            showPopperArrow={showPopperArrow}
            // className={classes.searchText}
            showYearDropdown
            showTimeSelect={showTimeSelect}
            popperPlacement={popperPlacement}
            error={error}
            readOnly={readOnly}
            disabled={readOnly}
            placeholderText={
              placeholder
                ? placeholder
                : showYearPicker
                ? "YYYY"
                : showMonthYearPicker
                ? "MMM-YYY"
                : dateTime
                ? "DD-MM-YYYY HH:mm"
                : "DD-MM-YYY"
            }
            margin={margin}
            customInput={
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true, ...InputLabelProps }}
                required={required}
                error={error}
                value={selected}
                margin={margin}
                disabled={readOnly}
                placeholder="DD-MM-YYY"
                className="form-control"
                InputProps={{
                  autoComplete: "off",
                  classes: {
                    input: inputStyle,
                    root: rootStyle,
                  },
                  readOnly: readOnly,
                  endAdornment: (
                    <InputAdornment
                      style={{ marginLeft: "3px !important" }}
                      position="end"
                    >
                      {" "}
                      <CalendarTodayOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            }
          />
        </MuiThemeProvider>
      </div>
    );
  }
}

export default CalendarComponent;
