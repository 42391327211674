const styles = (theme) => ({
  loader: {
    marginRight: "50%",
    marginTop: "25%",
    margin: "0 auto",
    // color: DynamicTheme.loader,
    border: "0px ! important",
  },

  fleettloader: {
    // marginLeft:'50%',
    marginTop: "8%",
    // margin:'0 auto',
    // color: DynamicTheme.loader,
    border: "0px ! important",
  },
  loaderWrapper: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
    height: "100%",
    placeContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    boxSizing: "border-box",
    zIndex: 200,
  },
});
export default styles;
