import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import SnackbarMessage from "../snackbar-component";
import styles from "./delete.style";
function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class DeleteComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      errors: {},
      snackbarOpen: false,
      snackbarMessage: "",
      snackbarVariant: "",
    };

    this.handleDelete = this.handleDelete.bind(this);
  }

  handleClose = () => {
    this.props.handleDeleteClose();
  };

  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  handleDelete() {
    this.setState({ submitted: true });
    this.props
      .delete()
      .then(() => {
        console.log("in delete compo");
        this.props.handleDeleteClose();
        this.setState({ submitted: false });
        this.setState({
          snackbarOpen: true,
          snackbarMessage: "Deleted Successfully",
          snackbarVariant: "success",
        });
      })
      .catch((err) =>
        this.setState({
          submitted: false,
          snackbarOpen: true,
          snackbarMessage: err.response.data.error?.message[0] || err.response.data.error,
          snackbarVariant: "error",
        })
      );
  }

  render() {
    const { opendelete, classes } = this.props;
    const { submitted } = this.state;
    return (
      <div>
        <Dialog
          open={opendelete}
          onClose={this.handleClose}
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle disableTypography className={classes.title}>
            <IconButton
              className={classes.closeButton}
              aria-label="close"
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>
            <h4>Delete</h4>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <p>Are you sure you want to delete?</p>
          </DialogContent>
          <DialogActions className={classes.actionsButton}>
            <Button
              variant="outlined"
              color="primary"
              onClick={this.handleClose}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              onClick={this.handleDelete}
              autoFocus
              disabled={submitted}
              style={{ backgroundColor: "#2e6adf" }}
            >
              DELETE
            </Button>
          </DialogActions>
        </Dialog>

        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}
export default withStyles(styles)(DeleteComponent);
