import { Grid } from "@material-ui/core";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../plugins/dataTable/dataTable";
import DeleteComponent from "../../../plugins/delete/delete.component";
import SearchField from "../../../plugins/searchfield";
import {
  deleteConstitutionCategoriesRow,
  searchConstitutionCategoriesData,
} from "../apifunctions";

class ConstitutionCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datas: [],
      options: {},
      mainId: "",
      opendelete: false,
      formSearch: {
        search: "",
        status: "",
        page: 1,
      },
      pagesize: 0,
      totalCount: 0,
    };
  }

  async componentDidMount() {
    try {
      await this.getData();
    } catch (err) {}
  }

  handlepagination(id) {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: id,
        },
      },
      () => {
        this.getData();
      }
    );
  }

  handleDeleteClose = () => {
    this.setState({
      opendelete: false,
    });
  };
  handleDeleteOpen = (e, reqData) => {
    this.setState({
      opendelete: true,
      mainId: reqData.id,
    });
  };

  handleDelete = () =>
    deleteConstitutionCategoriesRow(this.state.mainId).then(() => {
      this.getData();
    });

  handleEdit = (e, row) => {
    this.props.history.push(`${this.props.match.path}-edit/` + row.id);
  };

  handleChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        if (e.target.name !== "search") {
          //
          this.handleSearch();
        }
      }
    );
  };

  handleSearch = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: 1,
        },
      },
      () => {
        this.getData();
      }
    );
  };
  
  handleClear = (name) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [name]: "",
        },
      },
      () => {
        this.handleSearch();
      }
    );
  };

  async getData() {
    this.setState({ loader: true });
    try {
      searchConstitutionCategoriesData(this.state.formSearch)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            datas: res.results,
            totalCount: res.count,
            pagesize: res.page_size,
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.message,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
        });
    } catch (err) {}
  }

  render() {
    const { masterData } = this.props;
    const { loader, totalCount, pagesize, formSearch } = this.state;

    return (
      <div className="card-content collapse show">
        <CommonLoader open={loader} />
        <div className="card-body card-dashboard">
          <Grid container spacing={1}>
            <Grid item xs={6} sm={3} className="table-search">
              <SearchField
                onChange={this.handleChange}
                value={formSearch.search}
                handleSearch={this.handleSearch}
                handleClear={this.handleClear}
              />
            </Grid>

            <Grid item xs={6} sm={2}>
              <select
                className="form-control"
                name="status"
                onChange={this.handleChange}
                value={formSearch.status ? formSearch.status : ""}
              >
                <option value="" selected>
                  Status
                </option>
                {masterData &&
                  masterData.statuses &&
                  masterData.statuses.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
              </select>
            </Grid>
            <Grid align="right" item xs={6} sm={7}>
              <Link
                to={`${this.props.match.path}-create`}
                className="btn btn-primary"
              >
                + &nbsp; Add New
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} className="table-block">
              <DataTable
                data={this.state.datas}
                columns={[
                  {
                    title: "Category Code",
                    type: "text",
                    field: "constitution_category_code",
                  },
                  {
                    title: "Category Name",
                    type: "text",
                    field: "constitution_category_name",
                  },
                  {
                    title: "Display Name",
                    type: "text",
                    field: "constitution_category_display_name",
                  },
                  {
                    title: "Status",
                    type: "status",
                    field: "status",
                    render: (rowData) => (
                      <span>{rowData.status ? rowData.status.name : ""}</span>
                    ),
                  },
                ]}
                options={{
                  sort: true,
                  sortvalue: "constitution_category_code",
                  pageNo: this.state.formSearch.page,
                  pagination: true,
                  totalCount: totalCount,
                  pagesize: pagesize,
                  handlepagination: this.handlepagination.bind(this),
                }}
                actions={[
                  {
                    className: "edit",
                    rowActionCLick: this.handleEdit.bind(this),
                    iconName: "/app-assets/backend-images/icons/edit-icon.svg",
                    tooltip: "Edit Constitution Category",
                  },
                  {
                    className: "delete",
                    rowActionCLick: this.handleDeleteOpen.bind(this),
                    iconName:
                      "/app-assets/backend-images/icons/delete-icon.svg",
                    tooltip: "Delete Constitution Category",
                  },
                ]}
              />
            </Grid>
          </Grid>
        </div>
        <DeleteComponent
          opendelete={this.state.opendelete}
          handleDeleteClose={this.handleDeleteClose}
          delete={this.handleDelete}
        />
      </div>
    );
  }
}

export default ConstitutionCategories;
