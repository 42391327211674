import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { Component } from "react";
import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import {
  getCustomerAddress,
  getLocationList,
  newCustomerAddress,
  updateCustomerAddress,
} from "../../../apifunctions";
// import AddressKyc from "./addressKyc.comp";

let key = 0;
class AddressInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      snackbarOpen: false,
      submited: false,
      snackbarVariant: "",
      editble: false,
      loader: false,
      country_list: "",
      state_list: "",
      mainId: {},
      // addressKyc: [],
      snackbarMessage: "",
      formData: [],
    };
  }
  componentDidMount = () => {
    this.loadLocation();

    if (this.props.custId) {
      this.getData();
    }
  };
  getData() {
    let data = [];

    this.setState({ loader: true });

    getCustomerAddress(this.props.custId)
      .then((res) => res.data)
      .then((res) => {
        data = res.customer_address;
        // if (data && data.length !== 0) {
        //   data.forEach((item) => {
        //     item["kyctypes"] = JSON.parse(
        //       localStorage.getItem("masterData")
        //     ).customer_address_kyc_types;
        //     if (item.address_kyc && item.address_kyc.length !== 0) {
        //       item.address_kyc.forEach((kyc) => {
        //         item.kyctypes.forEach((types) => {
        //           if (kyc.document_type && kyc.document_type.id === types.id) {
        //             types["checked"] = true;
        //           }
        //         });
        //       });
        //     } else {
        //       item["address_kyc"] = [{ address: item.id }];
        //     }
        //   });
        // } else {
        //   data = [
        //     {
        //       customer: this.props.custId,
        //       country: "India",
        //     },
        //   ];
        // }
        this.setState(
          {
            formData: [],
            loader: false,
          },
          () => {
            this.setState({ formData: data });
            key = key + 1;
          }
        );
      })
      .catch((err) => {
        this.setState({
          datas: [],
          loader: false,
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  }
  handleData = (data) => {};
  handleChange = (e, index) => {
    const { formData } = this.state;
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }
    formData[index] = { ...formData[index], [e.target.name]: value };
    this.setState({
      formData,
    });
  };
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  handleUpdate = (e, ind) => {
    e.preventDefault();
    const { formData } = this.state;
    formData[ind]["submited"] = true;
    this.setState({ formData });
    let data = {};
    data = formData[ind];
    console.log(data);
    updateCustomerAddress(this.props.custId, data)
      .then((res) => {
        this.setState(
          {
            snackbarMessage: "Updated Successfully",
            snackbarOpen: true,
            snackbarVariant: "success",
            submited: false,
          },
          () => {
            this.getData();
          }
        );
      })
      .catch((err) => {
        formData[ind]["submited"] = false;
        var error_message = this.props.loadError(
          err.response.data.error.message
        );
        this.setState({
          snackbarMessage: error_message,
          snackbarOpen: true,
          submited: false,
          formData,
          snackbarVariant: "error",
        });
      });
  };
  handleSubmit = (event, ind) => {
    event.preventDefault();
    const { formData } = this.state;
    formData[ind]["submited"] = true;
    this.setState({ formData });
    let data = this.state.formData[ind];
    console.log(this.props.custId, data);
    newCustomerAddress(this.props.custId, [data])
      .then((res) => res.data)
      .then((res) => {
        this.setState(
          {
            formData: res,
            snackbarMessage: "Created Successfully",
            snackbarOpen: true,
            snackbarVariant: "success",
          },
          () => {
            this.getData();
          }
        );
      })
      .catch((err) => {
        formData[ind]["submited"] = false;
        var error_message = this.props.loadError(
          err.response.data.error.message
        );
        this.setState({
          snackbarMessage: error_message,
          snackbarOpen: true,
          formData,
          snackbarVariant: "error",
        });
      });
  };
  handleAddNew = () => {
    const { formData } = this.state;
    formData.push({ customer: this.props.custId });
    this.setState({ formData });
  };
  handleCancel = (index) => {
    const { formData } = this.state;

    formData.splice(index, 1);
    this.setState({ formData });
  };
  handleChildCancel = (ind, child) => {
    const { formData } = this.state;
    formData[ind].address_kyc.splice(child, 1);

    this.setState({ formData });
  };
  handleEdit = (index) => {
    const { formData } = this.state;
    formData[index]["editble"] = true;
    this.setState({ formData });
  };
  handleDisableEdit = (index) => {
    const { formData } = this.state;
    formData[index]["editble"] = false;
    this.setState({ formData });
  };

  // handleKycUpload = (e, id, data) => {
  //   e.preventDefault();
  //   const form = new FormData();
  //   form.append("frontpage", data.frontpage);
  //   form.append("backpage", data.backpage);
  //   form.append("number", data.number);
  //   form.append("document_type", data.document_type);
  //   form.append("frontpage_name", data.frontpage_name);
  //   form.append("backpage_name", data.backpage_name);
  //   form.append("document_name", data.document_name);
  //   form.append("address", id);
  //   add_CompanyaddreKyc(this.props.com_Id, id, form)
  //     .then((res) => res.data)
  //     .then((res) => {
  //       this.setState(
  //         {
  //           snackbarMessage: "Created Successfully",
  //           snackbarOpen: true,
  //           snackbarVariant: "success",
  //         },
  //         () => {
  //           this.getData();
  //         }
  //       );
  //     })
  //     .catch((err) => {
  //       this.setState({
  //         snackbarMessage: err.response.data.error.message,
  //         snackbarOpen: true,
  //         snackbarVariant: "error",
  //       });
  //     });
  // };
  // getKycData = (id) => {
  //   const { formData } = this.state;
  //   get_CompanyaddreKyc(this.props.com_Id, id)
  //     .then((res) => res.data)
  //     .then((res) => {
  //       this.state.formData.forEach((item) => {
  //         if (item.id === id) {
  //           item["addressKyc"] = res.address_KYC;
  //         }
  //       });
  //       this.setState(
  //         {
  //           formData,
  //         },
  //         () => {}
  //       );
  //     })
  //     .catch((err) => {
  //       this.setState({
  //         snackbarMessage: err.response.data.error.message,
  //         snackbarOpen: true,
  //         snackbarVariant: "error",
  //       });
  //     });
  // };
  // handleAddnewKyc = (e, id, id2) => {
  //   const { formData } = this.state;
  //   formData[id].address_kyc.push({ address: id2, addnew: true });
  //   this.setState({ formData });
  // };

  handleDeleteClose = () => {
    this.setState({
      opendelete: false,
    });
  };
  handleKycDelete = (proId, kycId) => {
    this.setState({
      opendelete: true,
      mainId: { proId: proId, kycId: kycId },
    });
  };
  // handleDelete = () =>
  //   delete_CompanyaddreKyc(
  //     this.props.com_Id,
  //     this.state.mainId.proId,
  //     this.state.mainId.kycId
  //   ).then(() => {
  //     this.getData();
  //   });
  loadLocation() {
    getLocationList()
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          country_list: res.countries,
          state_list: res.state,
        });
      })
      .catch((err) => {});
  }

  render() {
    const { formData, loader, editble, country_list, state_list } = this.state;
    // const { masterData, coun_state } = this.props;
    let addnew = "";
    if (formData && formData.length !== 0) {
      addnew = formData.length;
    }

    return (
      <div className="create-content">
        <CommonLoader open={loader} />
        <div className="content-body">
          <div className="card-content">
            {formData &&
              formData.map((item, index) => (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className="list-header">
                      <h4 className="titlestyle">
                        {`Address Information #${index + 1}`}
                      </h4>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div key={index}>
                      <form
                        className="edit-form"
                        autoComplete="off"
                        onSubmit={(e) =>
                          item.editble
                            ? this.handleUpdate(e, index)
                            : this.handleSubmit(e, index)
                        }
                      >
                        <div className="row" style={{ padding: "8px" }}>
                          {/* <div className="col-md-4 col-xs-12">
                            <label className="control-label">
                              Name <span className="red">*</span>
                            </label>
                            <input
                              type="text"
                              required
                              disabled={item.id && !item.editble}
                              name="name"
                              value={item.name}
                              class="form-control"
                              onChange={(e) => this.handleChange(e, index)}
                              placeholder=""
                            />
                          </div> */}

                          <div className="col-md-4 col-xs-12">
                            <label className="control-label">
                              Address Line 1 <span className="red">*</span>
                            </label>
                            <input
                              type="text"
                              required
                              disabled={item.id && !item.editble}
                              value={item.address_1}
                              name="address_1"
                              class="form-control"
                              onChange={(e) => this.handleChange(e, index)}
                              placeholder=""
                            />
                          </div>
                          <div className="col-md-4 col-xs-12">
                            <label className="control-label">
                              Address Line 2 <span className="red">*</span>
                            </label>
                            <input
                              type="text"
                              required
                              disabled={item.id && !item.editble}
                              name="address_2"
                              value={item.address_2}
                              class="form-control"
                              onChange={(e) => this.handleChange(e, index)}
                              placeholder=""
                            />
                          </div>

                          {/* <div className="col-md-12 col-xs-12">
                            <div className="row"> */}
                          <div className="col-md-4 col-xs-12">
                            <label className="control-label">
                              Country <span className="red">*</span>
                            </label>
                            <select
                              required
                              disabled={item.id && !item.editble}
                              className="form-control"
                              name="country"
                              value={item.country ? item.country : "India"}
                              onChange={(e) => this.handleChange(e, index)}
                              disabled="disabled"
                            >
                              {country_list &&
                                country_list.map(({ code, name }) => (
                                  <option key={code} value={name}>
                                    {name}
                                  </option>
                                ))}
                            </select>
                          </div>
                          <div className="col-md-4 col-xs-12">
                            <label className="control-label">
                              State <span className="red">*</span>
                            </label>
                            <select
                              required
                              disabled={item.id && !item.editble}
                              className="form-control"
                              name="state"
                              value={item.state ? item.state : ""}
                              onChange={(e) => this.handleChange(e, index)}
                            >
                              {state_list &&
                                state_list.map(({ code, name }) => (
                                  <option key={code} value={name}>
                                    {name}
                                  </option>
                                ))}
                            </select>
                          </div>

                          <div className="col-md-4 col-xs-12">
                            <label className="control-label">
                              City <span className="red">*</span>
                            </label>
                            <input
                              type="text"
                              required
                              value={item.city}
                              name="city"
                              disabled={item.id && !item.editble}
                              class="form-control"
                              onChange={(e) => this.handleChange(e, index)}
                              placeholder=""
                            />
                          </div>
                          <div className="col-md-4 col-xs-12">
                            <label className="control-label">
                              Pincode <span className="red">*</span>
                            </label>
                            <input
                              type="text"
                              disabled={item.id && !item.editble}
                              required
                              value={item.pin_code}
                              name="pin_code"
                              class="form-control"
                              onChange={(e) => this.handleChange(e, index)}
                              placeholder=""
                            />
                          </div>
                          {/* <div className="col-md-6 col-xs-12">
                                <label className="control-label">
                                  Phone Number <span className="red">*</span>
                                </label>
                                <input
                                  type="text"
                                  required
                                  disabled={item.id && !item.editble}
                                  name="phone_number"
                                  value={item.phone_number}
                                  class="form-control"
                                  onChange={(e) => this.handleChange(e, index)}
                                  placeholder=""
                                />
                              </div> */}
                          {/* </div>
                          </div> */}

                          <div
                            className="col-md-8 col-xs-12 text-right"
                            style={{ "margin-top": "3%" }}
                          >
                            {item.id && !item.editble && (
                              <button
                                className="btn btn-primary"
                                type="button"
                                onClick={(e) => this.handleEdit(index)}
                              >
                                EDIT
                              </button>
                            )}
                            {item.id && item.editble && (
                              <React.Fragment>
                                <button
                                  className="btn btn-secondary"
                                  type="button"
                                  onClick={(e) => this.handleDisableEdit(index)}
                                >
                                  CANCEL
                                </button>
                                <button
                                  className="btn btn-primary"
                                  type="submit"
                                  disabled={item.submited}
                                >
                                  UPDATE
                                </button>
                              </React.Fragment>
                            )}
                            {!item.id && (
                              <React.Fragment>
                                <button
                                  className="btn btn-secondary"
                                  type="button"
                                  onClick={(e) => this.handleCancel(index)}
                                >
                                  CANCEL
                                </button>
                                <button
                                  className="btn btn-primary"
                                  type="submit"
                                  disabled={item.submited}
                                >
                                  SAVE
                                </button>
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      </form>

                      {/* <div className="row m-top">
                        <div className="linedivider"></div>
                      </div> */}
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}

            <div className="row edit-form">
              <div className="col-md-12 col-xs-12 text-right">
                <button
                  href="#/"
                  className="btn btn-secondary"
                  onClick={(e) => this.props.handleTabView(2)}
                >
                  Back
                </button>
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={(e) => this.handleAddNew()}
                >
                  + &nbsp;&nbsp; ADD NEW ADDRESS
                </button>

                <button
                  href="#/"
                  className="btn btn-secondary"
                  onClick={(e) => this.props.handleTabView(4)}
                >
                  Next
                </button>
              </div>
            </div>
            {/* <div className="row m-top">
              <div className="linedivider"></div>
            </div> */}
          </div>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
        {/* <DeleteComponent
          opendelete={this.state.opendelete}
          handleDeleteClose={this.handleDeleteClose}
          delete={this.handleDelete}
        /> */}
      </div>
    );
  }
}

export default AddressInformation;
