import React, { Component } from "react";
import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import SelectBox from "../../../../../plugins/selectbox/commonSelectBox";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import {
  getbusinsector,
  getConstCat,
  get_Compnay_Basic,
  save_Compnay_Basic,
  updateCompnayBasic,
  updateCompnayBasicImage,
} from "../../../apifunctions";
// import {
//   createService_Benefits,
//   getService_Benefits,
//   updateService_Benefits,
// } from "../../../apifunctions";

class CompanyBasicInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      snackbarOpen: false,
      submited: false,

      snackbarVariant: "",
      images: {},
      loader: false,
      snackbarMessage: "",
      sectData: [],
      subSectData: [],
      indusData: [],
      constData: [],

      formData: {
        country: "India",
      },
      com_Id: props.com_Id,
    };
  }
  componentWillReceiveProps = () => {
    this.setState({
      com_Id: this.props.com_Id,
    });
  };
  componentDidMount = () => {
    if (this.props.com_Id) {
      this.getData();
    }
  };
  async getData() {
    this.setState({ loader: true });
    try {
      get_Compnay_Basic(this.props.com_Id)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            formData: {
              ...this.state.formData,
              id: res.id,
              constitution_category_name: res.constitution
                ? res.constitution.constitution_category_name
                : "",
              constitution: res.constitution ? res.constitution.id : "",
              state: res.address_info ? res.address_info.state : "",
              city: res.address_info ? res.address_info.city : "",
              business_name: res.business_name,
              business_sector_name: res.sector
                ? res.sector.business_sector_display_name
                : "",
              sector: res.sector ? res.sector.id : "",
              business_industry_name: res.industry
                ? res.industry.business_industry_name
                : "",
              industry: res.industry ? res.industry.id : "",
              sub_sector_name: res.sub_sector
                ? res.sub_sector.business_industry_name
                : "",
              sub_sector: res.sub_sector ? res.sub_sector.id : "",
              country: res.address_info ? res.address_info.country : "",
              profile_imageUrl: res.profile_image
                ? res.profile_image.profile_thumbnail
                : "",
            },
            indusData: res.sector ? res.sector.business_industries : [],
            subSectData: res.industry ? res.industry.children : [],

            loader: false,
          });
          var image = document.getElementById("output");
          image.src = this.state.formData.profile_imageUrl;
        })
        .catch((err) => {
          this.setState({
            datas: [],
            loader: false,
            snackbarMessage: err.response.data.error.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }
  handleChange = (e) => {
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: value,
      },
    });
  };
  // onSnackbarClose = () => {
  //   this.setState({ snackbarOpen: false });
  // };
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submited: true });
    if (this.state.formData.id) {
      updateCompnayBasic(
        this.state.formData.id,

        this.state.formData
      )
        .then((res) => {
          this.setState(
            {
              snackbarMessage: "Updated Successfully",
              snackbarOpen: true,
              submited: false,
              snackbarVariant: "success",
            },
            () => {}
          );
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.response.data.error.message,
            snackbarOpen: true,
            submited: false,
            snackbarVariant: "error",
          });
        });
    } else {
      save_Compnay_Basic(this.state.formData)
        .then((res) => res.data)
        .then((res) => {
          this.setState(
            {
              formData: { ...this.state.formData, id: res.id },
              snackbarMessage: "Created Successfully",
              snackbarOpen: true,
              submited: false,
              snackbarVariant: "success",
            },
            () => {
              setTimeout(() => {
                this.props.updateId(this.state.formData.id);
              }, 500);
            }
          );
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.response.data.error.message,
            snackbarOpen: true,
            submited: false,
            snackbarVariant: "error",
          });
        });
    }
  };
  handleImage = () => {
    const form = new FormData();
    form.append("profile_image", this.state.images.profile_image);
    form.append("image_name", this.state.images.fileName);
    updateCompnayBasicImage(this.props.com_Id, form)
      .then((res) => res.data)
      .then((res) => {
        this.setState(
          {
            images: {},
            snackbarMessage: "Created Successfully",
            snackbarOpen: true,
            snackbarVariant: "success",
          },
          () => {
            this.getData();
          }
        );
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err.response.data.error.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  handlebusSelectChange = (e, id, name) => {
    this.setState({
      indusData: e.business_industries ? e.business_industries : [],
      formData: {
        ...this.state.formData,
        [id]: e.id,
        [name]: e[name],
        industry: "",
        business_industry_name: "",
        sub_sector: "",
        sub_sector_name: "",
      },
    });
  };
  handleindusSelectChange = (e, id, name) => {
    this.setState({
      subSectData: e.children ? e.children : [],
      formData: {
        ...this.state.formData,
        [id]: e.id,
        [name]: e[name],
        sub_sector: "",
        sub_sector_name: "",
      },
    });
  };
  handlesubSelectChange = (e, id, name) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [id]: e.id,
        sub_sector_name: e.business_industry_name,
      },
    });
  };
  handleSelectChange = (e, id, name) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [id]: e.id,
        [name]: e[name],
      },
    });
  };
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  handleDataChange = (value) => {
    if (value.length >= 3) {
      this.setState({
        formData: { ...this.state.formData, constitution_category_name: value },
      });
      this.getConstData(value);
    }
  };
  getConstData = (value) => {
    getConstCat(value)
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          constData: res.constitution_categories,
        });
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err.response.data.error.message.error,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  handleBusDataChange = (value) => {
    if (value.length >= 3) {
      this.setState({
        formData: { ...this.state.formData, business_sector_name: value },
      });
      this.getBustData(value);
    }
  };
  getBustData = (value) => {
    getbusinsector(value)
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          sectData: res,
        });
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err.response.data.error.message.error,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  handleFileChange = (e) => {
    this.setState({
      images: {
        ...this.state.images,
        [e.target.name]: e.target.files[0],
        fileName: e.target.files[0].name,
      },
    });
    var image = document.getElementById("output");
    image.src = URL.createObjectURL(e.target.files[0]);
  };

  render() {
    const {
      formData,
      loader,
      sectData,
      subSectData,
      constData,
      submited,
      indusData,
    } = this.state;
    const { coun_state } = this.props;
    return (
      <div className="create-content">
        <CommonLoader open={loader} />
        <div className="content-body">
          <form
            className="edit-form"
            autoComplete="off"
            onSubmit={this.handleSubmit}
          >
            <div className="card-content">
              <div className="row">
                <div className="col-md-4 col-xs-12">
                  <label className="control-label">
                    Business Name <span className="red">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    name="business_name"
                    value={formData.business_name}
                    class="form-control"
                    onChange={this.handleChange}
                    placeholder=""
                  />
                </div>
                <div className="col-md-4 col-xs-12">
                  <label className="control-label">
                    Sector <span className="red">*</span>
                  </label>

                  <SelectBox
                    Data={sectData}
                    id="id"
                    async="true"
                    value={
                      formData.business_sector_name
                        ? formData.business_sector_name
                        : ""
                    }
                    desc="business_sector_name"
                    descId="sector"
                    getData={this.handleBusDataChange}
                    placeholder="Sector"
                    className="form-control"
                    onChange={this.handlebusSelectChange}
                  />
                </div>
                <div className="col-md-4 col-xs-12">
                  <label className="control-label">
                    Industry <span className="red">*</span>
                  </label>
                  <SelectBox
                    Data={indusData}
                    id="id"
                    value={formData.business_industry_name}
                    desc="business_industry_name"
                    disabled={!formData.sector}
                    // getData={this.handleBusIndDataChange}
                    placeholder="Industry"
                    className="form-control"
                    descId="industry"
                    onChange={this.handleindusSelectChange}
                  />
                </div>

                <div className="col-md-4 col-xs-12">
                  <label className="control-label">
                    Sub Sector <span className="red">*</span>
                  </label>
                  <SelectBox
                    Data={subSectData}
                    id="id"
                    value={formData.sub_sector_name}
                    desc="business_industry_name"
                    // getData={this.handleDataChange}
                    disabled={!formData.industry}
                    descId="sub_sector"
                    placeholder="Sub sector"
                    className="form-control"
                    onChange={this.handlesubSelectChange}
                  />
                </div>
                <div className="col-md-4 col-xs-12">
                  <label className="control-label">
                    Constitution Category <span className="red">*</span>
                  </label>
                  <SelectBox
                    Data={constData}
                    id="id"
                    async="true"
                    value={formData.constitution_category_name}
                    desc="constitution_category_name"
                    getData={this.handleDataChange}
                    placeholder="Category"
                    className="form-control"
                    descId="constitution"
                    onChange={this.handleSelectChange}
                  />
                </div>
                <div className="col-md-4 col-xs-12">
                  <label className="control-label">
                    State <span className="red">*</span>
                  </label>

                  <select
                    type="text"
                    required
                    value={formData.state}
                    name="state"
                    class="form-control"
                    onChange={this.handleChange}
                  >
                    <option value="">select</option>
                    {coun_state &&
                      coun_state.map((state) => (
                        <option value={state.name}>{state.name}</option>
                      ))}
                  </select>
                </div>
                <div className="col-md-4 col-xs-12">
                  <div className="row">
                    <div className="col-md-12 col-xs-12">
                      <label className="control-label">
                        Country <span className="red">*</span>
                      </label>
                      <select
                        required
                        className="form-control"
                        onChange={this.handleChange}
                        value={formData.country}
                      >
                        <option value={"India"}>India</option>
                      </select>
                    </div>
                    <div className="col-md-12 col-xs-12">
                      <label className="control-label">
                        City <span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        required
                        name="city"
                        value={formData.city}
                        class="form-control"
                        onChange={this.handleChange}
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-4 col-xs-12 upload-block upload-block01 ">
                  <label className="control-label">Business Logo </label>
                  <div className="upload-img">
                    <input
                      type="file"
                      className="file-upload"
                      id="img-upload"
                    />
                    <label for="img-upload">
                      <img src="/app-assets/backend-images/default.png" />
                      <div className="upload-icon">
                        <img src="/app-assets/backend-images/icons/camera.svg" />
                      </div>
                    </label>
                  </div>
                </div> */}
                <div className="col-md-4 col-xs-12">
                  <label className="control-label">Thumbnail Image </label>

                  <div className="upload-img">
                    <input
                      type="file"
                      name="profile_image"
                      onChange={this.handleFileChange}
                      className="file-upload"
                      id="img-upload"
                    />
                    <label for="img-upload">
                      <img alt="" id="output" />
                    </label>
                  </div>
                  <div className="upload-icon"></div>
                  {this.state.images.profile_image && (
                    <button
                      href="#/"
                      type="button"
                      onClick={(e) => this.handleImage()}
                    >
                      Upload
                    </button>
                  )}
                </div>
                <div className="col-md-12 col-xs-12 text-right">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={submited}
                  >
                    {this.state.formData.id ? "Update" : "Save"}
                  </button>
                  {this.state.formData.id && (
                    <a
                      href="#/"
                      className="btn btn-secondary"
                      onClick={(e) => this.props.handleTabView(2)}
                    >
                      Next
                    </a>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default CompanyBasicInformation;
