import React, { Component } from "react";
import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../../../plugins/dataTable/dataTable";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import { getCustomerKYC } from "../../../apifunctions";

class CustomerInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      snackbarOpen: false,
      submited: false,
      snackbarVariant: "",
      loader: false,
      customer_kyc_lists: [],
      snackbarMessage: "",
      // formData: {},
    };
  }

  componentDidMount = () => {
    this.loadKycData(this.props.custId);
  };
  async loadKycData(custId) {
    getCustomerKYC(custId)
      .then((res) => res.data)
      .then((res) => {
        console.log(res.kyc_attachments);
        this.setState({
          customer_kyc_lists: res.kyc_attachments,
          loader: false,
        });
      })
      .catch((err) => {
        this.setState({
          loader: false,
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  }
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  render() {
    const { loader } = this.state;
    const { custData } = this.props;
    let address = custData.default_contact
      ? custData.default_contact.user_address[0]
      : {};

    return (
      <div className="create-content">
        <CommonLoader open={loader} />
        <div className="content-body">
          <form className="edit-form" onSubmit={this.handleSubmit}>
            <div className="card-content">
              <div className="row">
                <div className="col-md-3 col-xs-12 customer-profile-list">
                  <ul>
                    <li>
                      <span className="sub">Customer Referral ID :</span>
                      <span className="text">
                        {custData.customer_referral_id}
                      </span>
                    </li>
                    <li>
                      <span className="sub">Customer No :</span>
                      <span className="text">{custData.customer_number}</span>
                    </li>
                    <li>
                      <span className="sub">Customer Tier :</span>
                      <span className="text">{custData.tier}</span>
                    </li>
                    <li>
                      <span className="sub">Preferred Language :</span>
                      <span className="text">
                        {custData.preferred_language &&
                          custData.preferred_language.language_name}
                      </span>
                    </li>
                    <li>
                      <span className="sub">Country :</span>
                      <span className="text">
                        {address ? address.country : ""}
                      </span>
                    </li>
                    <li>
                      <span className="sub">State :</span>
                      <span className="text">
                        {address ? address.state : ""}
                      </span>
                    </li>
                    <li>
                      <span className="sub">City :</span>
                      <span className="text">
                        {address ? address.city : ""}
                      </span>
                    </li>
                    <li>
                      <span className="sub">Address :</span>
                      <span className="text">
                        {address && address.address_1 + "," + address.address_2}
                      </span>
                    </li>
                    <li>
                      <span className="sub">Pincode :</span>
                      <span className="text">
                        {" "}
                        {address ? address.pin_code : ""}
                      </span>
                    </li>
                    <li>
                      <span className="sub">Specialization :</span>
                      <span className="text">NA</span>
                    </li>
                    <li>
                      <span className="sub">Education Qualification :</span>
                      <span className="text">NA </span>
                    </li>
                  </ul>
                </div>
                <div className="col-md-9 col-xs-12 customer-profile-table">
                  <h4 className="title">Attached KYC Documents</h4>
                  <div className="table-block">
                    <DataTable
                      data={this.state.customer_kyc_lists}
                      columns={[
                        {
                          title: "Document Type",
                          type: "text",
                          field: "document_type",
                          render: (rowData) =>
                            rowData.document_type && (
                              <span>{rowData.document_type.type_name}</span>
                            ),
                        },
                        {
                          title: "Document Number",
                          type: "text",
                          field: "number",
                        },
                        {
                          title: "Attachment 1",
                          type: "text",
                          field: "attachment1_name",
                        },
                        {
                          title: "Attachment 2",
                          type: "text",
                          field: "attachment2_name",
                        },
                        {
                          title: "Document Name",
                          type: "text",
                          field: "document_name",
                          render: (rowData) => (
                            <span>
                              {rowData.document_name === undefined
                                ? "NA"
                                : rowData.document_name}
                            </span>
                          ),
                        },
                        {
                          title: "Updated On",
                          type: "text",
                          field: "datetime_updated",
                        },
                      ]}
                      options={{
                        sort: true,
                        sortvalue: "number",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default CustomerInformation;
