import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./sub-menu.css";

class NavigationTab extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { handleTabView, tabData, currentTab } = this.props;

    return (
      <div className="card-header">
        <ul>
          {tabData.map((item) => (
            <li
              key={item.id}
              className={item.id === currentTab ? " active" : ""}
              onClick={(e) => handleTabView(item.id)}
            >
              <a>{item.name}</a>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default withRouter(NavigationTab);
