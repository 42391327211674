import { Grid } from "@material-ui/core";
import moment from "moment";
import React, { Component } from "react";
import CalendarComponent from "../../../plugins/calendar.comp";
import CommonLoaderComponent from "../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../plugins/dataTable/dataTable";
import SearchField from "../../../plugins/searchfield";
import { get_Compailnce_List } from "../apifunctions";
class ComplianceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      datas: [],
      options: {},
      mainId: "",
      opendelete: false,
      formSearch: {
        search: "",
        fromdate: "",
        todate: "",
        page: 1,

        status: "",
        filingCode: "",
        priority: "",
        updated: "",
      },
      loader: false,
      searchParam: "",

      pagesize: 0,
      masterData: this.props.masterData,
      totalCount: 0,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentWillReceiveProps = (props) => {
    this.setState({
      masterData: this.props.masterData,
    });
  };
  async componentDidMount() {
    try {
      await this.getData();
    } catch (err) {}
  }
  handleDeleteClose = () => {
    this.setState({
      opendelete: false,
    });
  };
  handleDeleteOpen = (e, reqData) => {
    this.setState({
      opendelete: true,
      mainId: reqData.id,
    });
  };

  handlepagination(page) {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: page,
        },
      },
      () => {
        this.getData();
      }
    );
  }
  getData(query) {
    this.setState({ loader: true });
    try {
      get_Compailnce_List(this.state.formSearch)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            datas: res.results,
            totalCount: res.count,
            pagesize: res.page_size,
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.response.data.error.message,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
        });
    } catch (err) {}
  }
  handleEdit = (e, row) => {
    this.props.history.push(`${this.props.match.path}-edit/` + row.id);
  };
  handleSearch = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: 1,
        },
      },
      () => {
        this.getData();
      }
    );
  };
  handleClose = () => {};
  handleChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        if (e.target.name !== "search") {
          this.handleSearch();
        }
      }
    );
  };
  handleDatePicker = (date) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          startDate: date[0],
          endDate: date[1],
          todate: date[1] ? moment(date[1]).format("DD-MM-YYYY") : "",
          fromdate: date[0] ? moment(date[0]).format("DD-MM-YYYY") : "",
        },
      },
      () => {
        if (this.state.formSearch.fromdate && this.state.formSearch.todate) {
          this.handleSearch();
        }
      }
    );
  };
  handleClear = (name) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [name]: "",
        },
      },
      () => {
        this.handleSearch();
      }
    );
  };
  render() {
    const { totalCount, formSearch, loader } = this.state;
    const { masterData } = this.props;
    return (
      <div className="card-content collapse show">
        <CommonLoaderComponent open={loader} />
        <div className="card-body card-dashboard">
          <Grid container spacing={1}>
            <Grid item xs={6} sm={2} className="table-search">
              {/* <div className="position-relative">
                <input
                  type="text"
                  name="search"
                  className="form-control"

                  placeholder="Search"
                  onChange={this.handleChange}
                />
                <button
                  className="btn-transparent"
                  onClick={(e) => this.handleSearch(e)}
                >
                  <img src="/app-assets/backend-images/icons/search-icon.svg" />
                </button>
              </div> */}
              <SearchField
                onChange={this.handleChange}
                value={formSearch.search}
                handleSearch={this.handleSearch}
                handleClear={this.handleClear}
              />
            </Grid>

            <Grid item xs={6} sm={2}>
              <select
                className="form-control"
                name="filingCode"
                onChange={this.handleChange}
                value={formSearch.filingCode ? formSearch.filingCode : ""}
              >
                <option value="">Type</option>
                {masterData.filing_types &&
                  masterData.filing_types.map((filt, ind) => (
                    <option key={filt.id} value={filt.code}>
                      {filt.filing_type}
                    </option>
                  ))}
              </select>
            </Grid>
            <Grid item xs={6} sm={2} className="">
              <CalendarComponent
                selectsRange={true}
                startDate={formSearch.startDate}
                endDate={formSearch.endDate}
                name="date"
                onChange={this.handleDatePicker}
              />
            </Grid>
            <Grid item xs={6} sm={2} className="">
              <select
                onChange={this.handleChange}
                value={formSearch.updated ? formSearch.updated : ""}
                name="updated"
                className="form-control"
              >
                <option value="">All</option>
                <option value={true}>Yes</option>
                <option value={false}> No</option>
              </select>
            </Grid>

            <Grid item xs={6} sm={2}>
              <select
                className="form-control"
                onChange={this.handleChange}
                value={formSearch.priority ? formSearch.priority : ""}
                name="priority"
              >
                <option value="">Priority</option>
                {masterData.filing_priority &&
                  masterData.filing_priority.map((filt, ind) => (
                    <option key={filt} value={filt}>
                      {filt}
                    </option>
                  ))}
              </select>
            </Grid>
            <Grid item xs={6} sm={2}>
              <select
                className="form-control"
                onChange={this.handleChange}
                value={formSearch.status ? formSearch.status : ""}
                name="status"
              >
                <option value="">Status</option>
                {masterData.statuses &&
                  masterData.statuses.map((filt, ind) => (
                    <option key={filt.id} value={filt.id}>
                      {filt.name}
                    </option>
                  ))}
              </select>
            </Grid>
            <Grid item xs={12} sm={12} className="table-block">
              <DataTable
                data={this.state.datas}
                columns={[
                  // { title: "Unique ID", field: "id" },
                  {
                    title: "Type",

                    render: (rowData) => (
                      <span>{rowData.filing.filing_type_name}</span>
                    ),
                    field: "filing_type",
                  },
                  {
                    title: "Name",

                    render: (rowData) => (
                      <span>{rowData.filing.filing_name}</span>
                    ),
                    field: "filing_name",
                  },

                  {
                    title: "Filing Date",

                    field: "compliance_date",
                    //////////////
                  },
                  {
                    title: "Date Changed",
                    field: "is_updated",
                    align: "center",
                    render: (rowData) => (
                      <span>{rowData.is_updated ? "Yes" : "No"}</span>
                    ),
                  },
                  {
                    title: "Priority",

                    render: (rowData) => <span>{rowData.filing.priority}</span>,
                    field: "priority",
                  },
                  {
                    title: "Status",
                    type: "status",
                    field: "status_name",

                    render: (rowData) => (
                      <span>{rowData.filing.status_name}</span>
                    ),
                  },
                ]}
                options={{
                  sort: true,
                  sortvalue: "filing_type",
                  pageNo: this.state.formSearch.page,

                  pagination: true,
                  totalCount: totalCount,
                  pagesize: this.state.pagesize,
                  handlepagination: this.handlepagination.bind(this),
                }}
                actions={[
                  {
                    className: "edit",
                    rowActionCLick: this.handleEdit.bind(this),
                    iconName: "/app-assets/backend-images/icons/edit-icon.svg",
                    tooltip: "Edit ",
                  },
                ]}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default ComplianceList;
