import apis from "../../apiactions/api.actions";

const support = "support/";
const support_note = "note";
const employee_listall = "employee/?view=all";

export const searchSupportData = (val) =>
  apis.getDatas(
    `${support}?search=${val.search}&status=${val.status}&priority=${
      val.priority
    }&client_type=${val.client_type}&agent_name=${val.agent_name}${
      val.page ? `&page=${val.page}` : ""
    }`
  );

export const getEmployeeList = () => apis.getDatas(employee_listall);

export const getSupportDetails = (id) => apis.getDatas(support + id);

export const addSupportNote = (id, data) =>
  apis.createData(support + id + "/" + support_note + "?", data);

export const updateEnquries = (id, data) => apis.updateData(support + id, data);
