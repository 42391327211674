import apis from "../../apiactions/api.actions";

export const govFileSearch = (val) =>
  apis.getDatas(
    `government-filings/filings/?search=${val.search}&filing_type__id=${
      val.filing_types
    }&status__id=${val.status}&priority=${val.priority}${
      val.page ? `&page=${val.page}` : ""
    }`
  );
export const deleteFiles = (id) =>
  apis.deleteData("government-filings/filings/" + id);
export const addGovFile = (data) =>
  apis.createData("government-filings/filings/", data);
export const updateGoveFileData = (id, data) =>
  apis.updateData("government-filings/filings/" + id + "/", data);
export const getGovFileData = (id) =>
  apis.getDatas("government-filings/filings/" + id + "/");
export const getEntities = (val) =>
  apis.getDatas(
    `entity/entity-types/?search=${val.search}&constitution_category__id=${
      val.constitution_category__id
    }${val.page ? `&page=${val.page}` : ""}`
  );
export const getEntitiesAll = (val) =>
  apis.getDatas(
    "entity/entity-types/?view=all&search=" +
      val.search +
      "&constitution_category__id=" +
      val.constitution_category__id
  );
export const deleteEntity = (id) =>
  apis.deleteData("entity/entity-types/" + id);
export const addEntity = (data) =>
  apis.createData("entity/entity-types/", data);
export const updateEntity = (id, data) =>
  apis.updateData("entity/entity-types/" + id + "/", data);
export const getEntity = (id) =>
  apis.getDatas("entity/entity-types/" + id + "/");
export const deleteParams = (id) =>
  apis.deleteData("entity/entity-parameter-variations/" + id + "/");
export const addParams = (data) =>
  apis.createData("entity/entity-parameter-variations/", data);

export const get_Complaince_mast = (val) =>
  apis.getDatas(
    `compliance/compliances?search=${val.search}&filing_type__id=${
      val.filing_types
    }&status__id=${val.status}&priority=${val.priority}&filing__filling_day=${
      val.filling_day
    }${val.page ? `&page=${val.page}` : ""}`
  );
/*export const get_Compailnce_List = (val) =>
  apis.getDatas(
    `compliance/compliance-calender?fromDate=${val.fromdate}&toDate=${val.todate}&search=${val.search}&filing__filing_type__code=${val.filingCode}&is_updated=${val.updated}&filing__priority=${val.priority}&filing__status__id=${val.status}`
  );*/
///////////////////////////////////////////////
export const get_Compailnce_List = (val) =>
  apis.getDatas(
    `compliance/all-compliances?fromDate=${val.fromdate}&toDate=${val.todate}&search=${val.search}&filing__filing_type__code=${val.filingCode}&is_updated=${val.updated}&filing__priority=${val.priority}&filing__status__id=${val.status}`
  );
////////////////////////////////////////////////////
export const getCompliance_listitem = (val) =>
  apis.getDatas(`compliance/compliance-calender/${val}`);
export const updateCompliance_listitem = (id, data) =>
  apis.updateData(`compliance/compliance-calender/${id}`, data);
export const govt_fillings = "government-filings/filings/";
export const entity_type = "entity/entity-types/";
export const compli_master = "compliance/compliances";
export const constitutioncat = "core/constitution-categories";
export const entity_Params = "entity/entity-parameter-variations/";
