import React, { Component } from "react";

class BusinessIndustriesCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: this.props.open,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentWillReceiveProps = () => {
    this.setState({
      open: this.props.open,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ open: false });
  };
  handleChange = (e) => {};
  handleFileChange = (e) => {
    var image = document.getElementById("output");
    image.src = URL.createObjectURL(e.target.files[0]);
  };

  render() {
    return (
      <div className="create-content">
        <div className="content-body">
          <form className="edit-form" onSubmit={this.handleSubmit}>
            <div className="card-top">
              <h4 className="card-title">Add New Business Industry</h4>
              <button className="btn btn-primary">Save</button>
            </div>
            <div className="card-content">
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <div className="row">
                    <div className="col-md-4 col-xs-12">
                      <label className="control-label">
                        Business Sector Name <span className="red">*</span>
                      </label>
                      <select className="form-control">
                        <option>Select</option>
                        <option>Select</option>
                        <option>Select</option>
                        <option>Select</option>
                      </select>
                    </div>
                    <div className="col-md-4 col-xs-12">
                      <label className="control-label">
                        Bussiness Industry Name <span className="red">*</span>
                      </label>
                      <input
                        onChange={this.handleChange}
                        required
                        type="text"
                        name="admin-name"
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                    <div className="col-md-4 col-xs-12">
                      <label className="control-label">
                        Display Name <span className="red">*</span>
                      </label>
                      <input
                        required
                        type="text"
                        name="admin-name"
                        class="form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-xs-12">
                  <div className="row">
                    <div className="col-md-8 col-xs-12">
                      <label className="control-label">Description</label>
                      <textarea
                        type="text"
                        name="admin-name"
                        class="form-control"
                        placeholder=""
                        rows="5"
                      ></textarea>
                    </div>
                    <div className="col-md-4 col-xs-12">
                      <label className="control-label">Thumbnail Image </label>
                      <div className="upload-img">
                        <input
                          type="file"
                          onChange={this.handleFileChange}
                          className="file-upload"
                          id="img-upload"
                        />
                        <label for="img-upload">
                          <img alt="" id="output" />
                          <div className="upload-icon">
                            <img src="/app-assets/backend-images/icons/camera.svg" />
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default BusinessIndustriesCreate;
