import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../../../plugins/dataTable/dataTable";
import DeleteComponent from "../../../../../plugins/delete/delete.component";
import SearchField from "../../../../../plugins/searchfield";
import SnackbarMessage from "../../../../../plugins/snackbar-component";

import {
  getCheckList,
  getPackageCheckLists,
  addToPackage,
  updatePackageCheckLists,
  deletePackageCheckLists,
} from "../../../apifunctions";

let addCheckListToPackage = [],
  updateObj = {},
  delData = {};

class PackageCheckList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbarMessage: "",
      formSearch: {
        page: 1,
        search: "",
      },
      deleteCheckList: [],
      datas: [],
      selectedData: [],
      loader: false,
      packageCheckLists: [],
      opendelete: false,
      packId: this.props.pack_Id,
    };
  }

  componentWillReceiveProps = () => {
    this.setState({ packId: this.props.pack_Id });
  };

  async componentDidMount() {
    try {
       await this.getData();
            
    } catch (err) {}
  }

  async getData() {
   
    try {
      getCheckList(this.state.formSearch)
        .then((res) => res.data)
        .then((res) => {
          const checklists = res.results;

          this.handlechecklist(checklists,this.props.pack_Id);
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.response.data.error.message.error,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
        });
    } catch (err) {}
  }

handlechecklist = (checklist,packId) => {

  getPackageCheckLists(this.state.formSearch,packId)
    .then((res) => res.data)
    .then((res) => {
      const packageCheckLists = res.package_checklists;

      if (packageCheckLists.length !== 0) {
        let filteredCheckLists = checklist.filter(
          (val) =>
            !packageCheckLists.some((el) => el.check_list_id.id === val.id)
        );

        this.setState({
          //deleteCheckList: [],
          datas: filteredCheckLists,
          packageCheckLists: packageCheckLists,
          loader: false,
        });
      } else {
        this.setState({
          datas: checklist,
          packageCheckLists: [],
          //deleteCheckList: [],
        
          loader: false,
        });
      }
    })
    .catch((err) => {
      this.setState({
        datas: checklist,
        deleteCheckList: [],
        packageCheckLists: [],
        snackbarMessage: err.response.data.error.message,
        snackbarOpen: true,
        snackbarVariant: "error",
        loader: false,
      });
    });
};

  handleAdd = (checkListData) => {
      this.setState({ selectedData: checkListData });
  };

  handleAddToPackage = (e) => {
   
    e.preventDefault();
    if (this.state.selectedData.length !== 0) {
      addCheckListToPackage = [];

      this.state.selectedData.forEach((item) => {
        addCheckListToPackage.push({
          sort_order: 2,
          customer_actioned: true,
          employee_actioned: false,
          payment_prompt: true,
          show_for_customer: true,
          check_list_id: item.id,
        });
      });

      addToPackage(addCheckListToPackage,this.props.pack_Id)
        .then((res) => {
          this.getData(this.state.packId);

          this.setState({
            snackbarMessage: "Created Successfully",
            snackbarOpen: true,
            selectedData: [],
            snackbarVariant: "success",
            loader: false,
            
          },
        );
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.response.data.error.message,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
        });
    }
  };

  handlePackageCheckBox = (e, row, index) => {
  
    let checked = e.target.checked;
    let name = e.target.name;
    this.state.packageCheckLists.forEach((item, ind) => {
      if (ind === index) {
       
        if(name === 'customer_actioned'){
            item[name] = checked;
            item['employee_actioned'] = !checked
        }
        else if(name === 'employee_actioned'){
         item[name] = checked;
         item['customer_actioned'] = !checked
        }
       
        item["edit"] = true;
        item[name] = checked;

      }
    });
    this.setState(
      {
        packageCheckLists: this.state.packageCheckLists,
      },
      () => {
        this.updatePackageCheckBox(row.id, this.state.packageCheckLists);
      }
    );
  };

  updatePackageCheckBox = (rowId, newData) => {
    newData.map((item, ind) => {
      if (item.edit) {
        delete item.edit;
        updateObj = {
          customer_actioned: item.customer_actioned,
          employee_actioned: item.employee_actioned,
          sort_order: item.sort_order,
          payment_prompt: item.payment_prompt,
          show_for_customer: item.show_for_customer,
          check_list_id: item.check_list_id.id,
        };
      }

    
    });

    updatePackageCheckLists(this.state.packId, rowId, updateObj)
      .then((res) => {
        this.setState({
          snackbarMessage: "Updated Successfully",
          snackbarOpen: true,
          snackbarVariant: "success",
        });
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err.response.data.error.message.error,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };

  handleDeleteRow = (data) => {
    this.setState({ deleteCheckList: data });
  };

  handleDeleteFromPackage = () => {
    if (this.state.deleteCheckList.length !== 0) {
      this.handleDeleteOpen();
    } else {
      this.setState({
        snackbarMessage: "Please select an item to delete ",
        snackbarOpen: true,
        snackbarVariant: "error",
      });
    }
  };

  handleDeleteOpen = () => {
    this.setState({
      opendelete: true,
      
    });
    this.state.deleteCheckList.forEach((obj, ind) => {
      delData["package_checklist_" + ind] = obj.id;
    });
  };

  handleDeleteClose = () => {
    delData = {}
    this.state.packageCheckLists.forEach((item, ind) => {
      if (item.checked) {
         item["checked"] = false;
      }
    });
    this.setState({
      opendelete: false,
      packageCheckLists: this.state.packageCheckLists,
      deleteCheckList: []
    });
  };

  handleDelete = () =>
    deletePackageCheckLists(this.state.packId, delData).then(() => {
      this.getData(this.state.packId);
    });

  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  handleChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        if (e.target.name !== "search") {
          this.handleSearch();
        }
      }
    );
  };

  handleClear = (name) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [name]: "",
        },
      },
      () => {
        this.handleSearch();
      }
    );
  };

  handleSearch = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: 1
          
        },
      },
      () => {
        this.getData(this.state.serId);
      }
    );
  };

  render() {

    
    const { loader, formSearch, deleteCheckList, selectedData } = this.state;
    return (
      <div className="card-content collapse show">
        <div className="edit-form card-body card-dashboard">
          <Grid container spacing={1}>
            <Grid item xs={6} sm={4}>
              <h4 className="card-title">CheckLists</h4>
              <SearchField
                onChange={this.handleChange}
                value={formSearch.search}
                handleSearch={this.handleSearch}
                handleClear={this.handleClear}
              />
              <DataTable
                data={this.state.datas}
                columns={[
                  {
                    title: "CheckList",
                    type: "text",
                    field: "name",
                  },
                ]}
                selectProps={this.handleAdd.bind(this)}
                options={{
                  //sort: true,
                  sortvalue: "",
                  selection: true,
                  //pageNo: formSearch.page,
                  //pagination: true,
                  //totalCount: totalCount,
                  //pagesize: pagesize,
                  //handlepagination: this.handlepagination.bind(this),
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={1} align="center" 
                    style={{position:'relative'}}>
                <Grid container>
                  <Grid item>
                    <button className="actionButton" type="button">
                      <AddIcon 
                         onClick={this.handleAddToPackage}           
                      />
                    </button>
                  </Grid>
                  <Grid item>
                    <button className="remove actionButton" type="button">
                      <RemoveIcon 
                          onClick={this.handleDeleteFromPackage}
                      />
                    </button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={7}>
                <h4 className="card-title">Added Package CheckLists</h4>
                <DataTable
                  data={this.state.packageCheckLists}
                  columns={[
                    {
                      title: "Name",
                      type: "text",
                      align:'center',
                      render: (row) => (
                        <span>{row.check_list_id.name}</span>
                        ),
                      },
                    {
                      title: "Show for Customer",
                      type: "text",
                      render: (row, index) => (
                        <span>
                          <Checkbox 
                                disableRipple="true"
                                checked={row.show_for_customer}
                                name="show_for_customer"
                                onClick={(e) =>
                                  this.handlePackageCheckBox(e, row, index)
                                }
                          />
                          </span>
                        ),
                      },
                      {
                        title: "Customer Action",
                        type: "text",
                        render: (row, index) => (
                          <span>
                            <Checkbox
                                checked={row.customer_actioned}
                                name="customer_actioned"
                                onClick={(e) =>
                                  this.handlePackageCheckBox(e, row, index)
                                }
                            />
                          </span>
                          ),
                      },
                      {
                        title: "Employee Action",
                        type: "text",
                        render: (row, index) => (
                          <span>
                            <Checkbox
                                checked={row.employee_actioned}
                                name="employee_actioned"
                                onClick={(e) =>
                                  this.handlePackageCheckBox(e, row, index)
                                }
                            />
                          </span>
                          ),
                        },
                      ]}
                      selectProps={this.handleDeleteRow.bind(this)}
                      options={{
                        sort: true,
                        sortvalue: "",
                        selection: true,
                        draggable: true
                        //pageNo: formSearch.page,
                        //pagination: true,
                        //totalCount: totalCount,
                        //pagesize: pagesize,
                        //handlepagination: this.handlepagination.bind(this),
                      }}
                    />
                  </Grid>
                </Grid>
           </div>
           <DeleteComponent
              opendelete={this.state.opendelete}
              handleDeleteClose={this.handleDeleteClose}
              delete={this.handleDelete}
            />
            <SnackbarMessage
              snackbarOpen={this.state.snackbarOpen}
              snackbarMessage={this.state.snackbarMessage}
              onSnackbarClose={this.onSnackbarClose}
              variant={this.state.snackbarVariant}
            />
          </div>
    );
  }
}
export default PackageCheckList;
