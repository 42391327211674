import { createMuiTheme } from "@material-ui/core/styles";

const SelectStyle = createMuiTheme({
  overrides: {
    MuiInputBase: {
      root: {
        // boxShadow: '1px 1px  #e1e3e3',

        "&:hover": {
          outline: " 1px !important",
        },
      },
      input: {
        color: "#9A9393",
        fontWeight: "400 !important",
        padding: "5px!important",

        height: "30px !important",
        // backgroundColor:"#F6F7FC ! important",
        fontSize: 12,
      },
    },

    MuiMenuPaper: {
      backgroundColor: "#000",
    },
    MuiInput: {
      underline: {
        "&:before": {
          "border-bottom": "0",
          display: "none !important",
        },
        "&:after": {
          "border-bottom": "0",
        },
        "&:focus": {
          "border-bottom": "0",
        },
      },
    },

    MuiFormControl: {
      root: {},
      marginNormal: {
        marginTop: 0,
        marginBottom: 0,
      },
      marginDense: {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    MuiAutocomplete: {
      paper: {
        fontSize: 12,
      },
      noOptions: {
        padding: "2px 10px !important",
      },
      option: {
        padding: "2px 10px !important",
      },
    },

    MuiFormLabel: {
      root: {
        // marginLeft:5,
        zIndex: 1,

        lineHeight: 1,
      },
    },

    MuiInputLabel: {
      shrink: {
        transform: "translate(0, 1.5px) scale(1)",
      },
      formControl: {
        transform: "none !important",
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: -4,
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: 13,
        height: 20,
      },
    },
  },
});
export default SelectStyle;
