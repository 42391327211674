import React, { useState } from 'react'
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core/styles";
import styles from "./file-upload.css";
import {
    createFolder
  } from "../apifunctions";


function Transition(props) {
    return <Slide direction="down" {...props} />;
}

const CreateFolder = (props) => {

    const [folderName, setFolderName] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [apiObj, setApiObj] = useState({})
    const [isSubmitted, setSubmitted] = useState(false)

    const onClickCreate = async () => {
        if (isSubmitted) return
        if (!folderName) { setApiObj({error: "Folder Name required."}); return; }
        setSubmitted(true)
        await createNewFolder()
        setSubmitted(false)
    }
    //API Call starts
    const createNewFolder = async () => {
        try {
            const req = { name: folderName, parent: props.folderId }
            const res = await createFolder(req)
            if (res.success) {
                onClickClose()
                props.showSnackbar({message: 'Folder Created Successfully!', type: 'success'})
            } else {
                setApiObj({error: 'Problem on creating folder!'})
            }
        } catch (err) {
            setApiObj({error: err.response?.data?.error?.message?.non_field_errors[0] || err.response?.data?.error?.message || 'Problem on creating folder!'})
            console.error('createNewFolder err >> ', err)
            // props.showSnackbar({message: err.response?.data?.error?.message?.non_field_errors[0] || err.response?.data?.error?.message || apiObj.error, type: 'error'})
        }
    }
    //API Call ends
    const onClickOpen = () => {
        if (props.isAllowed)
            setShowModal(true)
        else
            props.showSnackbar({message: 'You do not have permission to perform this action', type: 'error'})
    }
    const onClickClose = () => {
        props.reload()
        setApiObj({})
        setFolderName("")
        setSubmitted(false)
        setShowModal(false)
    }
    return (
        <>
        <a href="javascript:void(0)" className="btn btn-primary" onClick={() => onClickOpen()}>
        + &nbsp; New Folder
        </a>
        <Dialog
            open={showModal}
            TransitionComponent={Transition}
            onClose={() => onClickClose()}
            fullWidth="xs"
            maxWidth="xs"
            scroll="body"
            className="modal-mini"
            id="create_folder"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
        >
            <div className="modal-dialog-centered">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">CREATE FOLDER</h5>
                    <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => onClickClose()}
                    >
                    <img src="/app-assets/backend-images/icons/close-modal.svg" />
                    </button>
                </div>
                <div className="modal-body">
                    <form>
                    <fieldset>
                        <label className="control-label">Folder Name</label>
                        <input type="text" name="folder-name" className="form-control" value={folderName} 
                            onKeyPress={e => {if (e.key === 'Enter') {e.preventDefault(); onClickCreate()}}} 
                            onChange={(e) => {setApiObj({}); setFolderName(e.target.value)}}/>
                        <span style={{color: 'red'}}>{apiObj.error}</span>
                    </fieldset>
                    </form>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" disabled={isSubmitted} onClick={() => onClickCreate()}>CREATE</button>
                </div>
                </div>
            </div>
        </Dialog>  
        </>
    )
}

export default withStyles( styles )( CreateFolder );