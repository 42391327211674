import { Grid } from "@material-ui/core";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import SnackbarMessage from "../../../plugins/snackbar-component";
import {
  addSupportNote,
  getEmployeeList,
  getSupportDetails,
  updateEnquries,
} from "../apifunctions";

class SupportView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbarMessage: "",
      snackbarOpen: false,
      snackbarVariant: "",
      formData: {},
      currentDate: "",
      noteData: {
        agent_name: "",
        date_and_time: "",
        note: "",
        enquiry: "",
      },
      note_child_list: {
        idx: "",
        status: false,
      },
      noteChildData: {
        agent_name: "",
        date_and_time: "",
        note: "",
        enquiry: "",
        parent: "",
      },
      employee_list: [],
      enquiry_list: {
        status: "",
        agent_name: "",
        priority: "",
      },
    };
  }

  componentDidMount = () => {
    this.getData();
    this.loadCurrentDate();
    this.loadEmployeeList();
  };

  async getData() {
    this.setState({ loader: true });
    try {
      getSupportDetails(this.props.match.params.id)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            formData: res,
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.message,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
        });
    } catch (err) {}
  }
  loadCurrentDate = () => {
    var current_date, formated_month, formated_date, current_formated_date;
    current_date = new Date();
    formated_month =
      current_date.getMonth() + 1 < 10
        ? "0" + (current_date.getMonth() + 1)
        : current_date.getMonth() + 1;
    formated_date =
      current_date.getDate() < 10
        ? "0" + current_date.getDate()
        : current_date.getDate();
    current_formated_date =
      current_date.getFullYear() +
      "-" +
      formated_month +
      "-" +
      formated_date +
      " " +
      current_date.getHours() +
      ":" +
      current_date.getMinutes();
    this.setState({ currentDate: current_formated_date });
  };
  async loadEmployeeList() {
    try {
      getEmployeeList()
        .then((res) => {
          this.setState({
            employee_list: res,
          });
        })
        .catch((err) => {});
    } catch (err) {}
  }
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  loadNote = (e) => {
    e.preventDefault();
    if (!this.state.note) {
      this.setState({
        note: true,
        note_child_list: {
          ...this.state.note_child_list,
          status: false,
        },
      });
    }
  };
  loadNoteChild = (e, idx) => {
    e.preventDefault();
    if (!this.state.note_child) {
      this.setState({
        note: false,
        note_child_list: {
          idx: idx,
          status: true,
        },
      });
    }
  };
  handleChangeNotes = (e, parent_id = "") => {
    e.preventDefault();
    if (!this.state.formData.agent_name) {
      this.setState({
        snackbarMessage: "Please choose agent to add notes",
        snackbarOpen: true,
        snackbarVariant: "warning",
      });
      return false;
    }
    this.loadCurrentDate();
    if (parent_id) {
      this.setState({
        noteChildData: {
          ...this.state.noteChildData,
          agent_name: this.state.formData.agent_name.id,
          date_and_time: this.state.currentDate,
          [e.target.name]: e.target.value,
          enquiry: this.state.formData.id,
          parent: parent_id,
        },
      });
    } else {
      this.setState({
        noteData: {
          ...this.state.noteData,
          agent_name: this.state.formData.agent_name.id,
          date_and_time: this.state.currentDate,
          [e.target.name]: e.target.value,
          enquiry: this.state.formData.id,
        },
      });
    }
  };
  clearNote = () => {
    this.setState({
      noteData: {
        ...this.state.noteData,
        note: "",
      },
      noteChildData: {
        ...this.state.noteChildData,
        note: "",
      },
    });
  };
  addNote = (e) => {
    e.preventDefault();

    if (this.state.noteData.note) {
      addSupportNote(this.state.formData.id, this.state.noteData)
        .then((res) => {
          this.setState(
            {
              snackbarMessage: "Created Successfully",
              snackbarOpen: true,
              snackbarVariant: "success",
            },
            () => {
              setTimeout(() => {
                this.getData();
              }, 500);
            }
          );
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
      this.clearNote();
    } else {
      alert("Please enter a valid note and proceed.");
    }
  };
  addNoteChild = (e) => {
    e.preventDefault();

    if (this.state.noteChildData.note) {
      addSupportNote(this.state.formData.id, this.state.noteChildData)
        .then((res) => {
          this.setState(
            {
              snackbarMessage: "Created Successfully",
              snackbarOpen: true,
              snackbarVariant: "success",
            },
            () => {
              setTimeout(() => {
                this.getData();
              }, 500);
            }
          );
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
      this.clearNote();
    } else {
      alert("Please enter a valid comment and proceed.");
    }
  };

  handleChangeEnquiry = (e) => {
    this.setState({
      enquiry_list: {
        ...this.state.enquiry_list,
        [e.target.name]: e.target.value,
      },
    });

    setTimeout(() => {
      this.updateEnquries();
    }, 500);
  };
  updateEnquries = () => {
    updateEnquries(this.props.match.params.id, this.state.enquiry_list)
      .then((res) => {
        this.setState(
          {
            snackbarMessage: "Updated Successfully",
            snackbarOpen: true,
            snackbarVariant: "success",
          },
          () => {
            setTimeout(() => {
              this.getData();
            }, 500);
          }
        );
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };

  render() {
    const { masterData } = this.props;

    const {
      loader,
      formData,
      noteData,
      noteChildData,
      note_child_list,
      employee_list,
    } = this.state;

    return (
      <div className="card-content support-card collapse show">
        <CommonLoader open={loader} />
        <div className="card-body card-dashboard">
          <Grid container spacing={1} className="contact-details">
            <Grid item xs={6} sm={6} className="left-box">
              <p className="name">{formData.full_name}</p>
              <p className="text">Address</p>
              <p className="text">
                {formData.city}, {formData.state}
              </p>
              {formData.email && (
                <p className="text">
                  Email :{" "}
                  <a href={"mailto:" + formData.email}>{formData.email} </a>
                </p>
              )}
              {formData.phone_number && (
                <p className="text">Phone : {formData.phone_number}</p>
              )}
            </Grid>
            <Grid item xs={6} sm={6} className="right-box">
              {formData.ticket_id && (
                <p className="ticketid">Ticket ID : {formData.ticket_id}</p>
              )}
              <p className="date">Ticket Date : {formData.enquiry_date}</p>
              <p className="tags">
                <span className="enquiry">
                  {formData.enquiry_type ? formData.enquiry_type.name : ""}
                </span>
                <span className="unattended">{formData.status}</span>
              </p>
            </Grid>
          </Grid>
          <Grid container spacing={1} className="list-section">
            <Grid item xs={6} sm={7} className="left-box">
              <Grid container spacing={1}>
                <Grid item xs={6} sm={4} className="select-box">
                  <select
                    className="form-control"
                    name="status"
                    value={formData.status ? formData.status : ""}
                    onChange={(e) => this.handleChangeEnquiry(e)}
                  >
                    <option
                      value=""
                      disabled
                      {...(formData.status ? formData.status : "selected")}
                    >
                      Status
                    </option>
                    {masterData &&
                      masterData.enquiry_statuses &&
                      masterData.enquiry_statuses.map((status) => (
                        <option key={status} value={status}>
                          {status}
                        </option>
                      ))}
                  </select>
                </Grid>
                <Grid item xs={6} sm={4} className="select-box">
                  <select
                    className="form-control"
                    name="priority"
                    value={formData.priority ? formData.priority : ""}
                    onChange={(e) => this.handleChangeEnquiry(e)}
                  >
                    <option
                      value=""
                      disabled
                      {...(formData.enquiry_priorities
                        ? formData.enquiry_priorities
                        : "selected")}
                    >
                      Priority
                    </option>
                    {masterData &&
                      masterData.enquiry_priorities &&
                      masterData.enquiry_priorities.map((priority) => (
                        <option key={priority} value={priority}>
                          {priority}
                        </option>
                      ))}
                  </select>
                </Grid>
                <Grid item xs={6} sm={4} className="select-box">
                  <select
                    className="form-control"
                    name="agent_name"
                    value={formData.agent_name ? formData.agent_name.id : ""}
                    onChange={(e) => this.handleChangeEnquiry(e)}
                  >
                    <option
                      value=""
                      disabled
                      {...(formData.agent_name ? "" : "selected")}
                    >
                      Assigned
                    </option>
                    {employee_list &&
                      employee_list.map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                  </select>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={12} className="list-box">
                <ul className="content-list">
                  {formData.attributes &&
                    formData.attributes.map((attributes, idx) => (
                      <li>
                        <h4 className="main">{attributes.field}</h4>
                        <p className="text">{attributes.answer}</p>
                      </li>
                    ))}
                </ul>
                {formData.remarks && (
                  <div>
                    <h4 className="main"> Remarks </h4>
                    <p className="text">{formData.remarks}</p>
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid item xs={6} sm={5} className="right-box">
              <div className="card-header">
                <h4>Agent Notes</h4>
              </div>
              <div className="comment-list">
                <ul className="comments">
                  {formData.notes &&
                    formData.notes.map((notes, idx) => (
                      <li>
                        <p className="name">
                          {notes.agent_name.name} |{" "}
                          <span>{notes.date_and_time}</span>
                        </p>
                        <p className="text">{notes.note}</p>
                        <Link
                          className="link"
                          onClick={(e) => this.loadNoteChild(e, idx)}
                        >
                          Reply
                        </Link>
                        <ul className="comments">
                          {notes.children &&
                            notes.children.map((notes_child) => (
                              <li>
                                <p className="name">
                                  {notes_child.agent_name.name} |{" "}
                                  <span>{notes_child.date_and_time}</span>
                                </p>
                                <p className="text">{notes_child.note}</p>
                                {/* <Link
                                  className="link"
                                  onClick={(e) => this.loadNoteChild(e)}
                                >
                                  Reply
                                </Link> */}
                              </li>
                            ))}
                        </ul>
                        {note_child_list.status && idx == note_child_list.idx && (
                          <div className="add-comment">
                            <div className="position-relative">
                              <input
                                type="text"
                                name="note"
                                className="form-control"
                                placeholder="Share Comments"
                                value={noteChildData.note}
                                onChange={(e) =>
                                  this.handleChangeNotes(e, notes.id)
                                }
                              />
                              <button
                                className="btn btn-primary"
                                onClick={(e) => this.addNoteChild(e)}
                              >
                                <img src="/app-assets/backend-images/icons/comment.png" />
                              </button>
                            </div>
                          </div>
                        )}
                      </li>
                    ))}
                </ul>
                <div className="add-comment">
                  <div className="position-relative">
                    <input
                      type="text"
                      name="note"
                      className="form-control"
                      placeholder="Add Notes"
                      value={noteData.note}
                      onChange={(e) => this.handleChangeNotes(e)}
                    />
                    <button
                      className="btn btn-primary"
                      onClick={(e) => this.addNote(e)}
                    >
                      <img src="/app-assets/backend-images/icons/comment.png" />
                    </button>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default SupportView;
