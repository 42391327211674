import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import nsmConfig from "../utils/submenuConfig";
import "./sub-menu.css";

class NavigationSubMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    return (
      <div className="card-header">
        <ul>
          {nsmConfig[this.props.type || "Default"].menus.map((subMenu) => {
            return (
              <li
                key={subMenu.name}
                className={
                  this.props.location.pathname.startsWith(subMenu.path)
                    ? " active"
                    : ""
                }
              >
                <Link
                  to={
                    subMenu.path +
                    (this.props.params ? `/${this.props.params}` : "")
                  }
                >
                  {subMenu.name}{" "}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default withRouter(NavigationSubMenu);
