import CircularProgress from "@material-ui/core/CircularProgress";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import SelectStyle from "./selectoveride.syle";

class SelectBox extends React.Component {
  constructor(props) {
    super();
    this.state = {
      toggleHeader: "",
      value: props.value,
      loading: false,
      open: false,
    };
  }
  componentDidMount = () => {
    if (
      this.props.value !== "" &&
      this.props.value !== undefined &&
      this.props.value !== null
    )
      this.setState({ value: this.props.value });
  };

  componentWillReceiveProps = (props) => {
    this.setState({ value: props.value });
  };
  handlemenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleChange = (e, newValue) => {
    if (newValue) {
      this.props.onChange(newValue, this.props.descId, this.props.desc);
    } else {
      let data = { [this.props.id]: "", [this.props.desc]: "" };
      this.props.onChange(data, this.props.descId, this.props.desc);
    }
  };
  handleInputChange = (e, newValu, reason) => {
    if (reason === "input") {
      if (this.props.getData) {
        this.props.getData(newValu);
      }

      this.setState(
        {
          value: newValu,
        },
        () => {
          if (newValu === "" || newValu === undefined || newValu === null) {
            let data = {
              [this.props.id]: "",
              [this.props.desc]: "",
              Param1: false,
            };
            this.props.onChange(data, this.props.descId, this.props.desc);
          }
        }
      );
    }
  };
  handleOpen = () => {
    if (this.props.async) {
      this.setState(
        {
          loading: true,
          open: true,
        },
        () => {
          if (this.props.getData) {
            // this.props.getData();
            setTimeout(() => {
              this.setState({ loading: false });
            }, 2000);
          }
        }
      );
    } else {
      this.setState(
        {
          loading:
            this.props.Data.length === 0 && this.props.getData ? true : false,
          open: true,
        },
        () => {
          if (this.props.Data.length === 0 && this.props.getData) {
            this.props.getData(this.props.desc);
            setTimeout(() => {
              this.setState({ loading: false });
            }, 2000);
          }
        }
      );
    }
  };
  handleClose = () => {
    this.setState({
      open: false,
    });
  };
  render() {
    const {
      Data,
      label,
      margin,

      name,
      defaultValue,
      required,
      InputLabelProps,
      readOnly,
      error,
      disabled,
      id,
      classes,
      placeholder,
      desc,
    } = this.props;

    const { value, open, loading } = this.state;
    // loading = open && Data.length === 0 ? true : false;

    return (
      <div>
        <MuiThemeProvider theme={SelectStyle}>
          {readOnly ? (
            <TextField
              id="select-pagesize"
              type="text"
              label={label}
              fullWidth
              name={name}
              value={value ? value : ""}
              margin={margin}
              InputLabelProps={InputLabelProps}
              required={required}
              placeholder={placeholder}
              InputProps={{
                readOnly: true,
              }}
            />
          ) : (
            <Autocomplete
              options={Data}
              getOptionLabel={(option) => option[desc]}
              onChange={(e, newValue) => this.handleChange(e, newValue)}
              inputValue={value ? value : ""}
              open={open}
              onOpen={this.handleOpen}
              ListboxProps={{ style: { maxHeight: "15rem" } }}
              onClose={this.handleClose}
              defaultValue={defaultValue}
              name={desc}
              id={id}
              disabled={disabled}
              loading={loading}
              onInputChange={(event, newInputValue, reason) => {
                this.handleInputChange(event, newInputValue, reason);
              }}
              // disableClearable={readOnly}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={required}
                  value={value ? value : ""}
                  name={name}
                  variant="standard"
                  disabled={disabled}
                  label={label}
                  className="form-control"
                  defaultValue={defaultValue}
                  placeholder="Type to search"
                  margin="dense"
                  InputLabelProps={InputLabelProps}
                  error={error}
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    readOnly: readOnly,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="primary" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          )}
        </MuiThemeProvider>
      </div>
    );
  }
}

export default SelectBox;
