import apis from "../../apiactions/api.actions";

const folderlist = "bizpoledocs";

export const getFolderList = (val) => 
    apis.getDatas(
        folderlist + '/folders' +
      `?search=${val.search}${val.page ? `&page=${val.page}` : ""}${val.folder_id ? `&folder_id=${val.folder_id}` : ""}`
    );
export const uploadFile = (data) =>
    apis.createData(folderlist +'/file-upload', data);  
export const tagFile = (data) =>
    apis.updateData(folderlist +'/service-files', data); 
export const createFolder = (data) =>
    apis.createData(folderlist +'/folders', data);  
export const deleteFolder = (data) => 
    apis.deleteData(folderlist +`/folders/${data.id || ':id'}`, {});
export const employeeProfile = (val) => 
    apis.getDatas('employee/my-profile', val).then((res) => {
      localStorage.setItem("myProfile", JSON.stringify(res.data?.employee));
    });    
export const updateFolder = (data) => {
    const URL = folderlist +`/folders/${data.id || ':id'}`
    delete data.id
    return apis.updateData(URL, data)
}    
export const updateFile = (data) => {
    const URL = folderlist +`/files/${data.id || ':id'}`
    delete data.id
    return apis.updateData(URL, data)
}    
export const getCustomers = (val) => apis.getDatas('customer/'+`?view=all${val.search ? `&search=${val.search}` : ""}`)
export const getServices = (val) => apis.getDatas('service/'+`?view=all${val.search ? `&search=${val.search}` : ""}`)