import React, { Component } from "react";
import { Link } from "react-router-dom";
import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../../../plugins/dataTable/dataTable";
import DeleteComponent from "../../../../../plugins/delete/delete.component";
import SelectBox from "../../../../../plugins/selectbox/commonSelectBox";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import {
  delPackage_service,
  getServicelistAll,
  get_Package_service,
  update_Package_service,
} from "../../../apifunctions";

class ServiceInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [],
      users: [],
      filedatas: [],
      entdatas: [],
      snackbarOpen: false,
      submited: false,
      snackbarVariant: "",
      mainId: "",
      servData: [],
      loader: false,
      editflag: false,
      opendelete: false,
      hideAdd: false,
      snackbarMessage: "",
      formData: {
        package: this.props.pack_Id,
      },
      pack_Id: this.props.pack_Id,
    };
  }
  componentWillReceiveProps = () => {
    this.setState({
      pack_Id: this.props.pack_Id,
      formData: {
        package: this.props.pack_Id,
      },
    });
  };
  handleChange = (e) => {
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }

    this.setState({
      formData: {
        ...this.state.formData,
        details: value,
      },
    });
  };
  componentDidMount = () => {
    if (this.props.pack_Id) {
      this.getproData(this.props.pack_Id);
    }
  };
  async getproData(pack_Id) {
    this.setState({ loader: true });
    try {
      get_Package_service(pack_Id)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            datas: res.service_packages,
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            datas: [],
            loader: false,
            snackbarMessage: err.response.data.error.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submited: true });
    update_Package_service(this.state.formData, this.props.pack_Id)
      .then((res) => res.data)
      .then((res) => {
        this.setState(
          {
            formData: {},
            snackbarMessage: "Created Successfully",
            snackbarOpen: true,
            snackbarVariant: "success",
            submited: false,
          },
          () => {
            this.getproData(this.props.pack_Id);
          }
        );
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err.response.data.error.message,
          snackbarOpen: true,
          snackbarVariant: "error",
          submited: false,
        });
      });
  };
  handleDeleteClose = () => {
    this.setState({
      opendelete: false,
    });
  };
  handleDeleteOpen = (e, reqData) => {
    this.setState({
      opendelete: true,
      mainId: reqData.id,
    });
  };
  handleDelete = () =>
    delPackage_service(this.props.pack_Id, this.state.mainId).then(() => {
      this.getproData(this.props.pack_Id);
    });
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  getData(query) {
    getServicelistAll(query)
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          servData: res.services,
        });
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err.response.data.error.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  }
  handleDataChange = (value) => {
    if (value.length >= 3) {
      this.setState({
        formData: { ...this.state.formData, service_name: value },
      });
      this.getData(value);
    }
  };

  handleSelectChange = (e, event) => {
    this.setState({
      formData: {
        ...this.state.formData,
        service: e.id,
        service_name: e.service_name,
      },
    });
  };
  render() {
    const { formData, loader, servData, submited } = this.state;
    return (
      <div className="create-content">
        <CommonLoader open={loader} />
        <div className="content-body">
          <form className="edit-form" onSubmit={this.handleSubmit}>
            <div className="card-content margin-box">
              <div className="row">
                <div className="col-md-4 col-xs-12">
                  <div className="row">
                    <div className="col-md-12 col-xs-12 card-title">
                      <h4>Add Services</h4>
                    </div>
                    <div className="col-md-12 col-xs-12">
                      <label className="control-label">Service Name</label>

                      <SelectBox
                        Data={servData}
                        id="id"
                        required
                        async="true"
                        placeholder="Service Name"
                        value={formData.service_name}
                        desc="service_name"
                        getData={this.handleDataChange.bind(this)}
                        className="form-control"
                        onChange={this.handleSelectChange}
                      />
                    </div>
                    <div className="col-md-12 col-xs-12">
                      <label className="control-label">Description</label>
                      <textarea
                        type="text"
                        name="details"
                        required
                        class="form-control"
                        value={formData.details}
                        onChange={this.handleChange}
                        placeholder=""
                        rows="4"
                      ></textarea>
                    </div>
                    <div className="col-md-12 col-xs-12 text-left">
                      <button
                        className="btn btn-primary"
                        disabled={submited}
                        type="submit"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 col-xs-12">
                  <div className="row">
                    <div className="col-md-12 col-xs-12 card-title">
                      <h4>Updated Service List</h4>
                    </div>
                    <div className="col-md-12 col-xs-12">
                      <DataTable
                        data={this.state.datas}
                        columns={[
                          // {
                          //   title: "Sl",
                          //   type: "text",
                          //   field: "sl_no",
                          // },
                          {
                            title: "Service Name",
                            type: "text",
                            field: "service_name",
                            render: (rowData) =>
                              rowData.service && (
                                <span>{rowData.service.service_name}</span>
                              ),
                          },
                        ]}
                        options={{
                          sort: false,
                          sortvalue: "service_name",
                          //pageNo: this.state.formSearch.page,
                          pagination: false,
                          // totalCount: totalCount,
                          pagesize: this.state.pagesize,
                          // handlepagination: this.handlepagination.bind(this),
                        }}
                        actions={[
                          {
                            className: "delete",
                            rowActionCLick: this.handleDeleteOpen.bind(this),
                            iconName:
                              "/app-assets/backend-images/icons/delete-icon.svg",
                            tooltip: "Delete",
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-xs-12 text-right">
              <Link
                className="btn btn-secondary"
                onClick={(e) => this.props.handleTabView(0)}
              >
                Back
              </Link>
              {/* <button className="btn btn-primary" type="submit">
                save
              </button> */}
              <Link
                className="btn btn-secondary"
                onClick={(e) => this.props.handleTabView(2)}
              >
                Next
              </Link>
            </div>
          </form>
        </div>
        <DeleteComponent
          opendelete={this.state.opendelete}
          handleDeleteClose={this.handleDeleteClose}
          delete={this.handleDelete}
        />
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default ServiceInfo;
