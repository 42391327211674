import React, { Component } from "react";
import { Link } from "react-router-dom";
import apis from "../../apiactions/api.actions";
import SnackbarMessage from "../../plugins/snackbar-component";
import "./login-style.css";
class EnterOTP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOTPValid: false,
      validOTP: 1111,
      otpObj: {
        otp01: null,
        otp02: null,
        otp03: null,
        otp04: null,
      },
      snackbarOpen: false,

      snackbarMessage: "",
      snackbarVariant: "",
      loginParamsNew: {},
    };
  }
  componentDidMount = () => {
    if (
      localStorage.getItem("user_email") &&
      localStorage.getItem("user_email") !== null
    )
      localStorage.removeItem("code");
    else this.props.history.push("/");
  };
  onOTPSubmit = (event) => {
    let enteredOTP = Object.values(this.state.loginParams).join("");
    let data = {
      email: localStorage.getItem("user_email"),
      otp: enteredOTP,
    };

    apis
      .auth("employee/forgot-password-validate-otp/", data)
      .then((res) => {
        if (res.is_verified) {
          localStorage.setItem("code", res.code);
          this.setState(
            {
              snackbarMessage: "OTP verified successfully",
              snackbarOpen: true,
              snackbarVariant: "success",
            },
            () => {
              setTimeout(() => {
                this.props.history.push("/reset-password");
              }, 2500);
            }
          );
        } else {
          this.setState({
            snackbarMessage: "OTP Doesn't match",
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        }
      })
      .catch((err) => {
        localStorage.removeItem("code");
        this.setState({
          snackbarMessage: err.response.data.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });

    event.preventDefault();
  };
  handleFormChange = (e) => {
    this.setState({
      loginParams: {
        ...this.state.loginParams,
        [e.target.name]: e.target.value,
      },
    });
  };
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  render() {
    return (
      <div className="login-wrapper">
        <div className="left-block">
          <div className="img-block">
            <img src="/app-assets/backend-images/login/login-img.png" />
          </div>
        </div>
        <div className="right-block">
          <div className="card">
            <h4 className="form-title">Enter OTP</h4>
            <p className="form-text">
              We have sent an OTP to your registered email
            </p>
            <form className="login-form" onSubmit={this.onOTPSubmit}>
              <div className="form-group otp-block">
                <input
                  type="text"
                  name="otp01"
                  required
                  className={`form-control ${
                    this.state.isOTPValid ? "error" : ""
                  }`}
                  placeholder="0"
                  maxlength="1"
                  onChange={this.handleFormChange}
                />
                <input
                  type="text"
                  required
                  name="otp02"
                  className={`form-control ${
                    this.state.isOTPValid ? "error" : ""
                  }`}
                  placeholder="0"
                  maxlength="1"
                  onChange={this.handleFormChange}
                />
                <input
                  type="text"
                  name="otp03"
                  required
                  className={`form-control ${
                    this.state.isOTPValid ? "error" : ""
                  }`}
                  placeholder="0"
                  maxlength="1"
                  onChange={this.handleFormChange}
                />
                <input
                  type="text"
                  name="otp04"
                  required
                  className={`form-control ${
                    this.state.isOTPValid ? "error" : ""
                  }`}
                  placeholder="0"
                  maxlength="1"
                  onChange={this.handleFormChange}
                />
                {this.state.isOTPValid ? (
                  <label class="error">Incorrect OTP</label>
                ) : (
                  <></>
                )}
              </div>

              <div class="form-group">
                <button type="submit" className="btn btn-primary">
                  SUBMIT
                </button>
              </div>
              <div className="form-group text-center">
                <Link className="link" to="/forgot-password">
                  Resent OTP
                </Link>
              </div>
            </form>
          </div>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default EnterOTP;
