import { Grid } from "@material-ui/core";
import moment from "moment";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import CalendarComponent from "../../../plugins/calendar.comp";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../plugins/dataTable/dataTable";
import SearchField from "../../../plugins/searchfield";
import { getEmployeeList, searhCustomerData } from "../apifunctions";

class CustomerList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [],
      options: {},
      mainId: "",
      opendelete: false,
      formSearch: {
        search: "",
        status: "",
        customer_tier__tier: "",
        cre_agent__id: "",
        agent_name: "",
        fromDate: "",
        toDate: "",
        page: 1,
      },
      employee_list: [],
      loader: false,
      pagesize: 0,
      totalCount: 0,
    };
  }

  async componentDidMount() {
    try {
      await this.getData();

      this.loadEmployeeList();
    } catch (err) {}
  }

  handlepagination(id) {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: id,
        },
      },
      () => {
        this.getData();
      }
    );
  }
  async getData() {
    this.setState({ loader: true });
    try {
      searhCustomerData(this.state.formSearch)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            datas: res.results,
            totalCount: res.count,
            pagesize: res.page_size,
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.message,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
        });
    } catch (err) {}
  }
  handleView = (e, row) => {
    this.setState({ loader: true });
    this.props.history.push(`/customers-list-view/${row.id}`);
  };

  handleChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        if (e.target.name !== "search") {
          this.handleSearch();
        }
      }
    );
  };
  async loadEmployeeList() {
    try {
      getEmployeeList()
        .then((res) => {
          this.setState({
            employee_list: res,
          });
        })
        .catch((err) => {});
    } catch (err) {}
  }
  handleDatePicker = (date) => {
    //
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          startDate: date[0],
          endDate: date[1],
          fromDate: date[0] ? moment(date[0]).format("DD-MM-YYYY") : "",
          toDate: date[1] ? moment(date[1]).format("DD-MM-YYYY") : "",
        },
      },
      () => {
        if (this.state.formSearch.fromDate && this.state.formSearch.toDate) {
          this.handleSearch();
        }
      }
    );
  };
  handleClear = (name) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [name]: "",
        },
      },
      () => {
        this.handleSearch();
      }
    );
  };
  handleEdit = (e, row) => {
    this.props.history.push(`/customer-detailed/` + row.id);
  };
  handleSearch = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: 1,
        },
      },
      () => {
        this.getData();
      }
    );
  };
  render() {
    const { masterData } = this.props;
    const { loader, formSearch, employee_list, totalCount, pagesize } =
      this.state;

    return (
      <div className="card-content collapse show">
        <CommonLoader open={loader} />
        <div className="card-body card-dashboard">
          <Grid container spacing={1}>
            <Grid item xs={6} sm={2} className="table-search">
              <SearchField
                onChange={this.handleChange}
                value={formSearch.search}
                handleSearch={this.handleSearch}
                handleClear={this.handleClear}
              />
            </Grid>
            <Grid item xs={6} sm={2} className="table-search">
              <CalendarComponent
                selectsRange={true}
                startDate={formSearch.startDate}
                endDate={formSearch.endDate}
                name="date"
                onChange={this.handleDatePicker}
                placeholder="Usage Period"
              />
            </Grid>

            <Grid item xs={6} sm={2} className="table-search">
              <select
                className="form-control"
                name="cre_agent__id"
                onChange={this.handleChange}
                value={formSearch.cre_agent__id ? formSearch.cre_agent__id : ""}
              >
                <option value="" selected>
                  CRE/Agent
                </option>
                {employee_list &&
                  employee_list.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
              </select>
            </Grid>
            <Grid item xs={6} sm={2} className="table-search">
              <select
                className="form-control"
                name="customer_tier__tier"
                onChange={this.handleChange}
                value={
                  formSearch.customer_tier__tier
                    ? formSearch.customer_tier__tier
                    : ""
                }
              >
                <option value="" selected>
                  Tier
                </option>
                {masterData.tier &&
                  masterData.tier.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
              </select>
            </Grid>
            <Grid item xs={6} sm={2} className="table-search">
              <select
                className="form-control"
                name="status"
                onChange={this.handleChange}
                value={formSearch.status ? formSearch.status : ""}
              >
                <option value="" selected>
                  Status
                </option>
                {masterData &&
                  masterData.statuses &&
                  masterData.statuses.map((status) => (
                    <option key={status.id} value={status.id}>
                      {status.name}
                    </option>
                  ))}
              </select>
            </Grid>
            {/* add btn */}
            <Grid align="right" item xs={6} sm={2}>
              <Link to={"/customer-detailed/new"} className="btn btn-primary">
                + &nbsp; Add New
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} className="table-block">
              <DataTable
                data={this.state.datas}
                columns={[
                  // {
                  //   title: "SL",
                  //   type: "text",
                  //   field: "slNo",
                  // },
                  {
                    title: "Created Date/ Time",
                    type: "text",
                    field: "datetime_created",
                  },
                  {
                    title: "Customer Name",
                    type: "text",
                    field: "name",
                    // render: (rowData) =>
                    //   rowData.default_contact && (
                    //     <span>{rowData.default_contact.user.username}</span>
                    //   ),
                  },
                  {
                    title: "Mobile",
                    type: "text",
                    field: "mobile",
                    render: (rowData) =>
                      rowData.default_contact && (
                        <span>{rowData.default_contact.user.mobile}</span>
                      ),
                  },
                  {
                    title: "Primary Business",
                    field: "primaryBusiness",
                    align: "center",
                  },
                  // {
                  //   title: "City",
                  //   field: "city",
                  //   align: "city",
                  // },
                  {
                    title: "Tier",
                    field: "tier",
                  },
                  {
                    title: "CRE/ Agent",
                    field: "creAgent",
                    render: (rowData) => (
                      <span>
                        {rowData.cre_agent ? rowData.cre_agent.name : ""}
                      </span>
                    ),
                  },
                  {
                    title: "Status",
                    field: "status",
                    render: (rowData) =>
                      rowData.status.code && (
                        <span
                          span
                          style={{
                            backgroundColor:
                              rowData.status.code === "active"
                                ? "#78DEC0"
                                : "#DEDEDE",
                            padding: "0px 8px",
                          }}
                        >
                          {rowData.status.name}
                        </span>
                      ),
                    type: "status",
                  },
                ]}
                options={{
                  sort: true,
                  sortvalue: "datetime_created",
                  pageNo: this.state.formSearch.page,
                  pagination: true,
                  totalCount: totalCount,
                  pagesize: pagesize,
                  handlepagination: this.handlepagination.bind(this),
                }}
                actions={[
                  {
                    className: "view",
                    rowActionCLick: this.handleView.bind(this),
                    iconName: "/app-assets/backend-images/icons/view.png",
                    tooltip: "View Details",
                  },
                  {
                    className: "edit",
                    rowActionCLick: this.handleEdit.bind(this),
                    iconName: "/app-assets/backend-images/icons/edit-icon.svg",
                    tooltip: "Edit",
                  },
                  // {
                  //   className: "view",
                  //   rowActionCLick: this.handleView.bind(this),
                  //   iconName:
                  //     "/app-assets/backend-images/icons/delete-icon.svg",
                  //   tooltip: "Delete Customer",
                  // },
                ]}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default CustomerList;
