import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import styles from '../user-file-management/folder-list/file-upload.css'
import {
    changePassword
  } from "./apifunctions";
import SimpleReactValidator from 'simple-react-validator';
function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const initialState = {
    submitted: false,
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
}

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({messages: { in: 'Passwords need to match.'  }});
        this.state = initialState
    }

    handleClose = () => {
        this.setState(initialState)
        this.validator.hideMessages();
        this.props.closeModal();
    };

    onSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    onSubmitPassword = () => {
        if (this.validator.allValid()) {
            this.updatePassword()
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }

    updatePassword = () => {
        this.setState({submitted: true})
        const req = {
            old_password: this.state.currentPassword,
            password: this.state.newPassword,
            confirm_password: this.state.confirmPassword
        }
        changePassword(req)
            .then((res) => {
                this.setState({ submitted: false });
                this.handleClose()
                this.props.showSnackbar(res);
            })
            .catch((err) =>{
                this.setState({ submitted: false })
                this.handleClose()
                this.props.showSnackbar(err)
            });
    }

    render() {
        const { showModal } = this.props;
        const { submitted } = this.state;
        return (
            <div>
                <Dialog
                    open={showModal}
                    onClose={this.handleClose}
                    TransitionComponent={Transition}
                    fullWidth="xs"
                    maxWidth="xs"
                    className="modal-mini"
                    scroll="body"
                >
                <div className="modal-dialog-centered">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">CHANGE PASSWORD</h5>
                        <button
                        type="button"
                        className="btn-close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={this.handleClose}
                        >
                        <img src="/app-assets/backend-images/icons/close-modal.svg" />
                        </button>
                    </div>
                    <div className="modal-body">
                        <form>
                        <fieldset>
                            <label className="control-label">Current Password</label>
                            <input type="password" name="current_password" className="form-control" 
                                value={this.state.currentPassword}
                                onKeyPress={(e) => {if (e.key === 'Enter') this.onSubmitPassword()}}
                                onChange={(e) => this.setState({currentPassword: e.target.value})}/>
                            <span style={{color: 'red'}}>{this.validator.message('current_password', this.state.currentPassword, 'required')}</span>
                        </fieldset>
                        <fieldset>
                            <label className="control-label">New Password</label>
                            <input type="password" name="new_password" className="form-control"
                                value={this.state.newPassword}
                                onKeyPress={(e) => {if (e.key === 'Enter') this.onSubmitPassword()}}
                                onChange={(e) => this.setState({newPassword: e.target.value})}/>
                            <span style={{color: 'red'}}>{this.validator.message('new_password', this.state.newPassword, 'required')}</span>
                        </fieldset>
                        <fieldset>
                            <label className="control-label">Confirm Password</label>
                            <input type="password" name="confirm_password" className="form-control"
                                value={this.state.confirmPassword}
                                onKeyPress={(e) => {if (e.key === 'Enter') this.onSubmitPassword()}}
                                onChange={(e) => this.setState({confirmPassword: e.target.value})}/>
                            <span style={{color: 'red'}}>{this.validator.message('confirm_password', this.state.confirmPassword, `required|in:${this.state.newPassword}`)}</span>
                        </fieldset>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={this.onSubmitPassword} disabled={submitted}>SUBMIT</button>
                    </div>
                    </div>
                </div>
                </Dialog>
            </div>
        )
    }
}

export default withStyles( styles )( ChangePassword );