import { createMuiTheme } from "@material-ui/core/styles";

const CustomTableTheme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      head: {
        color: "black",
        // fontSize:12,
        height: 12,

        fontWeight: 500,
      },
      root: {
        padding: "0px 10px 0px 5px",

        fontSize: ".75rem",
        // marginRight:8,
        // "&:last-child": {
        //   padding: 0,
        //   margin:0
        // }
        "&:first-child": {
          paddingLeft: 10,
          padding: "0px 10px 0px 0px",
        },
      },

      sizeSmall: { padding: "0px 10px 0px 5px" },
    },

    MuiInput: {
      underline: {
        "&:before": {
          "border-bottom": "0",
          display: "none !important",
        },
        "&:after": {
          "border-bottom": "0",
        },
        "&:focus": {
          "border-bottom": "0",
        },
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: 8,
      },
    },

    MuiIconButton: {
      label: {
        // color: DynamicTheme.actionButton,
      },
      root: {
        padding: 2,
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiSvgIcon: {
      root: {
        // color: DynamicTheme.actionButton,
        fontSize: "1.1rem",
        // height:'0.8em',
        // width:'0.8em'
      },
    },

    MuiTableRow: {
      // Mui:{
      //   selected:{
      //     backgroundColor:DynamicTheme.tablerowSelected
      //   }
      // },

      head: {
        height: 25,
        whiteSpace: "nowrap",
        "&:hover": {
          backgroundColor: "transparent !important",
          boxShadow: "none !important",
        },
        //  backgroundColor:DynamicTheme.tableBackground,
      },
      root: {
        height: 30,
        "&$selected": {
          backgroundColor: "lightgrey",
        },
        "&:hover": {
          backgroundColor: "lightgrey",
          // boxShadow: DynamicTheme.commonBoxShadow,
        },
      },
      footer: {
        height: "40px ! important",
      },
    },

    MuiFormControlLabel: {
      root: {
        marginLeft: -4,
      },
    },

    MuiTableHead: {
      root: {
        backgroundColor: "#5d5a5a!important",
        color: "#fff!important",
        // backgroundColor:DynamicTheme.tableBackground
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: 13,
        height: 20,
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: "33px !important",
      },
      gutters: {
        paddingLeft: "15px ! important",
      },
      root: {
        paddingTop: 10,
      },
    },

    MuiExpansionPanelSummary: {
      root: {
        "&$expanded": {
          minHeight: 48,
        },
      },

      content: {
        "&$expanded": {
          margin: 0,
        },
      },
    },
  },
});
export default CustomTableTheme;
