import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { IconButton } from '@material-ui/core';
import { Close as IconClose } from '@material-ui/icons';
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";

// const variantIcon = {
//   success: CheckCircleIcon,
//   warning: WarningIcon,
//   error: ErrorIcon,
//   info: InfoIcon,
// };

const styles = (theme) => ({
  success: {
    backgroundColor: "green",
  },
  warning: {
    backgroundColor: "#ff9800",
  },
  error: {
    backgroundColor: "red",
  },

  icon: {
    fontSize: 20,
  },

  message: {
    display: "flex",
    alignItems: "center",
    color: "white",
  },
});

class SnackbarMessage extends React.Component {
  onSnackbarClose = () => {
    this.props.onSnackbarClose();
  };

  render() {
    const {
      snackbarMessage,
      snackbarOpen,
      classes,

      variant,
    } = this.props;

    /* eslint-disable react/no-danger */
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right", 
        }}
        style={{ zIndex: 5000 }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={this.onSnackbarClose}
      >
        <SnackbarContent
          className={classes[variant]}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={classes.message}>
              {snackbarMessage}
            </span>
          }
          action={
            <IconButton onClick={this.onSnackbarClose}>
              <IconClose style={{ fill: "white" }}/>
            </IconButton>
          }
        />
      </Snackbar>
    );
  }
}

SnackbarMessage.propTypes = {
  snackbarMessage: PropTypes.string,
  snackbarOpen: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(["success", "warning", "error", "info", ""])
    .isRequired,
};

export default withStyles(styles)(SnackbarMessage);
