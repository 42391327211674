import React, { Component } from "react";
import CommonLoader from "../../../../plugins/commonLoader/commonLoader.component";
import SnackbarMessage from "../../../../plugins/snackbar-component";
class ManageBusinessSector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sectData: [],
      openSector: false,
      formData: {
        ...props.sectorForm,
        status: "",
      },
      snackbarMessage: "",
      snackbarOpen: false,
      submited: false,
      addHeader: "ADD CATEGORY",
      snackbarVariant: "",
    };
  }
  componentWillReceiveProps = () => {
    this.setState({
      formData: { ...this.props.sectorForm, status: "" },
    });
  };
  componentDidMount = () => {};

  handleChange = (e) => {
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: value,
      },
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      submited: true,
      loader: true,
    });
    this.props
      .submit(this.state.formData)
      .then((res) => {
        if (this.state.formData.id) {
          this.props.handleRefresh(res, false);
        } else {
          this.props.handleRefresh(res, true);
        }
        this.setState({
          snackbarMessage: this.state.formData.id
            ? "Business sector updated successfully"
            : "Business sector created successfully",
          snackbarOpen: true,
          snackbarVariant: "success",
          submited: false,
          loader: false,
        });
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err.response.data.error.message,
          snackbarOpen: true,
          snackbarVariant: "error",
          loader: false,
        });
      });
  };
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  render() {
    const { loader, addHeader, sectData, formData, openSector, sectedit } =
      this.state;
    return (
      <div className="card-content">
        <CommonLoader open={loader} />
        <form className="edit-form" onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <label className="control-label">
                Business Sector Name <span className="red">*</span>
              </label>
              <input
                type="text"
                value={formData.business_sector_name}
                name="business_sector_name"
                onChange={this.handleChange}
                required
                class="form-control"
                placeholder=""
              />
            </div>
            <div className="col-md-6 col-xs-12">
              <label className="control-label">
                Business Sector Code <span className="red">*</span>
              </label>
              <input
                type="text"
                value={formData.business_sector_code}
                name="business_sector_code"
                required
                onChange={this.handleChange}
                class="form-control"
                placeholder=""
              />
            </div>

            <div className="col-md-6 col-xs-12">
              <label className="control-label">
                Display Name <span className="red">*</span>
              </label>
              <input
                type="text"
                value={formData.business_sector_display_name}
                name="business_sector_display_name"
                class="form-control"
                required
                onChange={this.handleChange}
                placeholder=""
              />
            </div>
            <div className="col-md-6 col-xs-12 textarea-block">
              <label className="control-label">Description</label>
              <textarea
                type="text"
                name="description"
                value={formData.description}
                class="form-control"
                onChange={this.handleChange}
                placeholder=""
                rows="5"
              ></textarea>
            </div>

            <div className="col-md-6 col-xs-12 upload-block">
              <label className="control-label">Thumbnail Image </label>
              <div className="upload-img">
                <input type="file" className="file-upload" id="img-upload" />
                <label for="img-upload">
                  <img src="/app-assets/backend-images/thumbnail-business-sector.png" />
                  <div className="upload-icon">
                    <img src="/app-assets/backend-images/icons/camera.svg" />
                  </div>
                </label>
              </div>
            </div>
            <div className="col-md-12 col-xs-12 text-right">
              <button
                disabled={this.state.submited}
                type="submit"
                className="btn btn-primary"
              >
                {this.props.sectedit ? "Update" : "Save"}
              </button>
            </div>
          </div>
        </form>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default ManageBusinessSector;
