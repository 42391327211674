import React, { Component } from "react";
import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import {
  get_Compnaycontact,
  update_Compnaycontact,
} from "../../../apifunctions";
// import {
//   createService_Benefits,
//   getService_Benefits,
//   updateService_Benefits,
// } from "../../../apifunctions";
let serId = "";
class CompanyContactInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      snackbarOpen: false,
      submited: false,
      snackbarVariant: "",
      loader: false,
      snackbarMessage: "",
      formData: {},
    };
  }
  componentDidMount = () => {
    if (this.props.com_Id) {
      this.getData();
    }
  };
  async getData() {
    this.setState({ loader: true });
    try {
      get_Compnaycontact(this.props.com_Id)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            formData: res.contact_info[0],
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            datas: [],
            loader: false,
            snackbarMessage: err.response.data.error.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }
  handleChange = (e) => {
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: value,
      },
    });
  };
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submited: true });
    // if (this.state.formData.id) {
    update_Compnaycontact(this.props.com_Id, this.state.formData)
      .then((res) => {
        this.setState(
          {
            snackbarMessage: "Updated Successfully",
            snackbarOpen: true,
            submited: false,
            snackbarVariant: "success",
          },
          () => {}
        );
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err.response.data.error.message,
          snackbarOpen: true,
          submited: false,
          snackbarVariant: "error",
        });
      });
    // } else {
    //   createService_Benefits(serId, this.state.formData)
    //     .then((res) => res.data)
    //     .then((res) => {
    //       this.setState(
    //         {
    //           formData: res.service_benefits,
    //           snackbarMessage: "Created Successfully",
    //           snackbarOpen: true,
    //           snackbarVariant: "success",
    //         },
    //         () => {}
    //       );
    //     })
    //     .catch((err) => {
    //       this.setState({
    //         snackbarMessage: err.response.data.error.message,
    //         snackbarOpen: true,
    //         snackbarVariant: "error",
    //       });
    //     });
    // }
  };
  render() {
    const { formData, loader, submited } = this.state;
    return (
      <div className="create-content">
        <CommonLoader open={loader} />
        <div className="content-body">
          <form className="edit-form" onSubmit={this.handleSubmit}>
            <div className="card-content">
              <div className="row">
                <div className="col-md-4 col-xs-12">
                  <label className="control-label">
                    Contact Number 01 <span className="red">*</span>
                  </label>
                  <input
                    type="tel"
                    pattern="/(+)\d{12}/"
                    required
                    value={formData.mobile}
                    name="mobile"
                    maxLength="13"
                    placeholder="+91 0000 000 000"
                    class="form-control"
                    onChange={this.handleChange}
                    minlength="13"
                    maxlength="13"
                  />
                </div>
                <div className="col-md-4 col-xs-12">
                  <label className="control-label">
                    Email ID 01 <span className="red">*</span>
                  </label>
                  <input
                    type="email"
                    required
                    name="email"
                    value={formData.email}
                    class="form-control"
                    onChange={this.handleChange}
                    placeholder=""
                  />
                </div>
                <div className="col-md-4 col-xs-12">
                  <label className="control-label">Website</label>
                  <input
                    type="url"
                    name="website"
                    value={formData.website}
                    class="form-control"
                    onChange={this.handleChange}
                    placeholder="https://www.google.com"
                  />
                </div>
                <div className="col-md-4 col-xs-12">
                  <label className="control-label">Contact Number 02</label>
                  <input
                    name="mobile_2"
                    value={formData.mobile_2}
                    class="form-control"
                    onChange={this.handleChange}
                    placeholder=""
                    minlength="13"
                    type="tel"
                    placeholder="+91 0000 000 000"
                    pattern="/(+)\d{12}/"
                    maxlength="13"
                  />
                </div>
                <div className="col-md-4 col-xs-12">
                  <label className="control-label">Email ID 02</label>
                  <input
                    type="email"
                    value={formData.email_2}
                    name="email_2"
                    class="form-control"
                    onChange={this.handleChange}
                    placeholder=""
                  />
                </div>

                <div className="col-md-12 col-xs-12 text-right">
                  <a
                    href="#/"
                    className="btn btn-secondary"
                    onClick={(e) => this.props.handleTabView(2)}
                  >
                    Back
                  </a>
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={submited}
                    onSubmit={this.handleSubmit}
                  >
                    {this.state.formData.id ? "Update" : "Save"}
                  </button>
                  <a
                    href="#/"
                    className="btn btn-secondary"
                    onClick={(e) => this.props.handleTabView(4)}
                  >
                    Next
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default CompanyContactInformation;
