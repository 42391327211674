import React, { Component } from "react";
import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../../../plugins/dataTable/dataTable";
import DeleteComponent from "../../../../../plugins/delete/delete.component";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import {
  delService_image,
  get_Service_image,
  update_Service_image,
} from "../../../apifunctions";

class Media extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [],
      users: [],
      filedatas: [],
      entdatas: [],
      snackbarOpen: false,
      submited: false,
      snackbarVariant: "",
      mainId: "",
      loader: false,
      editflag: false,
      opendelete: false,
      hideAdd: false,
      snackbarMessage: "",
      formData: {},
      serId: this.props.ser_Id,
    };

    this.handleFile = this.handleFile.bind(this);
  }
  componentWillReceiveProps = () => {
    this.setState({ serId: this.props.ser_Id });
  };
  handleChange = (e) => {
    let value = e.target.value;
    if (e.target.value.trim() === "") {
      value = "";
    }

    this.setState({
      formData: {
        ...this.state.formData,
        image_name: value,
      },
    });
  };
  handleFile = (e) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [e.target.name]: e.target.files[0],
        fileName: e.target.files[0].name,
      },
    });
  };
  componentDidMount = () => {
    if (this.props.ser_Id) {
      this.getproData(this.props.ser_Id);
    }
  };
  async getproData(serId) {
    this.setState({ loader: true });
    try {
      get_Service_image(serId)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            datas: res.service_images,
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            datas: [],
            snackbarMessage: err.response.data.error.message,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
        });
    } catch (err) {}
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submited: true });
    const form = new FormData();
    form.append("service_image", this.state.formData.service_image);
    form.append("image_name", this.state.formData.image_name);
    update_Service_image(form, this.props.ser_Id)
      .then((res) => res.data)
      .then((res) => {
        this.setState(
          {
            formData: {},
            snackbarMessage: "Created Successfully",
            snackbarOpen: true,
            snackbarVariant: "success",
            submited: false,
          },
          () => {
            this.getproData(this.props.ser_Id);
          }
        );
      })
      .catch((err) => {
        this.setState({
          submited: false,
          snackbarMessage: err.response.data.error.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  handleDeleteClose = () => {
    this.setState({
      opendelete: false,
    });
  };
  handleDeleteOpen = (e, reqData) => {
    this.setState({
      opendelete: true,
      mainId: reqData.id,
    });
  };
  handleDelete = () =>
    delService_image(this.props.ser_Id, this.state.mainId).then(() => {
      this.getproData(this.props.ser_Id);
    });
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  render() {
    const { formData, loader, submited } = this.state;
    return (
      <div className="create-content">
        <CommonLoader open={loader} />
        <div className="content-body">
          <form className="edit-form" onSubmit={this.handleSubmit}>
            <div className="card-content margin-box">
              <div className="row">
                <div className="col-md-4 col-xs-12">
                  <div className="row">
                    <div className="col-md-12 col-xs-12 card-title">
                      <h4>Add Media Image</h4>
                    </div>
                    {/* <div className="col-md-12 col-xs-12">
                      <label className="control-label">Image Name</label>
                      <input type="text" className="form-control" name="name" />
                    </div> */}
                    <div className="col-md-12 col-xs-12">
                      <label className="control-label">Image Name</label>
                      <input
                        type="text"
                        name="image_name"
                        value={formData.image_name ? formData.image_name : ""}
                        onChange={this.handleChange}
                        required
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-12 col-xs-12">
                      <label className="control-label">Choose Image</label>
                      <div className="position-relative upload-field">
                        <label for="upload-img">
                          <input
                            id="upload-img"
                            required
                            type="file"
                            onChange={this.handleFile}
                            // value={formData.upload}
                            accept="image/png, image/jpeg"
                            className="form-control"
                            name="service_image"
                          />
                          {formData.fileName}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-12 col-xs-12 text-left">
                      <button
                        className="btn btn-primary"
                        disabled={submited}
                        type="submit"
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 col-xs-12">
                  <div className="row">
                    <div className="col-md-12 col-xs-12 card-title">
                      <h4>Uploaded Media Images</h4>
                    </div>
                    <div className="col-md-12 col-xs-12">
                      <DataTable
                        data={this.state.datas}
                        columns={[
                          // {
                          //   title: "Sl",
                          //   type: "text",
                          //   field: "sl_no",
                          // },
                          {
                            title: "Image Name",
                            type: "text",
                            field: "image_name",
                          },
                          {
                            title: "Images",
                            type: "text",
                            field: "images",
                            align: "center",
                            render: (rowData) => (
                              <span>
                                <img
                                  style={{ width: 60, height: 60 }}
                                  alt=""
                                  src={rowData.service_image}
                                />
                              </span>
                            ),
                          },
                        ]}
                        options={{
                          sort: true,
                          sortvalue: "step",
                          // pageNo: this.state.formSearch.page,
                          // pagination: true,
                          // totalCount: totalCount,
                          pagesize: this.state.pagesize,
                          // handlepagination: this.handlepagination.bind(this),
                        }}
                        actions={[
                          {
                            className: "delete",
                            rowActionCLick: this.handleDeleteOpen.bind(this),
                            iconName:
                              "/app-assets/backend-images/icons/delete-icon.svg",
                            tooltip: "Delete",
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-xs-12 text-right">
              <a
                className="btn btn-secondary"
                onClick={(e) => this.props.handleTabView(10)}
              >
                Back
              </a>
              {/* <button className="btn btn-primary" type="submit">
                save
              </button> */}
              <a
                className="btn btn-secondary"
                onClick={(e) => this.props.handleTabView(12)}
              >
                Next
              </a>
            </div>
          </form>
        </div>
        <DeleteComponent
          opendelete={this.state.opendelete}
          handleDeleteClose={this.handleDeleteClose}
          delete={this.handleDelete}
        />
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default Media;
