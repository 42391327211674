import { Grid } from "@material-ui/core";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import user from "../../../apiactions/api.actions";
import DataTable from "../../../plugins/dataTable";

class Leads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      tableConfig: {
        action: {
          isEdit: true,
          isDelete: true,
          editPath: `${this.props.match.path}-edit`,
        },
        columns: [
          {
            title: "sort",
            type: Boolean,
            value: "isSelected",
            hStyle: { class: "cursor-pointer text-center" },
          },
          { title: "SL", type: String, value: "sl" },
          { title: "Create Date & Time", type: String, value: "dateTime" },
          {
            title: "Lead ID",
            type: String,
            value: "leadID",
          },
          {
            title: "Lead Name",
            type: String,
            value: "leadName",
          },
          {
            title: "Lead Mobile",
            type: String,
            value: "leadMobile",
          },
          {
            title: "Lead Source",
            type: String,
            value: "leadSource",
          },
          {
            title: "Agent ",
            type: String,
            value: "Agent ",
          },
          {
            title: "Lead Status",
            type: String,
            value: "status",
            hStyle: { class: "text-center" },
            dStyle: { class: "text-center", activeStyle: true },
          },
          {
            title: "Last Action Date ",
            type: String,
            value: "actionDate ",
          },
          {
            title: "action",
            type: "Action",
            value: "",
            hStyle: { class: "text-center" },
            dStyle: { class: "text-center" },
          },
        ],
      },
    };
  }

  async componentDidMount() {
    try {
      await this.getUser();
    } catch (err) {}
  }

  async deleteUser(reqData) {
    try {
      const users = await user.deleteUser("", reqData);

      this.setState({ users: users });
    } catch (err) {}
  }

  async getUser() {
    try {
      const users = await user.getUsers("/users");

      this.setState({ users: users });
    } catch (err) {}
  }

  render() {
    return (
      <div className="card-content collapse show">
        <div className="card-body card-dashboard">
        <Grid container spacing={1}>
          <Grid item xs={6} sm={3} className="table-search">
            <div className="position-relative">
              <input
                type="text"
                name="search"
                className="form-control"
                placeholder="Search"
              />
              <button
                className="btn-transparent"
              >
                <img src="/app-assets/backend-images/icons/search-icon.svg" />
              </button>
            </div>
          </Grid>
          
          <Grid align="right" item xs={6} sm={9}>
            <Link
              to={`${this.props.match.path}-create`}
              className="btn btn-primary"
            >
              + &nbsp; Add New
            </Link>
          </Grid>
          <Grid item xs={6} sm={2} className="select-box">
            <select className="form-control">
              <option>Source</option>
              <option>Category</option>
              <option>Category</option>
              <option>Category</option>
            </select>
          </Grid>
          <Grid item xs={6} sm={2} className="select-box">
            <select className="form-control">
              <option>State</option>
              <option>Category</option>
              <option>Category</option>
              <option>Category</option>
            </select>
          </Grid>
          <Grid item xs={6} sm={2} className="select-box">
            <select className="form-control">
              <option>City</option>
              <option>Category</option>
              <option>Category</option>
              <option>Category</option>
            </select>
          </Grid>
          <Grid item xs={6} sm={2} className="select-box">
            <select className="form-control">
              <option>Agent</option>
              <option>Category</option>
              <option>Category</option>
              <option>Category</option>
            </select>
          </Grid>
          <Grid item xs={6} sm={2} className="select-box">
            <select className="form-control">
              <option>Priority</option>
              <option>Category</option>
              <option>Category</option>
              <option>Category</option>
            </select>
          </Grid>
          <Grid item xs={6} sm={2} className="select-box">
            <select className="form-control">
              <option>Status</option>
              <option>Category</option>
              <option>Category</option>
              <option>Category</option>
            </select>
          </Grid>
          <Grid item xs={6} sm={2} className="select-box">
            <input
                type="text"
                name="createDate"
                className="form-control"
                placeholder="Create Date"
              />
          </Grid>
          <Grid item xs={6} sm={2} className="select-box">
            <input
                type="text"
                name="lastActionDate"
                className="form-control"
                placeholder="Last Action Date"
              />
          </Grid>
          <Grid item xs={6} sm={2} className="select-box">
            <input
                type="text"
                name="followupDate"
                className="form-control"
                placeholder="Next Follow up Date"
              />
          </Grid>

          <Grid item xs={12} sm={12} className="table-block">
            <DataTable
              dataList={this.state.users}
              deleteData={(user) => this.deleteUser(user)}
              tableConfig={this.state.tableConfig}
            />
          </Grid>
        </Grid>
        </div>
      </div>
    );
  }
}

export default Leads;
