import React, { Component } from "react";
import { Link } from "react-router-dom";
import user from "../../../apiactions/api.actions";
import DataTable from "../../../plugins/dataTable";

class BusinessIndustries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      tableConfig: {
        action: {
          isEdit: true,
          isDelete: true,
          editPath: `${this.props.match.path}-edit`,
        },
        columns: [
          {
            title: "sort",
            type: Boolean,
            value: "isSelected",
            hStyle: { class: "cursor-pointer text-center" },
          },
          { title: "SL", type: String, value: "sl" },
          { title: "Unique ID", type: String, value: "id" },
          {
            title: "Business Industries",
            type: String,
            value: "businessIndustries",
          },
          {
            title: "Status",
            type: String,
            value: "status",
            hStyle: { class: "text-center" },
            dStyle: { class: "text-center", activeStyle: true },
          },
          {
            title: "action",
            type: "Action",
            value: "",
            hStyle: { class: "text-center" },
            dStyle: { class: "text-center" },
          },
        ],
      },
    };
  }

  async componentDidMount() {
    try {
      await this.getUser();
    } catch (err) {}
  }

  async deleteUser(reqData) {
    try {
      const users = await user.deleteUser("", reqData);

      this.setState({ users: users });
    } catch (err) {}
  }

  async getUser() {
    try {
      const users = await user.getUsers("/users");

      this.setState({ users: users });
    } catch (err) {}
  }

  render() {
    return (
      <div className="card-content collapse show">
        <div className="card-body card-dashboard">
          <div className="right-button space-between">
            <div className="select-block">
              <select className="form-control">
                <option>Status</option>
                <option>Status</option>
                <option>Status</option>
                <option>Status</option>
                <option>Status</option>
                <option>Status</option>
                <option>Status</option>
                <option>Status</option>
                <option>Status</option>
              </select>
              <select className="form-control">
                <option>Status </option>
                <option>Status </option>
                <option>Status </option>
                <option>Status </option>
                <option>Status </option>
                <option>Status </option>
                <option>Status </option>
              </select>
            </div>
            <Link
              to={`${this.props.match.path}-create`}
              className="btn btn-primary"
            >
              + &nbsp; Add New
            </Link>
          </div>

          <DataTable
            dataList={this.state.users}
            deleteData={(user) => this.deleteUser(user)}
            tableConfig={this.state.tableConfig}
          />
        </div>
      </div>
    );
  }
}

export default BusinessIndustries;
