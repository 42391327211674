import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import routes from "../config/routes";
function NoLayout({ masterData, login, logout }) {
  const history = useHistory();
  useEffect(() => {
    window.addEventListener("popstate", (e) => {
      history.go(0);
    });
  }, []);

  return (
    <div>
      <Switch>
        {routes.map((route, index) => {
          if (!route.restricted) {
            return (
              <Route
                key={index}
                exact={route.exact}
                path={route.path}
                render={(props) =>
                  route.component ? (
                    <route.component
                      key={index}
                      {...props}
                      routes={route.routes}
                      type={route.type}
                      login={login}
                    />
                  ) : (
                    <>
                      <Redirect
                        to={{ pathname: "/", state: { from: props.location } }}
                      />
                    </>
                  )
                }
              />
            );
          }
        })}
      </Switch>
    </div>
  );
}

export default NoLayout;
