import React, { Component } from "react";
import DeleteComponent from "../../../../../plugins/delete/delete.component";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
let serId = "";

class FixedPricing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [],
      users: [],
      filedatas: [],
      entdatas: [],
      snackbarOpen: false,
      submited: false,
      snackbarVariant: "",
      mainId: "",
      editflag: false,
      opendelete: false,
      hideAdd: false,
      snackbarMessage: "",
      formData: this.props.formData,
    };
  }

  handleChange = (e) => {
    const { value, max } = e.target;
    if (e.target.name === "gst") {
      this.setState({
        formData: {
          ...this.state.formData,
          [e.target.name]:
            Number(max) >= Number(value) && Number(value) >= 0 ? value : "",
        },
      });
    } else {
      let values = e.target.value;
      if (e.target.value.trim() === "") {
        values = "";
      }

      this.setState({
        formData: {
          ...this.state.formData,
          [e.target.name]: values,
        },
      });
    }
  };
  componentWillUnmount() {
    this.props.onRef(undefined);
  }
  componentWillReceiveProps = () => {
    this.setState({ formData: this.props.formData });
  };
  componentDidMount = () => {
    this.props.onRef(this);
  };

  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };
  handleData = () => {
    return this.state.formData;
  };
  render() {
    const { formData } = this.state;
    const { masterData } = this.props;
    return (
      <div>
        <div className="col-md-12 col-xs-12">
          <div className="pricing-box">
            <div className="row">
              <div className="col-md-4 col-xs-6">
                <div className="sub-head">
                  <p>Fixed Price</p>
                </div>
                <p className="text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Quisque felis ligula, molestie at tellus ac, hendrerit
                  ullamcorper enim. Sed egestas tortor eros, ac ate ante feugiat
                  sed. Quisque sollicitudin orci eu nisi viverra, a tincidunt
                  augue efficitur.
                </p>
              </div>
              <div className="col-md-3 col-xs-6">
                <div className="col-md-12 col-xs-12">
                  <label className="control-label sub-head">Enter Price</label>
                  <input
                    type="number"
                    className="form-control"
                    name="base_price"
                    value={formData.base_price}
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="col-md-12 col-xs-12">
                  <label className="control-label sub-head">
                    GST Percentage
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    name="gst"
                    max="100"
                    value={formData.gst}
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="col-md-12 col-xs-12 text-left">
                  <button className="btn btn-primary" type="submit">
                    Update
                  </button>
                </div>
              </div>
              <div className="col-md-5 col-xs-6">
                <div className="sub-head col-md-12 col-xs-12">
                  <p>Pricing History</p>
                </div>
                <div className="col-md-12 col-xs-12">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>01</td>
                        <td>12 Mar 2021</td>
                        <td className="f-bold">18 %</td>
                        <td className="f-bold">$ 10</td>
                      </tr>
                      <tr>
                        <td>02</td>
                        <td>12 Mar 2021</td>
                        <td className="f-bold">18 %</td>
                        <td className="f-bold">$ 10</td>
                      </tr>
                      <tr>
                        <td>03</td>
                        <td>12 Mar 2021</td>
                        <td className="f-bold">18 %</td>
                        <td className="f-bold">$ 10</td>
                      </tr>
                      <tr>
                        <td>04</td>
                        <td>12 Mar 2021</td>
                        <td className="f-bold">18 %</td>
                        <td className="f-bold">$ 10</td>
                      </tr>
                      <tr>
                        <td>05</td>
                        <td>12 Mar 2021</td>
                        <td className="f-bold">18 %</td>
                        <td className="f-bold">$ 10</td>
                      </tr>
                      <tr>
                        <td>06</td>
                        <td>12 Mar 2021</td>
                        <td className="f-bold">18 %</td>
                        <td className="f-bold">$ 10</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <DeleteComponent
          opendelete={this.state.opendelete}
          handleDeleteClose={this.handleDeleteClose}
          delete={this.handleDelete}
        />
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default FixedPricing;
