import React from "react";
import { Redirect, Route } from "react-router-dom";
function ProtectedRoute({
  masterData: MasterData,
  component: Component,
  logout: logout,
  route: route,

  ...rest
}) {
  return (
    <>
      <Route
        {...rest}
        render={(props) => {
          if (localStorage.getItem("token")) {
            return (
              <React.Fragment>
                {Component ? (
                  <Component
                    {...props}
                    key={route.type}
                    routes={route.routes}
                    masterData={MasterData}
                    type={route.type}
                  />
                ) : (
                  <Redirect
                    to={{ pathname: "/error", state: { from: props.location } }}
                  />
                )}
              </React.Fragment>
            );
          } else {
            logout();
            return null;
          }
        }}
      />
    </>
  );
}

export default ProtectedRoute;
