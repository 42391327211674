import { Grid, Link, Radio } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import {
  ThemeProvider as MuiThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import Pagination from "@material-ui/lab/Pagination";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
// import CustoimportmTableToolbar from "./tableToolbar.comp";
import "../datatable.css";
import CustomTableTheme from "./customtablestyle";
import EmptyComp from "./emptyComponent";
import style from "./table.style";

class DataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleHeader: "",
      sort: false,
      curnXvalue: 0,
      Page: props.options.PageNo ? props.options.PageNo : 0,
      RowsPerPage: props.options.PageSize,
      lastndex: 0,
      checked: false,
      data: props.data,
      start: 0,
      end: 0,
    };
  }

  componentWillReceiveProps = (props) => {
    if (props.options.totalCount < props.options.pagesize) {
      props.options.pagesize = props.options.totalCount;
    }

    this.setState(
      {
        data: props.data,
        Page: props.options.PageNo ? props.options.PageNo : 0,
        RowsPerPage: props.options.PageSize,
      },
      () => {
        if (this.props.options.pageNo === 1) {
          this.setState({
            start: 0,
            end: 0,
          });
        }
        this.checkData();
      }
    );
  };

  checkData = () => {
    let flag = true;
    this.state.data.forEach((item) => {
      if (!item.checked) {
        flag = false;
      }
    });
    if (flag) {
      this.setState({
        checked: true,
      });
    } else {
      this.setState({
        checked: false,
      });
    }
  };
  searchProps = () => {};
  getcelValue = (item, row, index) => {
    const { classes } = this.props;
    if (item.render) {
      return item.render(row, index);
    }

    if (item.type === "date") {
      return (
        <React.Fragment>
          {row[item.field] && (
            <React.Fragment>{row[item.field]}</React.Fragment>
          )}
        </React.Fragment>
      );
    } else if (item.type === "dateTime") {
      return (
        <React.Fragment>
          {row[item.field] && (
            <React.Fragment>{row[item.field]}</React.Fragment>
          )}
        </React.Fragment>
      );
    } else if (item.type === "status") {
      if (row[item.field]) {
        if (row[item.field] === "Active") {
          return (
            <span style={{ backgroundColor: "#78DEC0", padding: "0px 8px" }}>
              {row[item.field]}
            </span>
          );
        } else {
          return (
            <span style={{ backgroundColor: "#DEDEDE", padding: "0px 8px" }}>
              {row[item.field]}
            </span>
          );
        }
      } else {
        return row[item.field];
      }
    } else if (item.type === "img") {
      return (
        (item.rowActionCLick) ? <img alt="" style={{cursor: 'pointer'}} src={row[item.field]} onClick={(e) => item.rowActionCLick(e, row)}/> : <img alt="" src={row[item.field]}/>
      )
    } else {
      return (
        (item.rowActionCLick) 
        ? 
        <p title={row[item.field]} style={{cursor: 'pointer'}} className={classes.tableCellTurn} onClick={(e) => item.rowActionCLick(e, row)}>
          {row[item.field]}
        </p>
        :
        <p title={row[item.field]} className={classes.tableCellTurn}>
          {row[item.field]}
        </p>
      );
    }
  };
  dataUpdate = (data) => {
    let sortedData = [];
    data.forEach((item) => {
      if (item.checked) {
        sortedData.push(item);
      }
    });
    this.setState(
      {
        data,
      },
      () => {
        setTimeout(() => {
          this.props.selectProps(sortedData);
        }, 150);
      }
    );
  };
  handleCkeckChange = (e) => {
    let checked = e.target.checked;
    if (checked) {
      this.state.data.forEach((item) => {
        item["checked"] = true;
      });
      this.dataUpdate(this.state.data);
      this.setState({
        checked: true,
      });
    } else {
      this.state.data.forEach((item) => {
        item["checked"] = false;
      });
      this.dataUpdate(this.state.data);
      this.setState({
        checked: false,
      });
    }
  };
  handleRadioChange = (e, id) => {
    let checked = e.target.checked;
    if (checked) {
      this.state.data.forEach((item, index) => {
        if (index === id) {
          item["checked"] = true;
        } else {
          item["checked"] = false;
        }
      });
      this.dataUpdate(this.state.data);
    } else {
      this.state.data.forEach((item) => {
        item["checked"] = false;
      });
      this.dataUpdate(this.state.data);
    }
  };
  handleChildChange = (e, id) => {
    let checked = e.target.checked;
    if (checked) {
      let flag = true;
      this.state.data.forEach((item, index) => {
        if (index === id) {
          item["checked"] = true;
        }
        if (!item.checked) {
          flag = false;
        }
      });
      if (flag) {
        this.setState({ checked: true });
      }

      this.dataUpdate(this.state.data);
    } else {
      this.state.data.forEach((item, index) => {
        if (index === id) {
          item["checked"] = false;
        }
      });
      this.setState({
        checked: false,
      });
      this.dataUpdate(this.state.data);
    }
  };

  handleChangePage = (e, value) => {
    const start = (value - 1) * this.props.options.pagesize;
    const end = start + this.props.options.pagesize;
    this.setState({
      start: start + 1,
      end: end,
    });

    this.props.options.handlepagination(value);
  };

  compare = (a, b) => {
    const { options } = this.props;

    let comparison = 0;
    if (typeof a[options.sortvalue] == "number") {
      const bandA = a[options.sortvalue];
      const bandB = b[options.sortvalue];
      if (bandA > bandB) {
        comparison = 1;
      } else if (bandA < bandB) {
        comparison = -1;
      }
    } else if (typeof a[options.sortvalue] == "string") {
      const bandA = a[options.sortvalue].toUpperCase();
      const bandB = b[options.sortvalue].toUpperCase();
      if (bandA > bandB) {
        comparison = 1;
      } else if (bandA < bandB) {
        comparison = -1;
      }
    }
    return comparison;
  };
  desCcompare = (a, b) => {
    const { options } = this.props;
    let comparison = 0;
    if (typeof a[options.sortvalue] == "number") {
      const bandA = a[options.sortvalue];
      const bandB = b[options.sortvalue];

      if (bandA < bandB) {
        comparison = 1;
      } else if (bandA > bandB) {
        comparison = -1;
      }
    } else if (typeof a[options.sortvalue] == "string") {
      const bandA = a[options.sortvalue].toUpperCase();
      const bandB = b[options.sortvalue].toUpperCase();
      if (bandA < bandB) {
        comparison = 1;
      } else if (bandA > bandB) {
        comparison = -1;
      }
    }

    return comparison;
  };

  handleSort = () => {
    const { data } = this.state;
    if (this.state.sort) {
      data.sort(this.desCcompare);
    } else {
      data.sort(this.compare);
    }
    this.setState({ data, sort: !this.state.sort });
  };
  handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const { data } = this.state;

    const [reorderedItem] = data.splice(result.source.index, 1);
    data.splice(result.destination.index, 0, reorderedItem);
    this.setState({ data });
  };
  handlepageCount = () => {
    const { options } = this.props;
    const { start, end } = this.state;

    return (
      <div style={{ margin: "18px 0px" }}>
        Showing {start === 0 ? 1 : start + " "} to{" "}
        {end === 0
          ? options.pagesize
          : end > options.totalCount
          ? options.totalCount
          : end}
        {" of " + options.totalCount}
      </div>
    );
  };
  columnLength = () => {
    let ColLen = this.props.columns.length;
    if (this.props.options.draggable) ColLen++;
    if (this.props.options.selection) ColLen++;
    if (this.props.options.radioSelection) ColLen++;
    if (this.props.options.sort) ColLen++;
    return ColLen;
  };

  render() {
    const { classes, columns, options, sort, stickyHeader, actions } =
      this.props;

    const { data, checked, RowsPerPage, Page, start, end } = this.state;

    let ColLen = columns.length;
    console.log(this.columnLength());
    return (
      <MuiThemeProvider theme={CustomTableTheme}>
        {/* {options.header && (
          <Toolbar className={classes.toolbar}>
            <CustomTableToolbar
              exportButton={options.exportButton}
              title={title}
              data={this.state.data}
              columns={columns}
              exportFileName={options.exportFileName}
            />
          </Toolbar>
        )} */}
        <div
          style={{
            maxHeight: options.bodyMaxHeight,
            overflowX:
              stickyHeader && options.bodyMaxHeight && options.bodyMinHeight
                ? "auto"
                : "",
            minHeight: options.bodyMinHeight,
          }}
        >
          <Table stickyHeader={stickyHeader ? stickyHeader : false}>
            <TableHead>
              <TableRow style={{ height: 30 }}>
                {options.sort && (
                  <Tooltip title="Sort">
                    <TableCell
                      align="left"
                      width="4%"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => this.handleSort(e)}
                      className={
                        stickyHeader
                          ? classes.TableStickyHeader
                          : classes.TableHeader
                      }
                    >
                      <SyncAltIcon className={classes.iconStyle} />
                    </TableCell>
                  </Tooltip>
                )}
                {options.draggable && (
                  <Tooltip title="Drag">
                    <TableCell
                      className={
                        stickyHeader
                          ? classes.TableStickyHeader
                          : classes.TableHeader
                      }
                      align="left"
                      width="4%"
                    ></TableCell>
                  </Tooltip>
                )}
                {options.selection && (
                  <TableCell
                    align="left"
                    width="4%"
                    className={
                      stickyHeader
                        ? classes.TableStickyHeader
                        : classes.TableHeader
                    }
                  >
                    <Checkbox
                      className={classes.listIconButton}
                      onClick={(e) => this.handleCkeckChange(e)}
                      checked={checked}
                      value={checked}
                    />
                  </TableCell>
                )}

                {options.radioSelection && (
                  <TableCell
                    align="left"
                    width="4%"
                    className={
                      stickyHeader
                        ? classes.TableStickyHeader
                        : classes.TableHeader
                    }
                  >
                    Select
                  </TableCell>
                )}
                {columns.map((item, index) => (
                  <React.Fragment>
                    <TableCell
                      key={item.title + index}
                      width={item.width}
                      align={
                        item.align
                          ? item.align
                          : item.type === "numeric"
                          ? "right"
                          : item.type === "status" || item.type === "date"
                          ? "center"
                          : ""
                      }
                      className={
                        stickyHeader
                          ? classes.TableStickyHeader
                          : classes.TableHeader
                      }
                    >
                      {item.title}
                    </TableCell>
                    <React.Fragment></React.Fragment>
                  </React.Fragment>
                ))}
                {actions && (
                  <React.Fragment>
                    {actions.length !== 0 && (
                      <TableCell
                        align="center"
                        width="4%"
                        className={
                          stickyHeader
                            ? classes.TableStickyHeader
                            : classes.TableHeader
                        }
                      >
                        Actions
                      </TableCell>
                    )}
                  </React.Fragment>
                )}
              </TableRow>
              {options.filter && (
                <TableRow className={classes.tableSearchRow}>
                  <React.Fragment>
                    {options.selection && (
                      <TableCell
                        className={
                          stickyHeader
                            ? classes.TableStickysearchHeader
                            : classes.TableHeadersearch
                        }
                      ></TableCell>
                    )}
                  </React.Fragment>
                  {columns.map((item) => (
                    <TableCell
                      className={
                        stickyHeader
                          ? classes.TableStickysearchHeader
                          : classes.TableHeadersearch
                      }
                    >
                      {this.filterbox(item)}
                    </TableCell>
                  ))}
                </TableRow>
              )}
            </TableHead>
            <DragDropContext onDragEnd={this.handleOnDragEnd}>
              <Droppable droppableId="tab-body">
                {(provided) => (
                  <TableBody
                    className="tab-body"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {data.length !== 0 ? (
                      <React.Fragment>
                        {data.map((row, index) => (
                          <Draggable
                            isDragDisabled={!options.draggable}
                            key={row.id}
                            draggableId={row.id}
                            index={index}
                          >
                            {(provided) => (
                              <TableRow
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                key={index}
                                className={
                                  row.checked ? classes.tablerowHighlight : ""
                                }
                              >
                                {options.sort && (
                                  <Tooltip title="Sort">
                                    <TableCell width="4%"></TableCell>
                                  </Tooltip>
                                )}
                                {options.draggable && (
                                  <Tooltip title="Drag">
                                    <TableCell
                                      align="left"
                                      width="4%"
                                      style={{ cursor: "pointer" }}
                                      //onClick={(e) => this.handleSort(e)}
                                    >
                                      <img src="/app-assets/backend-images/icons/drag-icon.svg" />
                                    </TableCell>
                                  </Tooltip>
                                )}
                                {options.selection && (
                                  <TableCell align="left" width="4%">
                                    <Checkbox
                                      className={classes.listIconButton}
                                      onClick={(e) =>
                                        this.handleChildChange(e, index)
                                      }
                                      checked={row.checked ? true : false}
                                      value={row.checked ? true : false}
                                    />
                                  </TableCell>
                                )}{" "}
                                {/*{options.sort && options.selection &&(
                                  <TableCell
                                    align="left"
                                    width="10%">
                                    
                                    kkkkkk
                                    
                                    </TableCell>
                               )}{" "}*/}
                                {options.radioSelection && (
                                  <TableCell align="left" width="4%">
                                    <Radio
                                      className={classes.listIconButton}
                                      onClick={(e) =>
                                        this.handleRadioChange(e, index)
                                      }
                                      checked={row.checked ? true : false}
                                      value={row.checked ? true : false}
                                    />
                                  </TableCell>
                                )}
                                {columns.map((item) => (
                                  <React.Fragment>
                                    {item.editData &&
                                    item.editData(row).hidden ? (
                                      <TableCell
                                        width={item.width}
                                        align={
                                          item.align
                                            ? item.align
                                            : item.type === "numeric"
                                            ? "right"
                                            : item.type === "status" ||
                                              item.type === "date"
                                            ? "center"
                                            : ""
                                        }
                                        style={{
                                          maxWidth: "calc(100vw /" + ColLen,
                                          whiteSpace:
                                            item.type === "date"
                                              ? "nowrap"
                                              : "",
                                        }}
                                      ></TableCell>
                                    ) : (
                                      <TableCell
                                        width={item.width}
                                        align={
                                          item.align
                                            ? item.align
                                            : item.type === "numeric"
                                            ? "right"
                                            : item.type === "status" ||
                                              item.type === "date"
                                            ? "center"
                                            : ""
                                        }
                                        style={{
                                          maxWidth: "calc(100vw /" + ColLen,
                                          whiteSpace:
                                            item.type === "date"
                                              ? "nowrap"
                                              : "",
                                        }}
                                      >
                                        {this.getcelValue(item, row, index)}
                                      </TableCell>
                                    )}
                                  </React.Fragment>
                                ))}
                                {actions && (
                                  <React.Fragment>
                                    {actions.length !== 0 && (
                                      <React.Fragment>
                                        <TableCell align="center" width="7%">
                                          {actions.map((item) => (
                                            (!item.showWhen || row[Object.keys(item.showWhen)[0]] === Object.values(item.showWhen)[0]) && <Tooltip title={item.tooltip}>
                                              <Link
                                                style={{ padding: 5 }}
                                                onClick={(e) =>
                                                  item.rowActionCLick(e, row)
                                                }
                                                className={item.className}
                                              >
                                                <img
                                                  alt=""
                                                  src={item.iconName}
                                                />
                                              </Link>
                                            </Tooltip>
                                          ))}
                                        </TableCell>
                                      </React.Fragment>
                                    )}
                                  </React.Fragment>
                                )}
                              </TableRow>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </React.Fragment>
                    ) : (
                      <EmptyComp cellcount={this.columnLength()} type="table" />
                    )}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        </div>
        {options.pagination && data.length !== 0 && (
          <Grid container spacing={1}>
            <Grid item xs={6} style={{ position: "relative" }}>
              {options.totalCount !== 0 ? this.handlepageCount() : ""}
            </Grid>
            <Grid item xs={6}>
              <Pagination
                colSpan={ColLen}
                count={Math.ceil(options.totalCount / options.pagesize)}
                // rowsPerPage={RowsPerPage}

                color="primary"
                size="small"
                className={classes.pagination}
                page={options.pageNo}
                align="right"
                variant="outlined"
                shape="rounded"
                onChange={this.handleChangePage}

                // ActionsComponent={TablePaginationActions}
              />
            </Grid>
          </Grid>
        )}
      </MuiThemeProvider>
    );
  }
}

export default withStyles(style)(DataTable);
