import Modal from "@material-ui/core/Modal";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import "./blockloader.css";
import styles from "./loader.style";

class CommonLoader extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    const { open } = this.props;
    let colr = "#2e6adf ";
    return (
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        hideBackdrop
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
      >
        <div class="spinner-wrapper">
          <div class="sk-cube-grid">
            <div
              class="sk-cube sk-cube1"
              style={{
                backgroundColor: colr,
              }}
            ></div>
            <div
              class="sk-cube sk-cube2"
              style={{
                backgroundColor: colr,
              }}
            ></div>
            <div
              class="sk-cube sk-cube3"
              style={{
                backgroundColor: colr,
              }}
            ></div>
            <div
              class="sk-cube sk-cube4"
              style={{
                backgroundColor: colr,
              }}
            ></div>
            <div
              class="sk-cube sk-cube5"
              style={{
                backgroundColor: colr,
              }}
            ></div>
            <div
              class="sk-cube sk-cube6"
              style={{
                backgroundColor: colr,
              }}
            ></div>
            <div
              class="sk-cube sk-cube7"
              style={{
                backgroundColor: colr,
              }}
            ></div>
            <div
              class="sk-cube sk-cube8"
              style={{
                backgroundColor: colr,
              }}
            ></div>
            <div
              class="sk-cube sk-cube9"
              style={{
                backgroundColor: colr,
              }}
            ></div>
          </div>
        </div>
      </Modal>
    );
  }
}
CommonLoader.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles, { withTheme: true })(CommonLoader);
