import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Input from "@material-ui/core/Input";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// import SearchIcon from "@material-ui/icons/Search";
import PropTypes from "prop-types";
import React from "react";
import themeStyles from "./404.theme.style";

const Error404 = (props) => {
  const { classes } = props;

  return (
    <div className={classes.background}>
      <Card raised>
        <CardContent>
          <Typography variant="headline" component="h2" gutterBottom>
            Page Under Construction
          </Typography>
          <Typography>
            Sorry the page you were looking for could not be found.
          </Typography>
          <Input placeholder="Search Portal" />
        </CardContent>
        <CardActions>
          <Button href="/">Go Home</Button>
        </CardActions>
      </Card>
    </div>
  );
};

Error404.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(themeStyles, { withTheme: true })(Error404);
