import apis from "../../apiactions/api.actions";

const coupon = "coupons/";
const employee_listall = "employee/?view=all";
const customers = "customers/";

export const searchCouponData = (val) => {
  var query,
    category_query,
    status_query,
    start_date_query,
    end_date_query,
    page_query;

  category_query = val.coupon_category
    ? "&coupon_category=" + val.coupon_category
    : "";
  status_query = val.status ? "&status=" + val.status : "";
  start_date_query = val.start_date ? "&start_date=" + val.start_date : "";
  end_date_query = val.end_date ? "&end_date=" + val.end_date : "";
  page_query = val.page ? "&page=" + val.page : "";
  query =
    coupon +
    "?search=" +
    val.search +
    category_query +
    status_query +
    start_date_query +
    end_date_query +
    page_query;

  return apis.getDatas(query);
};

export const addCoupon = (data) => apis.createData(coupon, data);
export const getCouponDetails = (id) => apis.getDatas(coupon + id);
export const updateCouponDetails = (id, data) =>
  apis.updateData(`${coupon}${id}`, data);
export const deleteCoupon = (id) => apis.deleteData(`${coupon}${id}`);

export const getEmployeeList = () => apis.getDatas(employee_listall);

export const getCouponCustomers = (id, data) =>
  apis.getDatas(`${coupon}${id}/${customers}&page=${data.page}`);
