import { TableCell } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";

const CustomTableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(TableCell);
class EmptyComp extends React.Component {
  constructor(props) {
    super();
    this.state = {
      toggleHeader: "",
    };
  }
  render() {
    const { type, cellcount } = this.props;
    if (type === "table") {
      return (
        <CustomTableCell colSpan={cellcount} align="center">
          <p style={{ marginTop: 10 }}>No Records Found</p>
        </CustomTableCell>
      );
    } else if (type === "dialog") {
      return (
        <div>
          <Grid container>
            <Grid item xs={12} align="center" style={{ marginTop: "5%" }}>
              <Grid item xs="6">
                <p>No Records Found</p>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      return (
        <div>
          <Grid container>
            <Grid item xs={12} align="center" style={{ marginTop: "5%" }}>
              <Grid item xs="6">
                <p>No Records Found</p>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    }
  }
}
EmptyComp.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default EmptyComp;
