import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import React from "react";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import "./sidemenu.css";
const menuItems = [
  // {
  //   label: "Dashboard",
  //   name: "dashboard",
  //   path: "/dashboard",
  //   icon: "ic-dashboard",
  // },
  {
    label: "File Management",
    name: "fileManagement",
    path: "/user-file-management/folders-list",
    icon: "ic-servicemanagement",
  },
  // {
  //   label: "Leads & Enquires",
  //   name: "Leads & Enquires",
  //   path: "/leads-enquiry",
  //   icon: "ic-enquirylisting",
  // },
  // {
  //   label: "Customers",
  //   name: "customers",
  //   path: "/customers",
  //   icon: "ic-packagemanagement",
  // },
  // {
  //   label: "Companies",
  //   name: "companies",
  //   path: "/companies",
  //   icon: "ic-orderspayments",
  // },
  // {
  //   label: "Orders",
  //   name: "orders",
  //   path: "/orders/orders-list",
  //   icon: "ic-compliances",
  // },
  // {
  //   label: "Checklists",
  //   name: "checklist",
  //   path: "/checklist/checklistView",
  //   icon: "ic-digitalmanagement",
  // },
  // {
  //   label: "Coupons",
  //   name: "coupons",
  //   path: "/coupons/coupons-list",
  //   icon: "ic-coupons",
  // },
  // {
  //   label: "Services & Packages",
  //   name: "servicespackages",
  //   path: "/packages-services",
  //   icon: "ic-servicemanagement",
  // },
  // {
  //   label: "Filings and Compliance",
  //   name: "filingscompliance",
  //   path: "/filings-compliance",
  //   icon: "ic-governmentfillings",
  // },
  // {
  //   label: "Data Management",
  //   name: "dataManagement",
  //   path: "/data-management",
  //   icon: "ic-datamanagement",
  // },
];

function Sidemenu(params) {
  const location = useLocation();
  const history = useHistory();
  const menuitem = menuItems;
  const handlePath = (e, path) => {
    history.push(path);
  };
  return (
    <div
      className="main-menu menu-fixed menu-light menu-accordion menu-shadow"
      data-scroll-to-active="true"
    >
      <div>
        <List
          //  className="navigation navigation-main"
          id="main-menu-navigation"
          data-menu="menu-navigation"
        >
          {menuitem.map((menuItem) => (
            <ListItem
              className={` nav-item ${
                location.pathname.startsWith(menuItem.path) ? "active" : ""
              }`}
              key={menuItem.name}
            >
              <Link onClick={(e) => handlePath(e, menuItem.path)}>
                <i className={`menu-icon ${menuItem.icon}`}></i>
                {/* <span className="menu-title" data-i18n="">
                  {menuItem.label}
                </span> */}
              </Link>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
}

export default withRouter(Sidemenu);
