import React, { Component } from "react";
import BreadCrumb from "../../../../layout/breadcrumb";
import NavigationTab from "../../../../plugins/customtab/customtab";
import nsmConfig from "../../../../plugins/customtab/customtabmenu";
import { getCountryState, get_Company_Details } from "../../apifunctions";
import CompanyAddressInformation from "./address-information/address-information";
import CompanyBasicInformation from "./basic-information/basic-information";
import "./companylist-component.css";
import CompanyCompliancesInformation from "./compliances-information/compliances-information";
import CompanyContactInformation from "./contact-information/contact-information";
import CompanyOfficialMaping from "./official-maping/official-maping";
import CompanyPromoterInformation from "./promoter-information/promoter-information";
import CompanyRegistrationInformation from "./registration-information/registration-information";

let tabData = nsmConfig["companylistcomponent"].menus;
let tabnew = [{}];
let com_Id = "";
class CustomerListComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: null,
      coun_state: [],
      currentTab: 1,
      companyDetail: {},
    };
    com_Id =
      this.props.match.params.id !== "new" ? this.props.match.params.id : "";
  }
  componentDidMount = () => {
    // this.getMasterData();
  };

  componentDidMount = () => {
    this.getState();
    if (com_Id) {
      this.getData(com_Id);
    }
  };

  async getData(com_Id) {
    this.setState({ loader: true });
    try {
      get_Company_Details(com_Id)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            companyDetail: res,
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            datas: [],
            loader: false,
            snackbarMessage: err.response.data.error.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }
  getState = () => {
    getCountryState()
      .then((res) => {
        this.setState({ coun_state: res.data.state });
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err.response.data.error.message,
          snackbarOpen: true,
          snackbarVariant: "error",
        });
      });
  };
  handleTabView = (value) => {
    if (com_Id) {
      this.setState({
        currentTab: value,
      });
    }
  };
  updateId = (id) => {
    com_Id = id;
    this.props.history.push(`/companies-list-create/${id}`);
  };

  render() {
    const { currentTab, companyDetail, coun_state } = this.state;
    return (
      <>
        <div className="app-content content">
          <div className="content-wrapper">
            <BreadCrumb
              type={com_Id ? "companylistcomponent" : "companyaddlistcomponent"}
            />
            <div className>
              <div className="content-body">
                <section id="configuration">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        {com_Id && (
                          <div className="customer-list-head">
                            <div className="row">
                              <div className="col-md-7 col-xs-12 text-left">
                                <span className="details">
                                  Date & Time: {companyDetail.datetime_created}
                                </span>

                                <span className="details">
                                  Business ID: {companyDetail.company_code}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                        <NavigationTab
                          tabData={tabData}
                          currentTab={currentTab}
                          handleTabView={this.handleTabView}
                        />
                        {currentTab === 1 && (
                          <CompanyBasicInformation
                            key={com_Id + currentTab}
                            coun_state={coun_state}
                            updateId={this.updateId}
                            com_Id={com_Id}
                            masterData={this.props.masterData}
                            handleParent={this.handleParent}
                            handleTabView={this.handleTabView}
                          />
                        )}
                        {currentTab === 2 && com_Id && (
                          <CompanyOfficialMaping
                            key={com_Id + currentTab}
                            com_Id={com_Id}
                            companyDetail={companyDetail}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                          />
                        )}
                        {currentTab === 3 && com_Id && (
                          <CompanyContactInformation
                            key={com_Id + currentTab}
                            com_Id={com_Id}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                          />
                        )}
                        {currentTab === 4 && com_Id && (
                          <CompanyRegistrationInformation
                            key={com_Id + currentTab}
                            com_Id={com_Id}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                          />
                        )}
                        {currentTab === 5 && com_Id && (
                          <CompanyAddressInformation
                            key={com_Id + currentTab}
                            com_Id={com_Id}
                            coun_state={coun_state}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                          />
                        )}
                        {currentTab === 6 && com_Id && (
                          <CompanyPromoterInformation
                            key={com_Id + currentTab}
                            com_Id={com_Id}
                            coun_state={coun_state}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                          />
                        )}
                        {currentTab === 7 && com_Id && (
                          <CompanyCompliancesInformation
                            key={com_Id + currentTab}
                            com_Id={com_Id}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CustomerListComponent;
