import { Grid } from "@material-ui/core";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import CommonLoader from "../../../plugins/commonLoader/commonLoader.component";
import DataTable from "../../../plugins/dataTable/dataTable";
import SearchField from "../../../plugins/searchfield";
import SelectBox from "../../../plugins/selectbox/commonSelectBox";
import {
  getbusinsector,
  getConstCat,
  seachCompanyData,
  searhCustomerData,
} from "../apifunctions";

class CompanyList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [],
      options: {},
      mainId: "",
      opendelete: false,
      formSearch: {
        search: "",
        constitution__id: "",
        sector__id: "",
        industry__id: "",
        sub_sector__id: "",
        default_customer__id: "",
        page: 1,
      },
      employee_list: [],
      customData: [],
      constData: [],
      loader: false,
      pagesize: 0,
      busData: [],
      totalCount: 0,
    };
  }

  async componentDidMount() {
    try {
      await this.getData();
    } catch (err) {}
  }

  handlepagination(id) {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: id,
        },
      },
      () => {
        this.getData();
      }
    );
  }
  async getData() {
    this.setState({ loader: true });
    try {
      seachCompanyData(this.state.formSearch)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            datas: res.results,
            totalCount: res.count,
            pagesize: res.page_size,
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.message,
            snackbarOpen: true,
            snackbarVariant: "error",
            loader: false,
          });
        });
    } catch (err) {}
  }
  handleView = (e, row) => {
    this.props.history.push(`/companies-list-view/${row.id}`);
  };

  handleChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        if (e.target.name !== "search") {
          this.handleSearch();
        }
      }
    );
  };

  handleDataChange = (value) => {
    if (value.length >= 3) {
      this.setState({
        formSearch: {
          ...this.state.formSearch,
          constitution_category_name: value,
        },
      });
      this.getconstData(value);
    }
  };

  handleSelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          constitution__id: e.id,
          constitution_category_name: e.constitution_category_name,
        },
      },
      () => {
        this.handleSearch();
      }
    );
  };
  async getconstData(value) {
    try {
      getConstCat(value)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            constData: res.constitution_categories,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.response.data.error.message.error,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }
  handleBusDataChange = (value) => {
    if (value.length >= 3) {
      this.setState({
        formSearch: {
          ...this.state.formSearch,
          business_sector_name: value,
        },
      });
      this.getbus(value);
    }
  };

  handleBusSelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          sector__id: e.id,
          business_sector_name: e.business_sector_name,
        },
      },
      () => {
        this.handleSearch();
      }
    );
  };
  async getbus(value) {
    try {
      getbusinsector(value)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            busData: res,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.response.data.error.message.error,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }
  handleCustDataChange = (value) => {
    if (value.length >= 3) {
      this.setState({
        formSearch: {
          ...this.state.formSearch,
          name: value,
        },
      });
      this.getCusts(value);
    }
  };

  handleCustSelectChange = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          default_customer__id: e.id,
          name: e.name,
        },
      },
      () => {
        this.handleSearch();
      }
    );
  };
  async getCusts(value) {
    try {
      searhCustomerData(value)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            customData: res.customers,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.response.data.error.message.error,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }
  handleClear = (name) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          [name]: "",
        },
      },
      () => {
        this.handleSearch();
      }
    );
  };
  handleSearch = (e) => {
    this.setState(
      {
        formSearch: {
          ...this.state.formSearch,
          page: 1,
        },
      },
      () => {
        this.getData();
      }
    );
  };
  handleEdit = (e, row) => {
    this.props.history.push(`/companies-list-create/${row.id}`);
  };
  render() {
    const { masterData } = this.props;
    const {
      loader,
      formSearch,
      employee_list,
      busData,
      customData,
      totalCount,
      pagesize,
      constData,
    } = this.state;

    return (
      <div className="card-content collapse show">
        <CommonLoader open={loader} />
        <div className="card-body card-dashboard">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={10}>
              <Grid container spacing={1}>
                <Grid item xs={6} sm={3} className="table-search">
                  <SearchField
                    onChange={this.handleChange}
                    value={formSearch.search}
                    handleSearch={this.handleSearch}
                    handleClear={this.handleClear}
                  />
                </Grid>
                <Grid item xs={6} sm={3} className="table-search">
                  <SelectBox
                    Data={constData}
                    id="id"
                    async="true"
                    value={formSearch.constitution_category_name}
                    desc="constitution_category_name"
                    getData={this.handleDataChange}
                    placeholder="Category"
                    className="form-control"
                    onChange={this.handleSelectChange}
                  />
                </Grid>
                <Grid item xs={6} sm={3} className="table-search">
                  <SelectBox
                    Data={busData}
                    id="id"
                    async="true"
                    value={formSearch.business_sector_name}
                    desc="business_sector_name"
                    getData={this.handleBusDataChange}
                    placeholder="Sector"
                    className="form-control"
                    onChange={this.handleBusSelectChange}
                  />
                </Grid>
                <Grid item xs={6} sm={3} className="table-search">
                  <SelectBox
                    Data={customData}
                    id="id"
                    async="true"
                    value={formSearch.name}
                    desc="name"
                    getData={this.handleCustDataChange}
                    placeholder="Customer"
                    className="form-control"
                    onChange={this.handleCustSelectChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid align="right" item xs={12} sm={2}>
              <Link
                to={"/companies-list-create/new"}
                className="btn btn-primary"
              >
                + &nbsp; Add New
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} className="table-block">
              <DataTable
                data={this.state.datas}
                columns={[
                  {
                    title: "Company Code",
                    type: "text",
                    field: "company_code",
                  },
                  {
                    title: "Date of Incorporation",
                    type: "text",
                    field: "incorporation_date",
                  },
                  {
                    title: "Business Name",
                    type: "text",
                    field: "business_name",
                  },
                  {
                    title: "Category",
                    type: "text",
                    field: "constitution_category_name",
                    render: (rowData) =>
                      rowData.constitution && (
                        <span>
                          {rowData.constitution.constitution_category_name}
                        </span>
                      ),
                  },
                  {
                    title: "Customer",
                    type: "text",
                    field: "customer_name",
                  },
                  {
                    title: "Sector",
                    type: "text",
                    field: "constitution_category_name",
                    render: (rowData) =>
                      rowData.sector && (
                        <span>{rowData.sector.business_sector_name}</span>
                      ),
                  },
                ]}
                options={{
                  sort: true,
                  sortvalue: "company_code",
                  pageNo: this.state.formSearch.page,
                  pagination: true,
                  totalCount: totalCount,
                  pagesize: pagesize,
                  handlepagination: this.handlepagination.bind(this),
                }}
                actions={[
                  {
                    className: "view",
                    rowActionCLick: this.handleView.bind(this),
                    iconName: "/app-assets/backend-images/icons/view.png",
                    tooltip: "View Details",
                  },
                  {
                    className: "view",
                    rowActionCLick: this.handleEdit.bind(this),
                    iconName: "/app-assets/backend-images/icons/edit-icon.svg",
                    tooltip: "Edit Details",
                  },
                ]}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default CompanyList;
