import { Grid } from "@material-ui/core";
import React, { Component } from "react";
// import DataTable from "../../../plugins/dataTable/dataTable";
import SnackbarMessage from "../../../plugins/snackbar-component";
import { getCouponCustomers, getCouponDetails } from "../apifunctions";

class CouponsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {},
      customers: { page: 1 },
      pagesize: 0,
      totalCount: 0,
      snackbarVariant: "",
      snackbarOpen: false,
      snackbarMessage: "",
    };
  }

  componentDidMount = () => {
    this.getData();
    this.getCouponCustomers();
  };

  async getData() {
    try {
      getCouponDetails(this.props.match.params.id)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            formData: res,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }
  async getCouponCustomers() {
    try {
      getCouponCustomers(this.props.match.params.id, this.state.customers)
        .then((res) => {
          this.setState({
            customers: res.results,
            totalCount: res.count,
            pagesize: res.page_size,
          });
        })
        .catch((err) => {
          this.setState({
            snackbarMessage: err.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }

  handlepagination(id) {
    this.setState(
      {
        customers: {
          ...this.state.customers,
          page: id,
        },
      },
      () => {
        this.getData();
      }
    );
  }
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  render() {
    const { formData, customers, totalCount, pagesize } = this.state;

    return (
      <div className="card-content coupon-card collapse show">
        <div className="card-body card-dashboard">
          <Grid container spacing={1} className="coupon-details">
            <Grid item xs={12} sm={12} className="header-row">
              <h4 className="title">{formData.coupon_name}</h4>
              <span className="code">{formData.coupon_code}</span>
            </Grid>
            <Grid item xs={12} sm={12} className="details-row">
              <Grid container spacing={1} className="coupon-details-section">
                <Grid item xs={4} sm={2} className="detail-box">
                  <p className="sub">Coupon Category</p>
                  <p className="text">
                    {formData.coupon_category
                      ? formData.coupon_category.coupon_category_display_name
                      : ""}
                  </p>
                </Grid>
                <Grid item xs={4} sm={2} className="detail-box">
                  <p className="sub">Status</p>
                  <p className="text">{formData.status}</p>
                </Grid>
                <Grid item xs={4} sm={2} className="detail-box">
                  <p className="sub">Minimum Sale Discount</p>
                  <p className="text">
                    {formData.minimum_sale_value_for_discount}
                  </p>
                </Grid>
                <Grid item xs={4} sm={2} className="detail-box">
                  <p className="sub">Maximum Usage Limit</p>
                  <p className="text">{formData.maximum_usage_limit}</p>
                </Grid>
                <Grid item xs={4} sm={2} className="detail-box">
                  <p className="sub">Discount Amount</p>
                  <p className="text">{formData.discount_amount}</p>
                </Grid>
                <Grid item xs={4} sm={2} className="detail-box">
                  <p className="sub">Coupon Used Count</p>
                  <p className="text">{formData.current_usage}</p>
                </Grid>
                <Grid item xs={4} sm={2} className="detail-box">
                  <p className="sub">Start Date</p>
                  <p className="text">{formData.start_date}</p>
                </Grid>
                <Grid item xs={4} sm={2} className="detail-box">
                  <p className="sub">End Date</p>
                  <p className="text">{formData.end_date}</p>
                </Grid>
                <Grid item xs={4} sm={2} className="detail-box">
                  <p className="sub">Discount Type</p>
                  <p className="text">{formData.discount_type}</p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={1} className="list-section">
            <Grid item xs={12} sm={12} className="">
              <h4>Customers who availed this coupon</h4>
            </Grid>
            <Grid item xs={12} sm={12} className="table-block">
              {/* <DataTable
                data={customers}
                columns={[
                  {
                    title: "Date & Time",
                    type: "text",
                    field: "",
                  },
                  {
                    title: "Customer ID",
                    type: "text",
                    field: "",
                  },
                  {
                    title: "Customer Name",
                    type: "text",
                    field: "",
                  },
                  {
                    title: "Business Name",
                    type: "text",
                    field: "",
                  },
                  {
                    title: "Order ID",
                    type: "text",
                    field: "",
                  },
                  {
                    title: "Service Name",
                    type: "text",
                    field: "",
                  },
                  {
                    title: "Discount Obtained",
                    type: "text",
                    field: "",
                  },
                ]}
                options={{
                  sort: true,
                  sortvalue: "id",
                   pageNo: this.state.formSearch.page,
                  pagination: true,
                  totalCount: totalCount,
                  pagesize: pagesize,
                  handlepagination: this.handlepagination.bind(this),
                }}
              /> */}
            </Grid>
          </Grid>
        </div>
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default CouponsView;
