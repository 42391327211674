import React, { Component } from "react";
import BreadCrumb from "../../../../layout/breadcrumb";
import CommonLoader from "../../../../plugins/commonLoader/commonLoader.component";
import NavigationTab from "../../../../plugins/customtab/customtab";
import { customerlistcomponent } from "../../../../plugins/customtab/customtabmenu";
import { getCustomer } from "../../apifunctions";
import AddressInformation from "./address-information/address-information";
import BasicInformation from "./basic-information/basic-information";
import "./customer_manage_detailed.css";
import KYCInformation from "./kyc-information/kyc-information";
import OfficialMaping from "./official-maping/official-maping";

let custId = "";
let tabData = customerlistcomponent.menus;

class CustomerListComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      custData: {},
      // editflag: false,
      currentTab: 1,
      loader: true,
    };

    custId = this.props.match.params.id ? this.props.match.params.id : "";
  }
  componentDidMount = () => {
    if (custId) {
      // this.loadCustomerInfo(custId);
      this.getCustomerDetails(custId);
    } else {
      this.setState({
        loader: false,
      });
    }
  };

  async getCustomerDetails(custId) {
    getCustomer(custId)
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          custData: res,
          loader: false,
        });
      })
      .catch((err) => {
        this.setState({
          snackbarMessage: err.message,
          snackbarOpen: true,
          snackbarVariant: "error",
          loader: false,
        });
      });
  }

  // async loadCustomerInfo(custId) {
  //   try {
  //     getCustomerInfo(custId)
  //       .then((res) => res.data)
  //       .then((res) => {
  //         console.log(res);
  //         this.setState({
  //           custData: res,
  //           loader: false,
  //         });
  //       })
  //       .catch((err) => {
  //         this.setState({
  //           snackbarMessage: err.message,
  //           snackbarOpen: true,
  //           snackbarVariant: "error",
  //           loader: false,
  //         });
  //       });
  //   } catch (err) {}
  // }
  handleTabView = (value) => {
    
    /*if (custId) {*/
      this.setState({
        currentTab: value,                
      });
   /* }*/
  };
  // loadFlag = (id) => {
  //   if (id) {
  //     this.setState({ editflag: true });
  //     // this.getEntityData();
  //   } else {
  //     this.setState({ editflag: false });
  //   }
  //   setTimeout(() => {
  //
  //   }, 500);
  // };
  // updateTab = (id) => {
  //   if (id) {
  //
  //     this.setState({ editflag: true });
  //     // this.getEntityData();
  //   } else {
  //     this.setState({ editflag: false });
  //   }
  // setTimeout(() => {
  //
  // }, 500);
  // };
  updateTab = (id) => {
    custId = id;
    this.props.history.push(`/customer-detailed/${id}`);
  };
  handleClose = () => {
    this.props.history.push("/customers/customer-list");
  };
  loadError(error) {
    var error_message = "";
    if (error.length > 1) {
      error_message = (
        <ul>
          {error.map((err) => (
            <li>{err}</li>
          ))}
        </ul>
      );
    } else {
      error_message = error;
    }
    return error_message;
  }
  render() {
    const { loader, currentTab, custData } = this.state;
    
    return (
      <>
        <div className="app-content content">
          <div className="content-wrapper">
            <BreadCrumb
              type={custId ? "customerviewcomponent" : "customerlistcomponent"}
            />
            <CommonLoader open={loader} />
            <div className>
              <div className="content-body">
                <section id="configuration">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        {custId && (
                          <div className="customer-list-head">
                            <div className="row">
                              <div className="col-md-7 col-xs-12 text-left">
                                <span className="details">
                                  Date & Time: {custData.datetime_created}
                                </span>
                                <span className="details">
                                  Customer ID: {custData.customer_number}
                                </span>
                              </div>
                              {custData.referred_by && (
                                <div className="col-md-5 col-xs-12 text-right">
                                  <span className="details">Refered By: </span>
                                  <a
                                    className="link"
                                    href="#"
                                    data-title={custData.customer_referral_id}
                                  >
                                    {custData.referred_by}
                                  </a>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        <NavigationTab
                          tabData={tabData}
                          key={custId}
                          currentTab={currentTab}
                          handleTabView={this.handleTabView}
                        />
                        {currentTab === 1 && (
                          <BasicInformation
                            key={currentTab}
                            custData={custData}
                            custId={custId}
                            updateTab={this.updateTab}
                            handleClose={this.handleClose}
                            loadFlag={this.loadFlag}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                            loadError={this.loadError}
                          />
                        )}
                        {currentTab === 2 && custId && (
                          <OfficialMaping
                            key={currentTab}
                            custData={custData}
                            custId={custId}
                            updateTab={this.updateTab}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                            loadError={this.loadError}
                          />
                        )}
                        {currentTab === 3 && custId && (
                          <AddressInformation
                            key={currentTab}
                            custData={custData}
                            custId={custId}
                            updateTab={this.updateTab}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                            loadError={this.loadError}
                          />
                        )}
                        {currentTab === 4 && custId && (
                          <KYCInformation
                            key={currentTab}
                            custData={custData}
                            custId={custId}
                            updateTab={this.updateTab}
                            handleTabView={this.handleTabView}
                            masterData={this.props.masterData}
                            loadError={this.loadError}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CustomerListComponent;
