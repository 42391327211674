import React, { Component } from "react";
import CommonLoader from "../../../../../plugins/commonLoader/commonLoader.component";
import DeleteComponent from "../../../../../plugins/delete/delete.component";
import SnackbarMessage from "../../../../../plugins/snackbar-component";
import {
  delService_Procedure,
  getService,
  update_Service_pricing,
} from "../../../apifunctions";
import FixedPricing from "./pricing";
import PricingCalculator from "./pricing _priceCalculator";
import PricingRequest from "./pricing_requestPricing";

class Pricing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      datas: [],
      users: [],
      filedatas: [],
      entdatas: [],
      snackbarOpen: false,
      submited: false,
      snackbarVariant: "",
      mainId: "",
      editflag: false,
      loader: false,
      opendelete: false,
      hideAdd: false,
      parentMain: "",
      snackbarMessage: "",
      formData: {
        base_price: 0,
        pricing_attributes: [],
      },
      serId: this.props.ser_Id,
    };
  }
  componentWillReceiveProps = () => {
    this.setState({ serId: this.props.ser_Id });
  };
  handleChange = (e) => {
    this.setState(
      {
        formData: { ...this.state.formData, [e.target.name]: e.target.value },
      },
      () => {
        if (this.state.parentMain === e.target.value) {
          this.getproData(this.props.ser_Id);
        } else {
          this.setState({
            formData: {
              pricing_type: this.state.formData.pricing_type,
              base_price: 0,
              pricing_attributes: [],
            },
          });
        }
      }
    );
  };
  componentDidMount = () => {
    if (this.props.ser_Id) {
      this.getproData(this.props.ser_Id);
    }
  };
  async getproData(serId) {
    this.setState({ loader: true });
    try {
      getService(serId)
        .then((res) => res.data)
        .then((res) => {
          this.setState({
            datas: res.pricing,
            formData: {
              ...this.state.formData,
              base_price: res.pricing ? res.pricing.base_price : 0,
              gst: res.pricing ? res.pricing.gst : "",
              pricing_type: res.pricing ? res.pricing.pricing_type.code : "",
              pricing_attributes: res.pricing
                ? res.pricing.pricing_attributes
                : [],
            },
            parentMain: res.pricing ? res.pricing.pricing_type.code : "",
            loader: false,
          });
        })
        .catch((err) => {
          this.setState({
            loader: false,
            snackbarMessage: err.response.data.error.message,
            snackbarOpen: true,
            snackbarVariant: "error",
          });
        });
    } catch (err) {}
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submited: true });
    let data = {};
    if (this.state.formData.pricing_type === "fixed") {
      data = {
        ...this.child.handleData(),
        pricing_attributes: [],
      };
    } else if (this.state.formData.pricing_type === "variable") {
      data = {
        ...this.child.handleData(),
      };
    } else {
      data = {
        base_price: 0,
        pricing_attributes: this.child.handleData()
          ? this.child.handleData()
          : [],
      };
      if (data.pricing_attributes.length === 0) {
        this.setState({
          snackbarOpen: true,
          submited: false,
          snackbarMessage: "Pricing request should have atleast one request",
          snackbarVariant: "error",
        });
        return false;
      }
    }
    this.setState(
      {
        formData: {
          ...this.state.formData,
          ...data,
        },
      },
      () => {
        update_Service_pricing(this.state.formData, this.props.ser_Id)
          .then((res) => res.data)
          .then((res) => {
            this.setState(
              {
                formData: {},
                submited: false,
                snackbarMessage: "Updated Successfully",
                snackbarOpen: true,
                snackbarVariant: "success",
              },
              () => {
                this.getproData(this.props.ser_Id);
              }
            );
          })
          .catch((err) => {
            this.setState({
              submited: false,
              snackbarMessage: err.response.data.error.message,
              snackbarOpen: true,
              snackbarVariant: "error",
            });
          });
      }
    );
  };
  handleDeleteClose = () => {
    this.setState({
      opendelete: false,
    });
  };
  handleDeleteOpen = (e, reqData) => {
    this.setState({
      opendelete: true,
      mainId: reqData.id,
    });
  };
  handleDelete = () =>
    delService_Procedure(this.props.ser_Id, this.state.mainId).then(() => {
      this.getproData(this.props.ser_Id);
    });
  onSnackbarClose = () => {
    this.setState({ snackbarOpen: false });
  };

  render() {
    const { formData, loader, submited } = this.state;
    const { masterData } = this.props;

    return (
      <div className="create-content">
        <CommonLoader open={loader} />
        <div className="content-body">
          <form
            className="edit-form"
            autoComplete="off"
            onSubmit={this.handleSubmit}
          >
            <div className="card-content margin-box">
              <div className="row">
                <div className="col-md-4 col-xs-12">
                  <div className="flex-display d-flex">
                    <label className="control-label">
                      Select the Pricing option
                    </label>
                    <select
                      name="pricing_type"
                      value={formData.pricing_type}
                      required
                      onChange={this.handleChange}
                      className="form-control"
                    >
                      {masterData.pricing_types &&
                        masterData.pricing_types.map((item) => (
                          <option value={item.code}>{item.name}</option>
                        ))}
                    </select>
                  </div>
                </div>
                {formData.pricing_type === "quote" && (
                  <PricingRequest
                    onRef={(ref) => (this.child = ref)}
                    handleSubmit={this.handleSubmit}
                    pricing_attributes={formData.pricing_attributes}
                  />
                )}
                {formData.pricing_type === "variable" && (
                  <PricingCalculator
                    formData={this.state.formData}
                    handleSubmit={this.handleSubmit}
                    onRef={(ref) => (this.child = ref)}
                  />
                )}
                {formData.pricing_type === "fixed" && (
                  <FixedPricing
                    formData={this.state.formData}
                    onRef={(ref) => (this.child = ref)}
                  />
                )}
              </div>
            </div>
            <div className="col-md-12 col-xs-12 text-right">
              <a
                className="btn btn-secondary"
                onClick={(e) => this.props.handleTabView(9)}
              >
                Back
              </a>
              {formData.pricing_type === "quote" && (
                <button
                  className="btn btn-primary"
                  disabled={submited}
                  type="submit"
                >
                  save
                </button>
              )}
              <a
                className="btn btn-secondary"
                onClick={(e) => this.props.handleTabView(11)}
              >
                Next
              </a>
            </div>
          </form>
        </div>
        <DeleteComponent
          opendelete={this.state.opendelete}
          handleDeleteClose={this.handleDeleteClose}
          delete={this.handleDelete}
        />
        <SnackbarMessage
          snackbarOpen={this.state.snackbarOpen}
          snackbarMessage={this.state.snackbarMessage}
          onSnackbarClose={this.onSnackbarClose}
          variant={this.state.snackbarVariant}
        />
      </div>
    );
  }
}

export default Pricing;
