import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = () => {};
  render() {
    return (
      <div>
        <div className="app-content content"></div>
      </div>
    );
  }
}

export default withRouter(Dashboard);
