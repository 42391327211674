import React, { Component } from "react";
import BreadCrumb from "../../../../layout/breadcrumb";
import CommonLoader from "../../../../plugins/commonLoader/commonLoader.component";
import NavigationTab from "../../../../plugins/customtab/customtab";
import { get_Company_Details } from "../../apifunctions";
import AddressInformation from "./company-profile/company-profile";
import "./companyview-component.css";
let com_Id = "";
let tabData = [
  {
    name: "Company Profile",
    id: 1,
  },
  {
    name: "Representative",
    id: 2,
  },
  {
    name: "Compliances",
    id: 3,
  },
];
class CompanyListComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: null,
      custData: {},
      userDetails: {},
      loader: false,
      currentTab: 0,

      params: "",
    };

    com_Id = this.props.match.params.id;
  }
  componentDidMount = () => {
    if (com_Id) {
      this.getData();
    }
  };
  getData = () => {
    this.setState({
      loader: true,
    });
    get_Company_Details(com_Id)
      .then((res) => res.data)
      .then((res) => {
        this.setState({
          userDetails: res,
          loader: false,
          currentTab: 1,
        });
      })
      .catch((err) => {
        this.setState({
          loader: false,
        });
      });
  };

  componentWillMount() {}
  componentDidUpdate(prevProps) {
    // this.loadjsagain(prevProps);
  }

  handleTabView = (value) => {
    this.setState({
      currentTab: value,
    });
  };
  render() {
    const { userDetails, loader, currentTab } = this.state;

    return (
      <>
        <div className="app-content content">
          <CommonLoader open={loader} />
          <div className="content-wrapper">
            <BreadCrumb type={"companyviewcomponent"} />
            <div className>
              <div className="content-body">
                <section id="configuration">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="customer-list-head customer-view">
                          <div className="details-box">
                            <div className="detail-box border-r">
                              <div className="img-box">
                                <img
                                  alt=""
                                  src={
                                    userDetails.profile_image
                                      ? userDetails.profile_image
                                          .profile_thumbnail
                                      : ""
                                  }
                                />
                              </div>
                              <div className="content">
                                <p className="name">
                                  {userDetails.business_name}
                                  <span className="tag yellow">Platinum</span>
                                </p>
                                <div className="list">
                                  <span className="icon">
                                    <img src="/app-assets/backend-images/icons/phone.png" />
                                  </span>
                                  <span className="para">
                                    {userDetails.contact_info
                                      ? userDetails.contact_info.mobile
                                      : ""}
                                  </span>
                                </div>
                                <div className="list">
                                  <span className="icon">
                                    <img src="/app-assets/backend-images/icons/envelope.png" />
                                  </span>
                                  <span className="para">
                                    {" "}
                                    {userDetails.contact_info
                                      ? userDetails.contact_info.email
                                      : ""}{" "}
                                  </span>
                                </div>
                                <div className="list">
                                  <span className="icon">
                                    <img src="/app-assets/backend-images/icons/date.png" />
                                  </span>
                                  <span className="para">
                                    {userDetails.website}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="detail-box border-r">
                              <div className="content">
                                <p className="main">Business Details</p>
                                <div className="list">
                                  <span className="sub">Business Id :</span>
                                  <span className="text">
                                    {userDetails.company_code}
                                  </span>
                                </div>
                                <div className="list">
                                  <span className="sub">Reg.Date :</span>
                                  <span className="text">
                                    {userDetails.incorporation_date}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="detail-box">
                              <div className="content">
                                <p className="main">Data Summary</p>
                                <div className="flex-list">
                                  <div className="view">
                                    <div className="list">
                                      <span className="sub">
                                        Number of Business :
                                      </span>
                                      <span className="text">NA</span>
                                    </div>
                                    <div className="list">
                                      <span className="sub">
                                        Active Orders :
                                      </span>
                                      <span className="text">NA</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <NavigationTab
                          tabData={tabData}
                          currentTab={currentTab}
                          handleTabView={this.handleTabView}
                        />
                        {currentTab === 1 && (
                          <AddressInformation
                            key={com_Id}
                            com_Id={com_Id}
                            userDetails={userDetails}
                          />
                        )}
                        {/* {currentTab === 2 && (
                          <BusinessProfile
                            key={com_Id}
                            custId={com_Id}
                            userDetails={userDetails}
                          />
                        )} */}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CompanyListComponent;
