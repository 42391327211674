const home = { name: "Home", path: "/dashboard", isActive: false };
const dataManagement = {
  name: "Data Management",
  path: "/data-management/service-categories",
  isActive: true,
};

const servicecategoriesList = {
  name: "Service Categories",
  path: "/data-management/service-categories",
  isActive: true,
};
const editServiceCategory = {
  name: "Edit Service Category",
  path: "",
  isActive: true,
};
const addServiceCategory = {
  name: "Add New Service Category",
  path: "",
  isActive: true,
};

const constitutioncategoriesList = {
  name: "Consitution Categories",
  path: "",
  isActive: true,
};
const editConstitutionCategory = {
  name: "Edit Consitution Category",
  path: "",
  isActive: true,
};
const addConstitutionCategory = {
  name: "Add New Consitution Category",
  path: "",
  isActive: true,
};

const businesssectorList = {
  name: "Business Sector",
  path: "",
  isActive: true,
};
const editBusinessSector = {
  name: "Edit Business Sector",
  path: "",
  isActive: true,
};
const addBusinessSector = {
  name: "Add New Business Sector",
  path: "",
  isActive: true,
};

const businessindustriesList = {
  name: "Business Industries",
  path: "",
  isActive: true,
};
const editBusinessIndustries = {
  name: "Edit Business Industry",
  path: "",
  isActive: true,
};
const addBusinessIndustries = {
  name: "Add New Business Industry",
  path: "",
  isActive: true,
};

const educationalqualificationsList = {
  name: "Educational Qualifications",
  path: "",
  isActive: true,
};
const editEducationalQualifications = {
  name: "Edit Educational Qualifications",
  path: "",
  isActive: true,
};
const addEducationalQualifications = {
  name: "Add New Educational Qualifications",
  path: "",
  isActive: true,
};

const specializationList = {
  name: "Specializations",
  path: "",
  isActive: true,
};
const editSpecialization = {
  name: "Edit Specialization",
  path: "",
  isActive: true,
};
const addSpecialization = {
  name: "Add New Specialization",
  path: "",
  isActive: true,
};

const currentpracticesList = {
  name: "Current Practices",
  path: "",
  isActive: true,
};
const editCurrentPractices = {
  name: "Edit Current Practices",
  path: "",
  isActive: true,
};
const addCurrentPractices = {
  name: "Add New Current Practices",
  path: "",
  isActive: true,
};

const parameteroptionsList = {
  name: "Parameter Options",
  path: "",
  isActive: true,
};
const editParameterOptions = {
  name: "Edit Parameter Options",
  path: "",
  isActive: true,
};
const addParameterOptions = {
  name: "Add New Parameter Options",
  path: "",
  isActive: true,
};

const filingscompliance = {
  name: "Filings Compliance",
  path: "/filings-compliance/entity-type",
  isActive: true,
};

const entitytypeList = { name: "Entity Type", path: "", isActive: true };
const editEntityType = { name: "Edit Entity Type", path: "", isActive: true };
const addEntityType = { name: "Add New Entity Type", path: "", isActive: true };

const governmentfilingsList = {
  name: "Government Filings",
  path: "",
  isActive: true,
};
const editGovernmentFilings = {
  name: "Edit Government Filings",
  path: "",
  isActive: true,
};
const addGovernmentFilings = {
  name: "Add New Government Filings",
  path: "",
  isActive: true,
};

const compliancemasterList = {
  name: "Compliance Master",
  path: "",
  isActive: true,
};
const editComplianceMaster = {
  name: "Edit Compliance ",
  path: "",
  isActive: true,
};
const addComplianceMaster = {
  name: "Add New Compliance ",
  path: "",
  isActive: true,
};

const compliancelistList = {
  name: "Compliance List",
  path: "",
  isActive: true,
};
const editComplianceList = {
  name: "Edit Compliance ",
  path: "",
  isActive: true,
};

const servicespackages = {
  name: "Service & Packages",
  path: "/packages-services/service-management",
  isActive: true,
};

const servicemanagementList = {
  name: "Service Management",
  path: "/packages-services/service-management",
  isActive: true,
};

const serviceinfoList = {
  name: "Service Info",
  path: "/packages-services-management/service-info",
  isActive: true,
};

const descriptionList = {
  name: "Description",
  path: "/packages-services-management/description",
  isActive: true,
};

const benefitsList = {
  name: "Benefits",
  path: "/packages-services-management/benefits",
  isActive: true,
};

const procedureList = {
  name: "Procedure",
  path: "/packages-services-management/procedure",
  isActive: true,
};

const documentrequiredList = {
  name: "Document Required",
  path: "/packages-services-management/document-required",
  isActive: true,
};

const servicecheckList = {
  name: "Service CheckList",
  path: "/packages-services-management/service-checklist",
  isActive: true,
};

const prerequisitsList = {
  name: "Pre Requisits",
  path: "/packages-services-management/pre-requisits",
  isActive: true,
};

const deliverableList = {
  name: "Deliverable",
  path: "/packages-services-management/deliverable",
  isActive: true,
};

const faqList = {
  name: "FAQ",
  path: "/packages-services-management/faq",
  isActive: true,
};

const pricingList = {
  name: "Pricing",
  path: "/packages-services-management/pricing",
  isActive: true,
};

const mediaList = {
  name: "Media",
  path: "/packages-services-management/media",
  isActive: true,
};

const quickvideoList = {
  name: "Quick Video",
  path: "/packages-services-management/quick-video",
  isActive: true,
};

const packagemanagementList = {
  name: "Package Management",
  path: "/packages-services/package-management",
  isActive: true,
};
const pack_serviceinfoList = {
  name: "Service Info",
  path: "/packages-management/service-info",
  isActive: true,
};
const pack_infoList = {
  name: "Package Info",
  path: "/packages-management/package-info",
  isActive: true,
};

const pack_descriptionList = {
  name: "Description",
  path: "/packages-management/description",
  isActive: true,
};

const pack_benefitsList = {
  name: "Benefits",
  path: "/packages-management/benefits",
  isActive: true,
};

const pack_procedureList = {
  name: "Procedure",
  path: "/packages-management/procedure",
  isActive: true,
};

const pack_documentrequiredList = {
  name: "Document Required",
  path: "/packages-management/document-required",
  isActive: true,
};

const pack_prerequisitsList = {
  name: "Pre Requisits",
  path: "/packages-management/pre-requisits",
  isActive: true,
};

const pack_deliverableList = {
  name: "Deliverable",
  path: "/packages-management/deliverable",
  isActive: true,
};

const pack_faqList = {
  name: "FAQ",
  path: "/packages-management/faq",
  isActive: true,
};

const pack_pricingList = {
  name: "Pricing",
  path: "/packages-management/pricing",
  isActive: true,
};

const pack_mediaList = {
  name: "Media",
  path: "/packages-management/media",
  isActive: true,
};

const pack_quickvideoList = {
  name: "Quick Video",
  path: "/packages-management/quick-video",
  isActive: true,
};
const leadsenquiry = {
  name: "Leads & Enquires",
  path: "/leads-enquiry/leads",
  isActive: true,
};
const leadList = {
  name: "Leads",
  path: "/leads-enquiry/leads",
  isActive: true,
};
const supportList = {
  name: "Support",
  path: "/leads-enquiry/support",
  isActive: true,
};
const viewSupport = {
  name: "Support",
  path: "/leads-enquiry/support-view",
  isActive: true,
};

const Coupons = {
  name: "Coupons",
  path: "/coupons/coupons-list",
  isActive: true,
};

const couponsList = {
  name: "Coupons",
  path: "/coupons/coupons-list",
  isActive: true,
};

const couponsCreate = {
  name: "Create Coupon",
  path: "/coupons/coupons-list/new",
  isActive: true,
};

const couponsEdit = {
  name: "Edit Coupon",
  path: "/coupons/coupons-list-edit/:id",
  isActive: true,
};

const couponsView = {
  name: "Coupons View",
  path: "/coupons/coupons-list-view",
  isActive: true,
};

const Customers = {
  name: "Customers",
  path: "/customers/customer-list",
  isActive: true,
};

const customersList = {
  name: "Customer List",
  path: "/customers/customer-list",
  isActive: true,
};

const customercomponentList = {
  name: "Add New Customer",
  path: "/customers-list-create",
  isActive: true,
};

const servicecomponentList = {
  name: "Service Mgnt",
  path: "/packages-services-management",
  isActive: true,
};

const basicInformation = {
  name: "Basic Information",
  path: "/customers-list-create/basic-information",
  isActive: true,
};
const officialMaping = {
  name: "Official Maping",
  path: "/customers-list-create/official-maping",
  isActive: true,
};
const addressInformation = {
  name: "Address Information",
  path: "/customers-list-create/address-information",
  isActive: true,
};
const kycInformation = {
  name: "KYC Information",
  path: "/customers-list-create/kyc-information",
  isActive: true,
};

const customerviewcomponent = {
  name: "Customers Details",
  path: "",
  isActive: true,
};

// const customerProfile = {
//   name: "Customer Profile",
//   path: "/customers-list-view/customer-profile",
//   isActive: true,
// };

// const customerOrders = {
//   name: "Customer Orders",
//   path: "/customers-list-view/orders",
//   isActive: true,
// };

// const businessProfile = {
//   name: "Business Profile",
//   path: "/customers-list-view/business-profile",
//   isActive: true,
// };

// const customerCompliances = {
//   name: "Compliances",
//   path: "/companies-list-view/compliances",
//   isActive: true,
// };
const Company = {
  name: "Companies",
  path: "/companies/company-list",
  isActive: true,
};

const companyList = {
  name: "Company List",
  path: "/companies/company-list",
  isActive: true,
};

const companycomponentList = {
  name: "Add New Company List",
  path: "/companies-list-create/basic-information",
  isActive: true,
};

const comapnyadddetailst = {
  name: "Add Company Details",
  path: "",
  isActive: true,
};
const comapnyeditdetailst = {
  name: "Edit Company Details",
  path: "",
  isActive: true,
};
const companybasicInformation = {
  name: "Basic Information",
  path: "/companies-list-create/basic-information",
  isActive: true,
};
const companyofficialMaping = {
  name: "Official Maping",
  path: "/companies-list-create/official-maping",
  isActive: true,
};
const companycontactInformation = {
  name: "Contact Information",
  path: "/companies-list-create/contact-information",
  isActive: true,
};

const companyregistrationInformation = {
  name: "Registration Information",
  path: "/companies-list-create/registration-information",
  isActive: true,
};

const companyaddressInformation = {
  name: "Address Information",
  path: "/companies-list-create/address-information",
  isActive: true,
};
const companypromoterInformation = {
  name: "Promoter Information",
  path: "/companies-list-create/promoter-information",
  isActive: true,
};

const companycompliancesInformation = {
  name: "Compliances Information",
  path: "/companies-list-create/compliances-information",
  isActive: true,
};

const companyviewcomponent = {
  name: "Company Details",
  path: "",
  isActive: true,
};

const companyprofile = {
  name: "Company Profile",
  path: "/companies-list-view/company-profile",
  isActive: true,
};

const companyrepresentative = {
  name: "Business Profile",
  path: "/companies-list-view/representative",
  isActive: true,
};

const companycompliances = {
  name: "Compliances",
  path: "/companies-list-view/compliances",
  isActive: true,
};

const CheckList = {
  name: "CheckList",
  path: "/checklist/checklistView",
  isActive: true,
};
const checklistView = {
  name: "Checklists",
  path: "/checklist/checklistView",
  isActive: true,
};
//////////////////////////////////////////

const Orders = {
  name: "Orders & Payments",
  path: "/orders/orders-list",
  isActive: true,
};
const ordersList = {
  name: "Orders & Payments",
  path: "/orders/orders-list",
  isActive: true,
};

const userfilemanagementList = {
  name: "Folders",
  path: "/user-file-management/folders-list",
  isActive: true,
};

const masterFolderList = {
  name: "Master Folders",
  path: null,
  isActive: true,
};

const foldersList = {
  name: "Master Folders",
  path: null,
  path: "/user-file-management/folders-list",
  isActive: true,
};

const folderDataList = {
  name: "name",
  isPlaceholder: true,
  path: null,
  isActive: true
}
/////////////////////////////////////

const breadcrumbConfig = {
  servicecategories: [home, dataManagement, servicecategoriesList],
  servicecategoriesedit: [
    home,
    dataManagement,
    servicecategoriesList,
    editServiceCategory,
  ],
  servicecategoriescreate: [
    home,
    dataManagement,
    servicecategoriesList,
    addServiceCategory,
  ],

  constitutioncategories: [home, dataManagement, constitutioncategoriesList],
  constitutioncategoriesedit: [
    home,
    dataManagement,
    constitutioncategoriesList,
    editConstitutionCategory,
  ],
  constitutioncategoriescreate: [
    home,
    dataManagement,
    constitutioncategoriesList,
    addConstitutionCategory,
  ],

  businesssector: [home, dataManagement, businesssectorList],
  businesssectoredit: [home, dataManagement, editBusinessSector],
  businesssectorcreate: [home, dataManagement, addBusinessSector],

  businessindustries: [home, dataManagement, businessindustriesList],
  businessindustriesedit: [home, dataManagement, editBusinessIndustries],
  businessindustriescreate: [home, dataManagement, addBusinessIndustries],

  educationalqualifications: [
    home,
    dataManagement,
    educationalqualificationsList,
  ],
  educationalqualificationsedit: [
    home,
    dataManagement,
    editEducationalQualifications,
  ],
  educationalqualificationscreate: [
    home,
    dataManagement,
    addEducationalQualifications,
  ],

  specialization: [home, dataManagement, specializationList],
  specializationedit: [home, dataManagement, editSpecialization],
  specializationcreate: [home, dataManagement, addSpecialization],

  currentpractices: [home, dataManagement, currentpracticesList],
  currentpracticesedit: [home, dataManagement, editCurrentPractices],
  currentpracticescreate: [home, dataManagement, addCurrentPractices],

  parameteroptions: [home, dataManagement, parameteroptionsList],
  parameteroptionsedit: [home, dataManagement, editParameterOptions],
  parameteroptionscreate: [home, dataManagement, addParameterOptions],

  entitytype: [home, filingscompliance, entitytypeList],
  entitytypeedit: [home, filingscompliance, editEntityType],
  entitytypecreate: [home, filingscompliance, addEntityType],

  governmentfilings: [home, filingscompliance, governmentfilingsList],
  governmentfilingsedit: [home, filingscompliance, editGovernmentFilings],
  governmentfilingscreate: [home, filingscompliance, addGovernmentFilings],

  compliancemaster: [home, filingscompliance, compliancemasterList],
  compliancemasteredit: [home, filingscompliance, editComplianceMaster],
  compliancemastercreate: [home, filingscompliance, addComplianceMaster],

  compliancelist: [home, filingscompliance, compliancelistList],
  compliancelistedit: [home, filingscompliance, editComplianceList],

  servicemanagement: [home, servicespackages, servicemanagementList],
  serviceinfo: [home, servicespackages, servicemanagementList, serviceinfoList],
  description: [home, servicespackages, servicemanagementList, descriptionList],
  benefits: [home, servicespackages, servicemanagementList, benefitsList],
  procedure: [home, servicespackages, servicemanagementList, procedureList],
  documentrequired: [
    home,
    servicespackages,
    servicemanagementList,
    documentrequiredList,
  ],

  servicechecklist: [
    home,
    servicespackages,
    servicemanagementList,
    servicecheckList,
  ],

  prerequisits: [
    home,
    servicespackages,
    servicemanagementList,
    prerequisitsList,
  ],
  deliverable: [home, servicespackages, servicemanagementList, deliverableList],
  faq: [home, servicespackages, servicemanagementList, faqList],
  pricing: [home, servicespackages, servicemanagementList, pricingList],
  media: [home, servicespackages, servicemanagementList, mediaList],
  quickvideo: [home, servicespackages, servicemanagementList, quickvideoList],

  packagemanagement: [home, servicespackages, packagemanagementList],
  package_info: [home, servicespackages, packagemanagementList, pack_infoList],
  pack_serviceinfo: [
    home,
    servicespackages,
    packagemanagementList,
    pack_serviceinfoList,
  ],
  pack_description: [
    home,
    servicespackages,
    packagemanagementList,
    pack_descriptionList,
  ],
  pack_benefits: [
    home,
    servicespackages,
    packagemanagementList,
    pack_benefitsList,
  ],
  pack_procedure: [
    home,
    servicespackages,
    packagemanagementList,
    pack_procedureList,
  ],
  pack_documentrequired: [
    home,
    servicespackages,
    packagemanagementList,
    pack_documentrequiredList,
  ],
  pack_prerequisits: [
    home,
    servicespackages,
    packagemanagementList,
    pack_prerequisitsList,
  ],
  pack_deliverable: [
    home,
    servicespackages,
    packagemanagementList,
    pack_deliverableList,
  ],
  pack_faq: [home, servicespackages, packagemanagementList, pack_faqList],
  pack_pricing: [
    home,
    servicespackages,
    packagemanagementList,
    pack_pricingList,
  ],
  pack_media: [home, servicespackages, packagemanagementList, pack_mediaList],
  pack_quickvideo: [
    home,
    servicespackages,
    packagemanagementList,
    pack_quickvideoList,
  ],

  leads: [home, leadsenquiry, leadList],
  support: [home, leadsenquiry, supportList],
  supportview: [home, leadsenquiry, viewSupport],

  coupons: [home, couponsList],
  couponslist: [home, couponsList],
  couponscreate: [home, Coupons, couponsCreate],
  couponsedit: [home, Coupons, couponsEdit],
  couponsview: [home, Coupons, couponsView],

  customers: [home, Customers],
  customerlist: [home, Customers],

  customerlistcomponent: [home, customersList, customercomponentList],
  // basicinformation: [home, customercomponentList, basicInformation],
  // officialmaping: [home, customercomponentList, officialMaping],
  // addressinformation: [home, customercomponentList, addressInformation],
  // kycinformation: [home, customercomponentList, kycInformation],

  customerviewcomponent: [home, customersList, customerviewcomponent],
  // customerprofile: [home, customersList, customerProfile],
  // customerOrders: [home, customersList, customerOrders],
  // businessprofile: [home, customersList, businessProfile],
  // customercompliances: [home, customersList, customerCompliances],

  companies: [home, Company],
  companylist: [home, Company],

  companylistcomponent: [home, companyList, comapnyeditdetailst],
  companyaddlistcomponent: [home, companyList, comapnyadddetailst],
  // companybasicinformation: [
  //   home,
  //   companycomponentList,
  //   companybasicInformation,
  // ],
  // companyofficialmaping: [home, companycomponentList, companyofficialMaping],
  // companycontactinformation: [
  //   home,
  //   companycomponentList,
  //   companycontactInformation,
  // ],
  // companyregistrationinformation: [
  //   home,
  //   companycomponentList,
  //   companyregistrationInformation,
  // ],
  // companyaddressinformation: [
  //   home,
  //   companycomponentList,
  //   companyaddressInformation,
  // ],
  // companypromoterinformation: [
  //   home,
  //   companycomponentList,
  //   companypromoterInformation,
  // ],
  // companycompliancesinformation: [
  //   home,
  //   companycomponentList,
  //   companycompliancesInformation,
  // ],

  companyviewcomponent: [home, companyList, companyviewcomponent],
  // companyprofile: [home, companyList, companyprofile],
  // companyrepresentative: [home, companyList, companyrepresentative],
  // companycompliances: [home, companyList, companycompliances],

  checklist: [home, checklistView],
  checklistview: [home, checklistView],

  ///////////////////////////////////

  orders: [home, ordersList],
  orderslist: [home, ordersList],


  userfilemanagement: [home, foldersList],
  folderslist: [masterFolderList],
  folderdatas: [foldersList, folderDataList]
  /////////////////////////////////
};

export default breadcrumbConfig