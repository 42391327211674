import apis from "../../apiactions/api.actions";

export const service_manage = "service/";
export const servicecat = "core/service-categories";
export const getServicelist = (val) =>
  apis.getDatas(
    `service/?search=${val.search}&service_category=${val.service_category}${
      val.page ? `&page=${val.page}` : ""
    }`
  );
export const getServicelistAll = (val) =>
  apis.getDatas("service/?view=all&search=" + val);
export const deleteService = (id) => apis.deleteData("service/" + id);

export const getService = (id) => apis.getDatas("service/" + id);

export const createService = (data) => apis.createData("service/", data);

export const updateService = (data, id) =>
  apis.updateData("service/" + id, data);

export const getService_Procedure = (id) =>
  apis.getDatas("service/" + id + "/service-procedures");

export const createService_Procedure = (data, id) =>
  apis.createData("service/" + id + "/service-procedures", data);

export const delService_Procedure = (parent, id) =>
  apis.deleteData("service/" + parent + "/service-procedures/" + id);

export const getService_Benefits = (id) =>
  apis.getDatas("service/" + id + "/service-benefits");

export const createService_Benefits = (id, data) =>
  apis.createData("service/" + id + "/service-benefits", data);

export const updateService_Benefits = (parent, id, data) =>
  apis.updateData("service/" + parent + `/service-benefits/${id}`, data);

export const createServiceSection = (id, data) =>
  apis.createData(`service/${id}/service-sections`, data);

export const createServiceSection_element = (id, data) =>
  apis.createData(`service/${id}/service-section-elements`, data);

export const delete_service_elements = (parent, id) =>
  apis.deleteData(`service/${parent}/service-section-elements/${id}`);
export const delete_service_sectoion = (parent, id) =>
  apis.deleteData(`service/${parent}/service-sections/${id}`);

export const updateService_sections = (parent, id, data) =>
  apis.updateData(`service/${parent}/service-sections/${id}`, data);

export const getService_prerequest = (id) =>
  apis.getDatas(`service/${id}/service-prerequisits`);

export const createService_prerequest = (id, data) =>
  apis.createData(`service/${id}/service-prerequisits`, data);
export const deleteService_prerequest = (parent, id) =>
  apis.deleteData(`service/${parent}/service-prerequisits/${id}`);
export const getService_documents = (id) =>
  apis.getDatas(`service/${id}/service-documents`);

export const createService_documents = (id, data) =>
  apis.createData(`service/${id}/service-documents`, data);

export const delService_Documents = (parent, id) =>
  apis.deleteData(`service/${parent}/service-documents/${id}`);

export const getService_deliverables = (id) =>
  apis.getDatas(`service/${id}/service-deliverables`);

export const createService_deliverables = (id, data) =>
  apis.createData(`service/${id}/service-deliverables`, data);

export const delService_deliverables = (parent, id) =>
  apis.deleteData(`service/${parent}/service-deliverables/${id}`);

export const getService_ffaq = (id) =>
  apis.getDatas(`service/${id}/service-faq`);

export const createService_faq = (id, data) =>
  apis.createData(`service/${id}/service-faq`, data);

export const delService_faq = (parent, id) =>
  apis.deleteData(`service/${parent}/service-faq/${id}`);

export const getService_video = (id) =>
  apis.getDatas(`service/${id}/service-video`);

export const createService_video = (id, data) =>
  apis.createData(`service/${id}/service-video`, data);

export const delService_video = (parent, id) =>
  apis.deleteData(`service/${parent}/service-video/${id}`);

export const update_Service_pricing = (data, id) =>
  apis.createData(`service/${id}/service-pricing`, data);

export const get_Service_Pricing = (id) =>
  apis.getDatas(`service/${id}/service-pricing`);

export const update_Service_image = (data, id) =>
  apis.createData(`service/${id}/service-image`, data);

export const get_Service_image = (id) =>
  apis.getDatas(`service/${id}/service-image`);

export const delService_image = (parent, id) =>
  apis.deleteData(`service/${parent}/service-image/${id}`);

///////packages//////////////////////

export const getPackagelist = (val) =>
  apis.getDatas(
    `packages/?search=${val.search}&service_category=${val.service_category}${
      val.page ? `&page=${val.page}` : ""
    }`
  );
export const deletePackage = (id) => apis.deleteData("packages/" + id);

export const getPackage = (id) => apis.getDatas("packages/" + id);

export const createPackage = (data) => apis.createData("packages/", data);

export const updatePackage = (data, id) =>
  apis.updateData("packages/" + id, data);

export const getPackage_Procedure = (id) =>
  apis.getDatas("packages/" + id + "/package-procedures");

export const createPackage_Procedure = (data, id) =>
  apis.createData("packages/" + id + "/package-procedures", data);

export const delPackage_Procedure = (parent, id) =>
  apis.deleteData("packages/" + parent + "/package-procedures/" + id);

export const getPackage_Benefits = (id) =>
  apis.getDatas("packages/" + id + "/package-benefits");

export const createPackage_Benefits = (id, data) =>
  apis.createData("packages/" + id + "/package-benefits", data);

export const updatePackage_Benefits = (parent, id, data) =>
  apis.updateData("packages/" + parent + `/package-benefits/${id}`, data);

export const createPackageSection = (id, data) =>
  apis.createData(`packages/${id}/package-sections`, data);

export const createPackageSection_element = (id, data) =>
  apis.createData(`packages/${id}/package-section-elements`, data);

export const delete_package_elements = (parent, id) =>
  apis.deleteData(`packages/${parent}/package-section-elements/${id}`);
export const delete_package_sectoion = (parent, id) =>
  apis.deleteData(`packages/${parent}/package-sections/${id}`);

export const updatePackage_sections = (parent, id, data) =>
  apis.updateData(`packages/${parent}/package-sections/${id}`, data);

export const getPackage_prerequest = (id) =>
  apis.getDatas(`packages/${id}/package-prerequisits`);

export const createPackage_prerequest = (id, data) =>
  apis.createData(`packages/${id}/package-prerequisits`, data);
export const deletePackage_prerequest = (parent, id) =>
  apis.deleteData(`packages/${parent}/package-prerequisits/${id}`);
export const getPackage_documents = (id) =>
  apis.getDatas(`packages/${id}/package-documents`);

export const createPackage_documents = (id, data) =>
  apis.createData(`packages/${id}/package-documents`, data);

export const delPackage_Documents = (parent, id) =>
  apis.deleteData(`packages/${parent}/package-documents/${id}`);

export const getPackage_deliverables = (id) =>
  apis.getDatas(`packages/${id}/package-deliverables`);

export const createPackage_deliverables = (id, data) =>
  apis.createData(`packages/${id}/package-deliverables`, data);

export const delPackage_deliverables = (parent, id) =>
  apis.deleteData(`packages/${parent}/package-deliverables/${id}`);

export const getPackage_ffaq = (id) =>
  apis.getDatas(`packages/${id}/package-faq`);

export const createPackage_faq = (id, data) =>
  apis.createData(`packages/${id}/package-faq`, data);

export const delPackage_faq = (parent, id) =>
  apis.deleteData(`packages/${parent}/package-faq/${id}`);

export const getPackage_video = (id) =>
  apis.getDatas(`packages/${id}/package-video`);

export const createPackage_video = (id, data) =>
  apis.createData(`packages/${id}/package-video`, data);

export const delPackage_video = (parent, id) =>
  apis.deleteData(`packages/${parent}/package-video/${id}`);

export const update_Package_pricing = (data, id) =>
  apis.createData(`packages/${id}/package-pricing`, data);

export const get_Package_Pricing = (id) =>
  apis.getDatas(`packages/${id}/package-pricing`);

export const update_Package_image = (data, id) =>
  apis.createData(`packages/${id}/package-image`, data);

export const get_Package_image = (id) =>
  apis.getDatas(`packages/${id}/package-image`);
export const update_Package_service = (data, id) =>
  apis.createData(`packages/${id}/service-packages`, data);

export const get_Package_service = (id) =>
  apis.getDatas(`packages/${id}/service-packages`);
export const delPackage_service = (parent, id) =>
  apis.deleteData(`packages/${parent}/service-packages/${id}`);

export const delPackage_image = (parent, id) =>
  apis.deleteData(`packages/${parent}/package-image/${id}`);

////for service checklists/////

//const checklistAll = "core/check-list?view=all";

const checklist = "core/check-list";

export const getCheckList = (val) =>
  apis.getDatas(
    checklist + `?search=${val.search}${val.page ? `&page=${val.page}` : ""}`
  );
/*export const getCheckList = () => 
  apis.getDatas(checklistAll);*/

export const getServiceCheckLists = (val, id) =>
  apis.getDatas(
    `service/${id}/service-checklist?search=${val.search}${
      val.page ? `&page=${val.page}` : ""
    }`
  );

export const addToService = (data, id) =>
  apis.createData("service/" + id + "/service-checklist?", data);

export const updateServiceCheckLists = (id, rowId, data) =>
  apis.updateData("service/" + id + "/service-checklist/" + rowId, data);

export const deleteServiceCheckLists = (id, dat) =>
  apis.deleteData("service/" + id + "/service-checklist-delete", { data: dat });

////for package checklists//////////////
export const addToPackage = (data, id) =>
  apis.createData("packages/" + id + "/package-checklist?", data);

export const getPackageCheckLists = (val, id) =>
  apis.getDatas(
    `packages/${id}/package-checklist?search=${val.search}${
      val.page ? `&page=${val.page}` : ""
    }`
  );

export const updatePackageCheckLists = (id, rowId, data) =>
  apis.updateData("packages/" + id + "/package-checklist/" + rowId, data);

export const deletePackageCheckLists = (id, dat) =>
  apis.deleteData("packages/" + id + "/package-checklist-delete", {
    data: dat,
  });
