import apis from "../../apiactions/api.actions";

const customer = "customer/";
const customer_basic = "customer/basic-info/";
const customer_mapping = "/customer-mapping/";
const customer_address = "/customer-address/";
const customer_kyc = "/customerKYCattachments/";
const customer_orders = "/customer-orders/";
const support_note = "note";
const employee_listall = "employee/?view=all";
const location_list = "core/country-state-list";

export const searhCustomerData = (val) =>
  apis.getDatas(
    `${customer}?search=${val.search}&status__id=${val.status}&fromDate=${
      val.fromDate
    }&toDate=${val.toDate}&cre_agent__id=${
      val.cre_agent__id
    }&customer_tier__tier=${val.customer_tier__tier}${
      val.page ? `&page=${val.page}` : ""
    }`
  );

export const getEmployeeList = () => apis.getDatas(employee_listall);
export const getCustomerBusiness = (id, page) =>
  apis.getDatas(customer + id + "/business-info/?page=" + page);
export const getCustomerOrder = (id, page) =>
  apis.getDatas(customer + id + "/order-info/?page=" + page);
export const getCustomerTransactions = (id, page) =>
  apis.getDatas(customer + id + "/transactions-info/?page=" + page);
export const getCustomerService = (id, page) =>
  apis.getDatas(customer + id + "/service-info/?page=" + page);

export const getCustomer = (id) => apis.getDatas(customer + id + "/");
//apis.getDatas(`${company}${id}/company`);

export const saveCustomerBasic = (data) =>
  apis.createData(`${customer_basic}`, data);
export const updateCustomerBasic = (id, data) =>
  apis.updateData(`${customer_basic}${id}/`, data);
export const getCustomerBasic = (id) =>
  apis.getDatas(customer_basic + id + "/");

export const getLocationList = () => apis.getDatas(location_list);

export const getCustomerInfo = (id) =>
  apis.getDatas(customer + id + "/customer-info/");

export const getMapCustomer = (customer_id) =>
  apis.getDatas(customer + customer_id + customer_mapping);
export const saveMapCustomer = (customer_id, data) =>
  apis.createData(customer + customer_id + "/new-customer-mapping", data);
export const updateMapCustomer = (customer_id, data) =>
  apis.updateData(
    customer + customer_id + customer_mapping + data.id + "/",
    data
  );

export const getCustomerAddress = (customer_id) =>
  apis.getDatas(customer + customer_id + customer_address);
export const newCustomerAddress = (customer_id, data) =>
  apis.createData(customer + customer_id + customer_address, data);
export const updateCustomerAddress = (customer_id, data) =>
  apis.updateData(
    customer + customer_id + customer_address + data.id + "/",
    data
  );

export const getCustomerKYC = (customer_id) =>
  apis.getDatas(customer + customer_id + customer_kyc);
export const saveCustomerKYC = (customer_id, data) =>
  apis.createData(customer + customer_id + customer_kyc, data);
export const deleteCustomerKYC = (customer_id, row_id) =>
  apis.deleteData(customer + customer_id + customer_kyc + row_id + "/");

export const getCustomerOrders = (customer_id) =>
  apis.getDatas(customer + customer_id + customer_orders);
